import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  IconButton,
  TextField,
  InputAdornment,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import SendIcon from "@mui/icons-material/Send";
import EmojiPicker from "emoji-picker-react";
import { postRequest } from "../api-service";
import { handleReplyToComment } from "../http/postApiCalls";
import { queryClient } from "../http";
import { useMutation } from "@tanstack/react-query";
import { Icon } from "@iconify/react/dist/iconify.js";

const CommentInput = ({
  postId,
  onPostSuccess,
  replyToComment,
  commentReplyUserName,
}) => {
  const [comment, setComment] = useState("");
  const [isPosted, setIsPosted] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);

  useEffect(() => {
    setComment(replyToComment ? `${commentReplyUserName} ` : "");
    const handleClickOutside = (event) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [commentReplyUserName]);

  const handleEmojiClick = (event, emojiObject) => {
    const emoji = emojiObject?.emoji || event.emoji || event.native;
    if (emoji) {
      setComment((prevComment) => prevComment + emoji);
    }
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  // Define mutation for replying to a comment
  const { mutate: replyComment, reset: commentReplyReset } = useMutation({
    mutationKey: ["comment-reply"],
    mutationFn: handleReplyToComment,
    onSuccess: (data) => {
      setComment("");
      console.log("Comment posted:", data);
      setIsPosted(false);
      if (onPostSuccess) {
        onPostSuccess(data); // Optional callback for parent component to handle success
      }
      if (data?.status) {
        // showSuccessToast(data.message, darkMode);
        queryClient.invalidateQueries(["getAllPostData"]);
        queryClient.invalidateQueries("notifications");
      } else {
        // showErrorToast(data?.message, darkMode);
      }
      commentReplyReset();
    },
  });

  const handlePostComment = () => {
    if (comment.trim()) {
      setIsPosted(true);
      const formData = new FormData();
      formData.append("post_id", postId);
      formData.append("comment", comment);

      if (replyToComment) {
        const formData1 = new FormData();
        formData1.append("comment_id", postId);
        formData1.append("reply", comment);
        replyComment(formData1);
      } else {
        postRequest(
          "/community-post/community-post-comments/add-comment",
          formData
        )
          .then((res) => {
            setComment("");
            console.log("Comment posted:", res);
            queryClient.invalidateQueries("notifications");
            setIsPosted(false);
            if (onPostSuccess) {
              onPostSuccess(res); // Optional callback for parent component to handle success
            }
          })
          .catch((error) => {
            console.error("Error posting comment:", error);
            setIsPosted(false);
          });
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mt: 0,
        position: "relative",
        borderBottom: "1px solid #dbdbdb",
      }}
    >
      <Tooltip title="Add Emoji">
        <IconButton onClick={toggleEmojiPicker} edge="start">
          {/* <EmojiEmotionsIcon sx={{ color: "#d1d1d1" }} /> */}
          <Icon icon="fluent:emoji-add-16-regular" fontSize={25} />
        </IconButton>
      </Tooltip>
      <TextField
        fullWidth
        multiline
        maxRows={2}
        variant="standard"
        placeholder={replyToComment ? commentReplyUserName : "Add a comment..."}
        value={comment}
        onChange={handleCommentChange}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handlePostComment();
          }
        }}
        sx={{
          backgroundColor: "transparent",
          "& .MuiInputBase-root": {
            backgroundColor: "transparent",
            paddingTop: "12px",
            paddingBottom: "12px",
            // borderBottom: "1px solid #dbdbdb",
            height: "auto",
            maxHeight: "50px",
            resize: "none",
            "&.Mui-focused": {
              backgroundColor: "transparent",
            },
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiInput-underline:before": {
            display: "none",
          },
          "& .MuiInput-underline:after": {
            display: "none",
          },
          "& .MuiInput-underline:hover:before": {
            display: "none",
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isPosted ? (
                <CircularProgress size={20} thickness={5} />
              ) : (
                <Tooltip title="Send">
                  <IconButton
                    disabled={!comment.trim()}
                    onClick={handlePostComment}
                    edge="end"
                    sx={{ backgroundColor: "transparent" }}
                  >
                    <SendIcon sx={{ color: comment ? "#fa594d" : "#FCEBEC" }} />
                  </IconButton>
                </Tooltip>
              )}
            </InputAdornment>
          ),
        }}
      />

      {showEmojiPicker && (
        <Box
          ref={emojiPickerRef}
          sx={{
            position: "absolute",
            bottom: "60px",
            left: "0",
            zIndex: 1,
          }}
        >
          <EmojiPicker onEmojiClick={handleEmojiClick} />
        </Box>
      )}
    </Box>
  );
};

export default CommentInput;
