import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  highlightName: "",
  selectedStories: [],
  coverImage: null,
  currentStep: 1,
};

const highlightSlice = createSlice({
  name: "highlight",
  initialState,
  reducers: {
    addHighlightName(state, action) {
      state.highlightName = action.payload;
    },
    toggleStorySelection(state, action) {
      const story = action.payload;
      const exists = state.selectedStories.find((s) => s.id === story.id);
      if (exists) {
        state.selectedStories = state.selectedStories.filter(
          (s) => s.id !== story.id
        );
      } else {
        state.selectedStories.push(story);
      }
    },
    setCoverImage(state, action) {
      state.coverImage = action.payload;
    },
    resetHighlight(state) {
      return initialState;
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
  },
});

export const {
  addHighlightName,
  toggleStorySelection,
  setCoverImage,
  resetHighlight,
  setCurrentStep,
} = highlightSlice.actions;

export const selectHighlights = (state) => state.highlight;

export default highlightSlice.reducer;
