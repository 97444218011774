import React from "react";
import { Box, CircularProgress, Skeleton } from "@mui/material";
import { Grid } from "@mui/material";
import Post from "../components/Post";
import { handleGetPosts } from "../http/getApiCalls";
import { useQuery } from "@tanstack/react-query";
import MasonryImageList from "../components/Explore/ExplorePost";

function Explore() {
  const posts = [
    { id: 1, username: "user1", imageUrl: "url1", caption: "caption1" },
    { id: 2, username: "user2", imageUrl: "url2", caption: "caption2" },
    // Add more posts here
  ];

  const {
    data: postsData,
    isLoading: postFinalIsLoading,
    isRefetching: postFinalIsRefetch,
    refetch: postFinalRefetch,
  } = useQuery({
    queryKey: ["getAllPostData"],
    queryFn: async () => handleGetPosts(),
    gcTime: 0,
    staleTime: Infinity,
  });

  console.log("postData explore", postsData);

  return (
    <>
      <Grid container spacing={2} display={"flex"} justifyContent={"center"}>
        <MasonryImageList
          postsData={postsData}
          postFinalIsLoading={postFinalIsLoading}
          postFinalRefetch={postFinalRefetch}
        />
      </Grid>
      {/* <MasonryImageList
        postsData={postsData}
        postFinalIsLoading={postFinalIsLoading}
        postFinalRefetch={postFinalRefetch}
      /> */}
    </>
  );
}

export default Explore;
