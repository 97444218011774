import React from "react";
import { Container } from "@mui/material";
import { styled } from "@mui/system";
import community from "../../assets/images/Community.png"; // Ensure the path is correct and image has transparency

// Styled component for the rotating image
const RotatingImage = styled("img")({
  width: "60px", // Image size
  height: "60px",
  animation: "rotation 1.3s infinite linear", // Rotation animation
  borderRadius: "20%", // Soft corners (optional)
  // boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.15)", // Shadow for effect
  transition: "transform 0.5s ease-in-out", // Smooth hover transition
  backgroundColor: "transparent", // Transparent background (default behavior for image)
  "@keyframes rotation": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
  },
});

const Loader = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh", // Center the loader in view
      }}
    >
      <RotatingImage src={community} alt="Loading..." />
    </Container>
  );
};

export default Loader;
