import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import Feed from "../components/Feed";
import Stories from "../components/Stories";
import Chat from "../components/chats/Chat";

function Home() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Mobile screens

  return (
    <Grid
      container
      spacing={isSmallScreen ? 0 : 2}
      sx={{
        padding: isSmallScreen ? "0 8px" : "0 32px",
        marginX: "auto",
      }}
    >
      <Grid
        item
        xs={12}
        md={8}
        lg={9.5}
        sx={{
          padding: isSmallScreen ? "0 8px" : "0 16px",
          marginBottom: isSmallScreen ? "16px" : 0,
        }}
      >
        <Stories />
        <Feed />
      </Grid>
      {!isSmallScreen && (
        <Grid
          item
          xs={12}
          md={4}
          lg={2.5}
          sx={{
            padding: "0 16px",
            marginLeft: "auto",
            textAlign: "end",
          }}
        >
          <Chat />
        </Grid>
      )}
    </Grid>
  );
}

export default Home;
