import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Avatar,
  TextField,
  Grid,
  useMediaQuery,
  Typography,
  InputAdornment,
  Tooltip,
  Skeleton,
  CircularProgress,
  useTheme,
  Collapse,
  Popover,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import {
  Delete,
  MoreHorizOutlined,
  PlayCircleOutline,
} from "@mui/icons-material";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import VolumeOffOutlinedIcon from "@mui/icons-material/VolumeOffOutlined";
import { Icon } from "@iconify/react/dist/iconify.js";
import EmojiPicker from "emoji-picker-react";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { getRequest, postRequest } from "../../api-service";
import { queryClient } from "../../http";
import { handleDeleteComment, handlePostLike } from "../../http/postApiCalls";
import { useMutation, useQuery } from "@tanstack/react-query";
import { handleGetPosts } from "../../http/getApiCalls";
import CommentInput from "../../utils/CommentInput";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import ReplyIcon from "@mui/icons-material/Reply";
import { useLocation, useNavigate } from "react-router-dom";
import PostMoreMenuModal from "../../more-menus/PostMoreMenuModal";

function CommentsModal({
  isModalOpen,
  handleModalClose,
  imageUrl,
  caption,
  postData,
  userId,
  userName,
  profileImage,
  following_status,
}) {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const location = useLocation();
  console.log("postData", postData);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isPosted, setIsPosted] = useState(false);
  const [likePost, setLikePost] = useState(postData.liked_by_user);
  const [likeCount, setLikeCount] = useState(postData.likes_count);

  const videoRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const navigate = useNavigate();

  // console.log("postData---", postData);
  const isImage = imageUrl?.match(/\.(jpeg|jpg|gif|png)$/) !== null;
  const isVideo = imageUrl?.match(/\.(mp4|webm|ogg)$/) !== null;

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  const {
    data: postsData,
    isLoading: postFinalIsLoading,
    isRefetching: postFinalIsRefetch,
    refetch: postFinalRefetch,
  } = useQuery({
    queryKey: ["getAllPostData", postData.id],
    queryFn: async () => handleGetPosts(postData.id),
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (isModalOpen) {
      postFinalRefetch(); // Refetch the data when the modal is opened
    }
  }, [isModalOpen, postFinalRefetch, isPosted]);
  // console.log("postsData commentsmodal", postsData ? postsData[0] : postsData);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    borderRadius: 2,
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 0,
    // p: isSmallScreen ? 2.5 : 4,
    width: isSmallScreen ? "85vw" : "75vw",
    minHeight: "70vh",
    maxHeight: isSmallScreen ? "95vh" : "80vh",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  };

  const handleMuteToggle = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handlePlayPauseToggle = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play().catch((error) => {
          console.error("Error playing video:", error);
        });
        setIsPaused(false);
      } else {
        videoRef.current.pause();
        setIsPaused(true);
      }
    }
  };

  const getCommentsOfPost = () => {
    // setLoading(true);

    getRequest(
      `/community-post/community-post-comments/get-comments?post_id=${postData.id}`
    )
      .then((res) => {
        setLoading(false);
        setComments(res.data.comments);
        // console.log("comments res", res.data.comments);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error", err);
      });

    // postRequest("/community-post/community-post-comments/get-comments", {
    //   post_id: postData.id,
    // })
    //   .then((res) => {
    //     setLoading(false);
    //     setComments(res.data.comments);
    //     console.log("comments res", res.data.comments);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     console.log("error", err);
    //   });
  };
  const [replyingTo, setReplyingTo] = useState(null);
  const [commentReplyTrue, setCommentReplyTrue] = useState(false);
  const [replyingToName, setReplyingToName] = useState("");
  const [commentReplyId, setCommentReplyId] = useState(null);

  const handleReplyClick = (commentId, commentUsername) => {
    setCommentReplyId(commentId);
    setCommentReplyTrue(true);
    setReplyingToName(`@${commentUsername}`);
    setReplyingTo((prev) => (prev === commentId ? null : commentId));
  };

  const [viewingReplies, setViewingReplies] = useState(null);

  const handleViewRepliesClick = (commentId) => {
    setViewingReplies((prev) => (prev === commentId ? null : commentId));
  };
  const handlePostSuccess = () => {
    if (isModalOpen === true) {
      getCommentsOfPost();
      setReplyingTo(null);
      setReplyingToName("");
      setCommentReplyTrue(false);
      setCommentReplyId(null);
    }
  };

  useEffect(() => {
    if (isModalOpen === true) {
      getCommentsOfPost();
    }
  }, [isModalOpen, isPosted]);

  // api for like post
  const handleLikePost = (id) => {
    setLikePost(!likePost);
    setLikeCount(likePost ? likeCount - 1 : likeCount + 1);
    // postRequest("/community-post/togglelike-post", { post_id: id }).then(
    //   (res) => {
    //     console.log("post liked");
    //   }
    // );
    const formData = new FormData();
    formData.append("post_id", id);

    postLike(formData);
  };

  const { mutate: postLike, reset: postLikeReset } = useMutation({
    mutationKey: ["post-like"],
    mutationFn: handlePostLike,
    onSuccess: (data) => {
      // console.log("final data", data);
      if (data?.status) {
        // showSuccessToast(data.message, darkMode);
        queryClient.invalidateQueries(["getAllPostData"]);
      } else {
        // showErrorToast(data?.message, darkMode);
      }

      postLikeReset();
    },
  });
  const userCheck = localStorage.getItem("loggedIn_user_id");

  const handleDeleteComments = (id, type) => {
    console.log("id", id, "type", type);
    const formData = new FormData();
    if (type === "comment") {
      formData.append("comment_id", id);
    } else if (type === "reply") {
      formData.append("reply_id", id);
    }
    deleteComment(formData);
  };

  const { mutate: deleteComment, reset: deleteCommentReset } = useMutation({
    mutationKey: ["delete-comments"],
    mutationFn: (fd) => handleDeleteComment(fd),
    onSuccess: (data) => {
      if (data?.status) {
        getCommentsOfPost();
        handleClose();
      }
      deleteCommentReset();
    },
    onError: (data) => {
      console.error("Error deleting comment or reply", data);
    },
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteID, setDeleteID] = useState(0);
  const [deleteType, setDeleteType] = useState("");

  // Handle opening popover
  const handleClick = (event, id, type) => {
    console.log("id", id, "type", type);
    setDeleteID(id);
    setDeleteType(type);

    setAnchorEl(event.currentTarget);
  };

  // Handle closing popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Determine if the popover is open
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [isLoading, setIsLoading] = useState(true); // Loading state for media

  const handleImageLoaded = () => {
    setIsLoading(false); // Hide skeleton when image is loaded
  };

  const handleVideoLoaded = () => {
    setIsLoading(false); // Hide skeleton when video is loaded
  };

  const handleGoToOtherUserProfile = (id) => {
    if (location.pathname.startsWith("/profile")) {
      navigate("/profile");
      return;
    }
    navigate(
      userCheck === id?.toString() ? "/profile" : `/other/profile/${id}`
    );
  };

  const [selectedPost, setSelectedPost] = useState([]);
  const [openMoreMenu, setOpenMoreMenu] = useState(false);
  const handleOpenModal = (post) => {
    setSelectedPost(post);
    setOpenMoreMenu(true);
  };
  const handleCloseModal = () => {
    setOpenMoreMenu(false);
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <Box sx={modalStyle}>
        {/* <Box
          sx={{ position: "absolute", top: isSmallScreen ? 2 : 6, right: 15 }}
        >
          <IconButton edge="end" color="inherit" onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box> */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: 0,
                paddingTop: "100%", // Square aspect ratio
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#c4c0c0", // Black background similar to Instagram
              }}
            >
              {/* Skeleton Loader */}
              {isLoading && (
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    bgcolor: "#c4c0c0", // Dark skeleton for Instagram-like feel
                  }}
                />
              )}

              {/* Image Handling */}
              {postData?.media_details[0]?.media_type === "image" && (
                <img
                  src={imageUrl}
                  alt="Post"
                  onLoad={handleImageLoaded} // Detect when the image is fully loaded
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover", // Make sure the image covers the area
                    display: isLoading ? "none" : "block", // Hide image until loaded
                  }}
                />
              )}

              {/* Video Handling */}
              {postData?.media_details[0]?.media_type === "video" && (
                <>
                  <video
                    ref={videoRef}
                    src={imageUrl}
                    loop
                    autoPlay
                    playsInline
                    onLoadedData={handleVideoLoaded} // Detect when the video is ready
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover", // Cover the container
                      display: isLoading ? "none" : "block", // Hide video until loaded
                    }}
                    onClick={handlePlayPauseToggle}
                  />
                  {isPaused && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "white",
                        borderRadius: "50%",
                        padding: 1,
                      }}
                    >
                      <PlayCircleOutline
                        onClick={handlePlayPauseToggle}
                        sx={{
                          fontSize: isSmallScreen ? 40 : 60,
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              maxHeight: "100%",
              p: 1.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                // mt: -1,
                mb: 1,
                mt: isSmallScreen ? 0 : 1,
                justifyContent: "space-between",
              }}
            >
              {loading ? (
                <div style={{ display: "flex" }}>
                  <Skeleton
                    animation="wave"
                    sx={{
                      bgcolor: "#bbbdbf",
                      width: 40,
                      height: 40,
                      borderRadius: "50%",
                    }}
                    variant="circular"
                    width={40}
                    height={40}
                  />
                  <Skeleton
                    animation="wave"
                    sx={{ bgcolor: "#bbbdbf", ml: 1 }}
                    variant="text"
                    width={100}
                    height={40}
                  />
                </div>
              ) : (
                <>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleGoToOtherUserProfile(postData.user_id)}
                  >
                    <Avatar
                      src={`https://api.silocloud.io/get-file-content/${
                        profileImage
                          ? profileImage
                          : postData?.profile_image_path
                      }`}
                      alt={postData.username}
                      style={{
                        width: 32,
                        height: 32,
                        borderRadius: "50%",
                        textTransform: "capitalize",
                      }}
                    />
                    <div style={{ marginLeft: "8px" }}>
                      {/* Username */}
                      <Typography
                        sx={{
                          fontWeight: "900",
                          fontSize: "1rem",
                          textTransform: "capitalize",
                        }}
                      >
                        {userName ? userName : postData.username}
                      </Typography>

                      {/* Location */}
                      <Typography
                        sx={{
                          fontSize: "0.875rem",
                          color: "gray",
                          textTransform: "capitalize",
                          maxWidth: "150px", // Adjust width as needed
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        title={
                          postData?.location === "undefined"
                            ? " "
                            : postData?.location
                        }
                      >
                        {postData?.location === "undefined"
                          ? " "
                          : postData?.location}
                      </Typography>
                    </div>

                    {/* <Button
                  onClick={() => handleDoFollowUser(post.user_id)}
                  // className={
                  //   theme.palette.mode === "dark"
                  //     ? "profile-section-btn-follow"
                  //     : "profile-section-btn-follow"
                  // }
                  variant="outlined"
                  color="error"
                  sx={{
                    ml: 1,
                    border: "none",
                    "&:hover": {
                      border: "none",
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress size={18} color="inherit" />
                  ) : post.following_status ? (
                    "Following"
                  ) : (
                    "Follow"
                  )}
                </Button> */}
                  </span>
                  {/* <IconButton onClick={() => handleOpenModal(postData)}> */}
                  <MoreHorizIcon
                    sx={{
                      cursor: "pointer",
                      height: 32,
                      width: 28,
                      "&:hover": {
                        color: "#De3744",
                      },
                    }}
                    onClick={() => handleOpenModal(postData)}
                  />
                  {/* </IconButton> */}
                </>
              )}
            </Box>
            <List
              className="custom-scrollbar"
              sx={{
                overflowY: "auto",
                flexGrow: 1,
                maxHeight: isSmallScreen ? "40vh" : "70vh",
              }}
            >
              {loading
                ? Array.from(new Array(10))?.map((_, index) => (
                    <ListItem key={index}>
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={30}
                        height={30}
                        sx={{ mr: 1, mt: -2 }}
                      />
                      <ListItemText
                        primary={
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width="150px"
                          />
                        }
                        secondary={
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width="100px"
                          />
                        }
                      />
                    </ListItem>
                  ))
                : comments?.map((comment) => (
                    <React.Fragment key={comment.id}>
                      <ListItem sx={{ alignItems: "flex-start" }}>
                        <Avatar
                          src={`https://api.silocloud.io/get-file-content/${comment?.profile_image_path}`}
                          alt={comment?.username}
                          sx={{
                            mr: 1,
                            height: 25,
                            width: 25,
                            textTransform: "capitalize",
                          }}
                        />
                        <ListItemText
                          primary={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  mr: 1,
                                  mt: -0.5,
                                  textTransform: "capitalize",
                                }}
                              >
                                {comment.username}
                              </Typography>
                            </Box>
                          }
                          secondary={
                            <Box sx={{ display: "", alignItems: "center" }}>
                              <Typography variant="subtitle2" sx={{ mr: 1 }}>
                                {comment.comment}
                              </Typography>
                              <span style={{ display: "flex" }}>
                                <Typography
                                  variant="body"
                                  onClick={() =>
                                    handleReplyClick(
                                      comment.id,
                                      comment.username
                                    )
                                  }
                                  sx={{ padding: "5px", cursor: "pointer" }}
                                >
                                  {/* <ReplyIcon sx={{ fontSize: 20 }} /> */}
                                  Reply
                                </Typography>

                                {comment.reply_on_comment?.length > 0 && (
                                  <Typography
                                    variant="body"
                                    onClick={() =>
                                      handleViewRepliesClick(comment.id)
                                    }
                                    sx={{
                                      padding: "5px",
                                      cursor: "pointer",
                                      mt: 0.1,
                                    }}
                                  >
                                    {viewingReplies === comment.id
                                      ? "Hide "
                                      : `—View Replies (${comment.reply_on_comment.length})`}
                                  </Typography>
                                )}
                                {(userCheck === postData.user_id?.toString() ||
                                  userCheck === userId?.toString()) && (
                                  <IconButton
                                    onClick={(e) =>
                                      handleClick(e, comment.id, "comment")
                                    }
                                  >
                                    <MoreHorizOutlined />
                                  </IconButton>
                                )}

                                {/* Popover component */}
                                <Popover
                                  id={id}
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                  sx={{
                                    "& .MuiPaper-root": {
                                      boxShadow:
                                        "1px 1px 1px 1px #e0e0e0 !important", // Remove box-shadow
                                    },
                                  }}
                                  className="popover-container"
                                >
                                  <div className="popover-content">
                                    <Typography
                                      className="popover-button"
                                      onClick={() =>
                                        handleDeleteComments(
                                          deleteID,
                                          deleteType
                                        )
                                      }
                                    >
                                      Delete
                                    </Typography>
                                    <Divider />
                                    <Typography
                                      className="popover-button"
                                      onClick={handleClose}
                                    >
                                      Cancel
                                    </Typography>
                                  </div>
                                </Popover>
                              </span>
                            </Box>
                          }
                        />
                      </ListItem>

                      {/* {replyingTo === comment.id && (
                        <Box sx={{ ml: 4 }}>
                          <CommentInput
                            replyToComment={true}
                            postId={comment.id}
                            onPostSuccess={handlePostSuccess}
                          />
                        </Box>
                      )} */}
                      {/* Replies List */}
                      <Collapse
                        in={viewingReplies === comment.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box sx={{ ml: 5 }}>
                          {comment.reply_on_comment?.map((reply) => (
                            <ListItem
                              key={reply.id}
                              sx={{ alignItems: "flex-start" }}
                            >
                              <Avatar
                                src={`https://api.silocloud.io/get-file-content/${reply?.profile_image_path}`}
                                alt={reply?.profile_image_path}
                                sx={{ mr: 1, mt: 0.7, height: 20, width: 20 }}
                              />
                              <ListItemText
                                primary={
                                  <Typography variant="subtitle2">
                                    {reply.username}
                                  </Typography>
                                }
                                secondary={
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {reply.reply}
                                    {(userCheck ===
                                      postData.user_id?.toString() ||
                                      userCheck === userId?.toString()) && (
                                      <IconButton
                                        onClick={(e) =>
                                          handleClick(e, reply.id, "reply")
                                        }
                                      >
                                        <MoreHorizOutlined />
                                      </IconButton>
                                    )}
                                  </Box>
                                }
                              />
                            </ListItem>
                          ))}
                        </Box>
                      </Collapse>
                    </React.Fragment>
                  ))}
            </List>

            <Divider />
            <Typography
              variant="body2"
              // color="textSecondary"
              sx={{ display: caption === null ? "none" : "flex", ml: -0.7 }}
              component="p"
              fontSize={14}
            >
              <span style={{ display: "flex", margin: "5px 0" }}>
                <Avatar
                  src={`https://api.silocloud.io/get-file-content/${
                    profileImage ? profileImage : postData?.profile_image_path
                  }`}
                  alt={postData.username}
                  sx={{
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                    textTransform: "capitalize",
                    mr: 0.5,
                  }}
                />
                <span>
                  <span className="bold-username">
                    {userName ? userName : postData.username}{" "}
                  </span>

                  {caption && isExpanded
                    ? caption
                    : `${caption?.substring(0, 50)}...`}
                  {caption?.length > 50 && (
                    <span
                      onClick={toggleIsExpanded}
                      style={{
                        cursor: "pointer",
                        color: "#bfbfbf",
                        fontSize: "13px",
                      }}
                    >
                      {isExpanded ? " Read Less" : " Read More"}
                    </span>
                  )}
                </span>
              </span>
            </Typography>
            <div style={{ marginTop: "6px", gap: 15, display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  // flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleLikePost(postData.id)}
              >
                <Icon
                  icon={
                    likePost ? "ant-design:heart-filled" : "solar:heart-linear"
                  }
                  style={{
                    color: likePost
                      ? "#ec1313"
                      : theme.palette.mode === "dark"
                      ? "#fff"
                      : "#000",
                    fontSize: 25,
                    width: "1.1em",
                    height: "1em",
                  }}
                />
                <span style={{ fontSize: "15px" }}>{likeCount}</span>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  // flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Icon icon="iconamoon:comment-light" style={{ fontSize: 25 }} />
                <span style={{ fontSize: "15px" }}>
                  {postsData
                    ? postsData[0]?.comment_count
                    : postData?.comment_count}
                </span>
              </Box>
              <Divider />
            </div>

            <CommentInput
              replyToComment={commentReplyTrue}
              postId={commentReplyTrue ? commentReplyId : postData.id}
              onPostSuccess={handlePostSuccess}
              commentReplyUserName={replyingToName}
            />
            {/* </Box> */}
          </Grid>
        </Grid>

        <PostMoreMenuModal
          open={openMoreMenu}
          onClose={handleCloseModal}
          post={selectedPost}
          userId={userId}
          closeCommentModal={handleModalClose}
          postData={postData}
          following_status={following_status}
        />
      </Box>
    </Modal>
  );
}

export default CommentsModal;
