import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import {
  setCoverImage,
  setCurrentStep,
} from "../../store/slices/highlightSlice";
import { handleAddHighLight } from "../../http/postApiCalls";
import { useSnackbarUtils } from "../../utils/snackbar/snackbarUtils";
import LoadingBackdrop from "../../utils/progress/LoadingBackdrop";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../http";

const ChooseCoverImage = ({ open, handleClose }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarUtils();
  const dispatch = useDispatch();
  const { highlightName, coverImage, selectedStories } = useSelector(
    (state) => state.highlight
  );

  const [loading, setLoading] = useState(false);
  const [storyIds, setStoryIds] = useState([]);
  const [coverImgId, setCoverImgId] = useState();
  const [coverMediaType, setCoverMediaType] = useState("");
  const [coverMedia, setCoverMedia] = useState(null); // Binary data for media
  const [coverMediaFileName, setCoverMediaFileName] = useState(""); // Media filename

  console.log("storyIds", storyIds);

  useEffect(() => {
    const ids = selectedStories.map((story) => String(story.id)); // getting story ids
    setStoryIds(ids);
  }, [selectedStories]);

  useEffect(() => {
    if (coverImage) {
      const isVideo = coverMediaType === "video";

      fetchMediaAsBinary(coverImage, isVideo)
        .then((binaryData) => {
          setCoverMedia(binaryData); // Set binary data for cover media
          const extension = isVideo ? "mp4" : "jpg"; // Determine file extension based on media type
          setCoverMediaFileName(`cover.${extension}`);
          console.log("Binary data:", binaryData);
        })
        .catch((error) => {
          console.error("Error fetching media:", error);
        });
    }
  }, [coverImage, coverMediaType]);

  // Function to fetch media as binary (handles both image and video)
  async function fetchMediaAsBinary(mediaUrl, isVideo = false) {
    if (isVideo) {
      return new Promise((resolve, reject) => {
        const video = document.createElement("video");
        video.src = mediaUrl;
        video.crossOrigin = "anonymous"; // For CORS handling

        video.addEventListener("loadeddata", () => {
          video.currentTime = 1; // Capture thumbnail at 1 second
        });

        video.addEventListener("seeked", () => {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          // Convert canvas to binary data (thumbnail)
          canvas.toBlob((blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result); // Return binary data (ArrayBuffer)
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(blob);
          }, "image/jpeg"); // Change image format if needed
        });

        video.addEventListener("error", (e) => {
          reject("Error loading video: " + e.message);
        });
      });
    } else {
      const response = await fetch(mediaUrl);
      const blob = await response.blob();
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          resolve(reader.result); // Return binary data (ArrayBuffer)
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
      });
    }
  }

  const handleSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("story_ids", JSON.stringify(storyIds)); // Ensure it's JSON string
    formData.append("title", highlightName);
    formData.append("is_archived", true);

    // Append the binary data as a file to the formData
    if (coverMedia) {
      const binaryBlob = new Blob([coverMedia]); // Convert ArrayBuffer to Blob
      formData.append("cover_img", binaryBlob, coverMediaFileName); // Send binary as file
    }

    addHighlight(formData);
    handleClose();
  };

  const handleGoBack = () => {
    dispatch(setCurrentStep(2)); // Go back to the previous step
  };

  // Mutation for add highlight
  const { mutate: addHighlight, reset: addHighlightReset } = useMutation({
    mutationKey: ["add-highlight"],
    mutationFn: (formData) => handleAddHighLight(formData),
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries(["getHighlights"]);
        setLoading(false);
        showSuccessSnackbar("Highlight added successfully!");
      }
      addHighlightReset();
    },
    onError: (error) => {
      setLoading(false);
      console.error("Error adding highlight:", error);
    },
  });

  // Handle cover selection and fetching binary data
  const handleCoverSelect = (story) => {
    setCoverMediaType(story?.media_type);
    setCoverImgId(story?.id);
    dispatch(setCoverImage(story?.mediaUrls));
  };

  const isConfirmButtonDisabled = !coverImage;

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? 300 : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    maxHeight: 550,
    minHeight: 520,
    display: "flex",
    flexDirection: "column",
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="cover-image-modal-title"
      aria-describedby="cover-image-modal-description"
    >
      <Box sx={modalStyle}>
        <Box p={1} display="flex" alignItems="center">
          <IconButton onClick={handleGoBack} sx={{ mr: 1 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            id="modal-title"
            variant="h6"
            component="h3"
            flexGrow={1}
            textAlign="center"
          >
            Choose Cover Image
          </Typography>
          <IconButton onClick={handleClose} sx={{ mr: 1 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box
          p={1.5}
          sx={{ flexGrow: 1, overflow: "auto" }}
          className="custom-scrollbar-chat"
        >
          <Typography id="cover-image-modal-description" sx={{ mb: 2 }}>
            Select a cover image for your highlight from the selected stories.
          </Typography>

          <ImageList variant="masonry" cols={2} gap={8}>
            {selectedStories.map((story) => (
              <ImageListItem key={story.id}>
                {story.media_type === "image" ? (
                  <img
                    src={story.mediaUrls}
                    alt={story.title}
                    style={{
                      borderRadius: "8px",
                      cursor: "pointer",
                      filter:
                        coverImgId === story.id ? "brightness(0.7)" : "none",
                    }}
                    onClick={() => handleCoverSelect(story)}
                  />
                ) : (
                  <video
                    src={story.mediaUrls}
                    controls
                    style={{
                      borderRadius: "8px",
                      cursor: "pointer",
                      filter:
                        coverImgId === story.id ? "brightness(0.7)" : "none",
                      width: "100%", // Ensure video takes up full width
                      height: "auto",
                    }}
                    onClick={() => handleCoverSelect(story)}
                  />
                )}
                <ImageListItemBar
                  sx={{ background: "transparent" }}
                  title={story.title}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="center" my={1} p={1}>
          <Button
            onClick={handleSubmit}
            variant="outlined"
            disabled={isConfirmButtonDisabled}
            sx={{
              border: "1px solid #f44336",
              color: "#f44336",
              borderRadius: 3,
              textTransform: "none",
              padding: "5px 0",
              fontSize: "1rem",
              "&:hover": {
                backgroundColor: "#f44336",
                color: "#fff",
                border: "1px solid #f44336",
              },
            }}
          >
            Done
          </Button>
        </Box>

        <LoadingBackdrop loading={loading} />
      </Box>
    </Modal>
  );
};

export default ChooseCoverImage;
