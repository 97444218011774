// import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBuYhyc9kUY6YqyuCvg825Rvbk29Au8WGc",
//   authDomain: "chat-app-31985.firebaseapp.com",
//   projectId: "chat-app-31985",
//   storageBucket: "chat-app-31985.appspot.com",
//   messagingSenderId: "902012263055",
//   appId: "1:902012263055:web:f1ae200e97a798b8dbaefa",
//   measurementId: "G-VVEED8FQD8",
// };

import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDxOIVIs0Wt9FuDos20EmfbFBKJeP2exAg",
  authDomain: "chat-app-8acd7.firebaseapp.com",
  projectId: "chat-app-8acd7",
  storageBucket: "chat-app-8acd7.appspot.com",
  messagingSenderId: "435826054036",
  appId: "1:435826054036:web:d0ec2c9321da8fd6ca3581",
  measurementId: "G-9LH9C1LPJY",
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
