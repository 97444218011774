import React, { useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  TextField,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  addHighlightName,
  resetHighlight,
  setCurrentStep,
} from "../../store/slices/highlightSlice";

const AddHighlight = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const highlightName = useSelector((state) => state.highlight.highlightName);
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark";
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleInputChange = (e) => {
    dispatch(addHighlightName(e.target.value));
  };

  const handleNext = () => {
    dispatch(setCurrentStep(2)); // Proceed to the next step
  };

  // Reset highlight data when the modal is closed
  useEffect(() => {
    if (!open) {
      dispatch(resetHighlight());
    }
  }, [open, dispatch]);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? 300 : 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <Box p={1}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-title"
            variant="h6"
            component="h3"
            textAlign="center"
          >
            New Highlight
          </Typography>
        </Box>
        <Divider />
        <Box p={2}>
          <TextField
            fullWidth
            value={highlightName}
            onChange={handleInputChange}
            variant="outlined"
            inputProps={{ maxLength: 255 }}
            placeholder="Highlight Name"
            InputProps={{
              sx: {
                height: 60,
                backgroundColor: darkMode ? "#3c3c3c" : "#f5f5f5",
                borderRadius: "8px !important",
              },
            }}
            sx={{
              p: 0,
              my: 2,
              "& .MuiOutlinedInput-root": {
                padding: "0px 16px",
                backgroundColor: darkMode ? "#3c3c3c" : "#ffffff",
                height: 40,
                "& fieldset": {
                  border: darkMode
                    ? "0.7px solid #2b2b2b"
                    : "0.7px solid #b3dfea",
                },
                "&:hover fieldset": {
                  borderColor: "#De3744",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#De3744",
                  boxShadow: "0 0 0 1px #De3744",
                },
              },
            }}
          />
        </Box>
        <Divider />
        <Box display="flex" justifyContent="center" my={1}>
          <Button
            onClick={handleNext}
            variant="outlined"
            disabled={!highlightName.trim()}
            sx={{
              border: "1px solid #f44336",
              color: "#f44336",
              borderRadius: 3,
              textTransform: "none",
              padding: "5px 0",
              fontSize: "1rem",
              "&:hover": {
                backgroundColor: "#f44336",
                color: "#fff",
                border: "1px solid #f44336",
              },
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddHighlight;
