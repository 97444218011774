// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { combineReducers } from "redux";
import filteredImageReducer from "../store/slices/filteredImageSlice";
import fileSlice from "../store/slices/multipleImageSlice";
import { mediaUrlSlice } from "./slices/DraftStoryURLSlice";
import highlightReducer from "./slices/highlightSlice";
// Configure persist for the filteredImage slice
const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  filteredImage: filteredImageReducer,
  fileSlice: fileSlice,
  mediaUrlSlice: mediaUrlSlice,
  highlight: highlightReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

export const persistor = persistStore(store);
