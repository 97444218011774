import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Modal,
  Box,
  IconButton,
  LinearProgress,
  useMediaQuery,
  CircularProgress,
  Avatar,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useTheme,
} from "@mui/material";
import {
  Close as CloseIcon,
  VolumeOff as MuteIcon,
  VolumeUp as UnmuteIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  PlayArrow as PlayIcon,
  Pause as PauseIcon,
  MoreHorizOutlined,
} from "@mui/icons-material";
import StylishCircularProgress from "../../utils/progress/StylishCircularProgress ";
import Cancel from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  formatDistanceToNow,
} from "date-fns";
import { Icon } from "@iconify/react/dist/iconify.js";
import StoryShareModal from "./StoryShareModal";
import {
  handleDeleteHighlight,
  handleDeleteUserStory,
} from "../../http/postApiCalls";
import { useSnackbarUtils } from "../../utils/snackbar/snackbarUtils";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../http";
import LoadingBackdrop from "../../utils/progress/LoadingBackdrop";

const buttonStyles = {
  position: "absolute",
  top: "50%",
  color: "white",
  transform: "translateY(-50%)",
  zIndex: 1,
  backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
  borderRadius: "50%",
  padding: 10,
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Subtle shadow
  transition: "background-color 0.3s ease, transform 0.3s ease",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.7)", // Darker on hover
    transform: "scale(1.1)", // Slightly larger on hover
  },
  "&:disabled": {
    backgroundColor: "rgba(0, 0, 0, 0.3)", // Lighter background when disabled
    boxShadow: "none", // No shadow when disabled
  },
};

const HighlightStoryViewer = ({
  open,
  handleClose,
  storiesData,
  currentIndex,
  highlight,
}) => {
  const [index, setIndex] = useState(currentIndex);
  const [storyIndex, setStoryIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [muted, setMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentUser, setCurrentUser] = useState(storiesData[index]?.user_id);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarUtils();
  const [videoState, setVideoState] = useState({
    currentTime: 0,
    isPlaying: false,
  });
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const progressInterval = useRef(null);
  const progressDuration = 7000; // 6 seconds
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark";

  const currentStory = storiesData[index]?.stories[storyIndex];

  // Reset story index when modal opens
  useEffect(() => {
    if (open) {
      setIndex(currentIndex);
      setStoryIndex(0);
    }
  }, [open, currentIndex]);

  console.log("storiesData", storiesData);

  // // Handle media end
  // const handleMediaEnded = () => {
  //   if (storyIndex < storiesData[index]?.stories.length - 1) {
  //     setStoryIndex((prev) => prev + 1);
  //   } else if (index < storiesData.length - 1) {
  //     setIndex((prev) => prev + 1);
  //     setStoryIndex(0);
  //   } else {
  //     handleClose();
  //   }
  // };

  // // Handle progress bar for image stories
  // useEffect(() => {
  //   if (currentStory?.media_type === "image") {
  //     const startTime = Date.now();
  //     setProgress(0);

  //     progressInterval.current = setInterval(() => {
  //       const elapsed = Date.now() - startTime;
  //       const newProgress = (elapsed / progressDuration) * 100;
  //       setProgress(newProgress);

  //       if (elapsed >= progressDuration) {
  //         clearInterval(progressInterval.current);
  //         handleMediaEnded();
  //       }
  //     }, 100);

  //     return () => {
  //       clearInterval(progressInterval.current);
  //     };
  //   }
  // }, [currentStory, storyIndex]);

  // Handle video playback
  useEffect(() => {
    const videoElement = videoRef.current;

    if (currentStory?.media_type === "video" && videoElement) {
      setIsPlaying(true);

      videoElement.src = currentStory.mediaUrls;
      videoElement.muted = muted;
      videoElement.load();
      setIsPlaying(true);

      // Restore video state after changing mute/unmute
      videoElement.currentTime = videoState.currentTime;
      if (videoState.isPlaying) {
        videoElement
          .play()
          .catch((error) => console.error("Video play error:", error));
      }

      const handleCanPlay = () => {
        videoElement
          .play()
          .catch((error) => console.error("Video play error:", error));
        videoElement.removeEventListener("canplay", handleCanPlay);
      };

      videoElement.addEventListener("canplay", handleCanPlay);

      return () => {
        videoElement.removeEventListener("canplay", handleCanPlay);
        videoElement.pause();
      };
    }
  }, [currentStory, muted, videoState]);

  // Update progress bar for video stories
  const handleTimeUpdate = useCallback(() => {
    const videoElement = videoRef.current;
    if (videoElement && currentStory?.media_type === "video") {
      const currentProgress =
        (videoElement.currentTime / videoElement.duration) * 100;
      setProgress(currentProgress);
    }
  }, [currentStory]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [handleTimeUpdate]);

  // Handle mute/unmute
  const toggleMute = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      setVideoState({
        currentTime: videoElement.currentTime,
        isPlaying: !videoElement.paused,
      });
      setMuted((prevMuted) => {
        videoElement.muted = !prevMuted;
        if (!videoElement.paused) {
          videoElement
            .play()
            .catch((error) => console.error("Video play error:", error));
        }
        return !prevMuted;
      });
    }
  };

  // Handle play/pause
  const togglePlayPause = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (isPlaying) {
        videoElement.pause();
      } else {
        videoElement
          .play()
          .catch((error) => console.error("Video play error:", error));
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Handle navigation
  const handleNavigation = (direction) => {
    if (direction === "next") {
      if (storyIndex < storiesData[index]?.stories.length - 1) {
        setStoryIndex((prev) => prev + 1);
      } else if (index < storiesData.length - 1) {
        setIndex((prev) => prev + 1);
        setStoryIndex(0);
      }
      setIsPlaying(true);
      setProgress(0);
    } else if (direction === "prev") {
      if (storyIndex > 0) {
        setStoryIndex((prev) => prev - 1);
      } else if (index > 0) {
        setIndex((prev) => prev - 1);
        setStoryIndex(storiesData[index - 1]?.stories.length - 1);
      }
      setIsPlaying(true);
      setProgress(0);
    }
  };

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowRight") {
        handleNavigation("next");
      } else if (e.key === "ArrowLeft") {
        handleNavigation("prev");
      } else if (e.key === "Escape") {
        handleClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleClose]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true); // Set loading to true every time the story changes
    setProgress(0);
  }, [currentStory]);

  const handleImageLoad = () => {
    setLoading(false);
    setIsPlaying(true);
  };

  useEffect(() => {
    handleImageLoad();
  }, [currentStory]);

  const handleVideoLoad = () => {
    setLoading(false);
  };

  const [elapsedTime, setElapsedTime] = useState(0); // Store elapsed time for image stories

  // Progress Interval for Image Stories
  useEffect(() => {
    if (currentStory?.media_type === "image" && isPlaying) {
      const startTime = Date.now() - elapsedTime; // Continue from where we left off
      setProgress((elapsedTime / progressDuration) * 100); // Set progress based on elapsed time

      progressInterval.current = setInterval(() => {
        const elapsed = Date.now() - startTime;
        const newProgress = (elapsed / progressDuration) * 100;
        setProgress(newProgress);

        if (elapsed >= progressDuration) {
          clearInterval(progressInterval.current);
          handleMediaEnded();
        }
      }, 100);

      return () => {
        clearInterval(progressInterval.current);
      };
    }
  }, [currentStory, storyIndex, isPlaying, elapsedTime]);

  // Toggle play/pause for both image and video stories
  const togglePlayPause1 = () => {
    const videoElement = videoRef.current;

    if (currentStory?.media_type === "video" && videoElement) {
      if (isPlaying) {
        videoElement.pause();
      } else {
        videoElement
          .play()
          .catch((error) => console.error("Video play error:", error));
      }
    } else if (currentStory?.media_type === "image") {
      if (isPlaying) {
        // Store the elapsed time when pausing
        const timeElapsed = (progress / 100) * progressDuration;
        setElapsedTime(timeElapsed);
      }
    }

    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };

  // Handle media end for both images and videos
  const handleMediaEnded = () => {
    clearInterval(progressInterval.current);
    setElapsedTime(0); // Reset elapsed time for the next story

    if (storyIndex < storiesData[index]?.stories.length - 1) {
      setStoryIndex((prev) => prev + 1);
      setProgress(0);
      togglePlayPause1();
    } else if (index < storiesData.length - 1) {
      setIndex((prev) => prev + 1);
      setStoryIndex(0);
      setProgress(0);
      togglePlayPause1();
    } else {
      handleClose();
      togglePlayPause1();
    }
  };

  const userCheck = localStorage.getItem("loggedIn_user_id");
  // Function to navigate to user's profile
  const handleGoToOtherUserProfile = (id) => {
    console.log("id of story user", id);
    navigate(userCheck === id.toString() ? "/profile" : `/other/profile/${id}`);
  };

  const formatTimeDifference = (date) => {
    const now = new Date();
    const secondsDiff = differenceInSeconds(now, date);

    if (secondsDiff < 60) {
      return `${secondsDiff}s`; // seconds
    } else if (secondsDiff < 3600) {
      // less than an hour
      const minutesDiff = differenceInMinutes(now, date);
      return `${minutesDiff}m`; // minutes
    } else if (secondsDiff < 86400) {
      // less than a day
      const hoursDiff = differenceInHours(now, date);
      return `${hoursDiff}h`; // hours
    } else {
      return `${Math.floor(secondsDiff / 86400)}d`; // days
    }
  };

  const [sendModalOpen, setSendModalOpen] = useState(false);
  const [storyUrl, setStoryUrl] = useState(false);

  const handleShareStory = (url) => {
    setStoryUrl(url);
    setSendModalOpen(true);
  };
  const handleCloseModalSend = () => {
    setSendModalOpen(false);
  };

  // Component Logic
  const [open1, setOpen1] = useState(false);
  const [userId, setUserId] = useState("");
  const [storyId, setStoryId] = useState("");
  const [highlightId, setHighlightId] = useState("");
  const [loading1, setLoading1] = useState(false);

  // Close dialog handler
  const handleClose1 = () => {
    setOpen1(false);
    togglePlayPause1();
  };

  // Function to handle deleting a story
  const handleDeleteStory = () => {
    setLoading1(true);
    const formData = new FormData();
    formData.append("id", highlightId?.toString());
    formData.append("story_id", storyId?.toString());
    deleteHighlight(formData);
  };

  // Function to handle reporting a story
  const handleReportStory = (storyId) => {
    console.log(`Reporting story with ID: ${storyId}`);
    // Add report functionality here (e.g., API call)
  };

  // Function to trigger story action dialog (delete/report)
  const handleStoryAction = (id, storyId, highLightId) => {
    togglePlayPause1();
    setUserId(id);
    setOpen1(true);
    setStoryId(storyId);
    setHighlightId(highLightId);
  };

  // Mutation for deleting the post
  const { mutate: deleteHighlight, reset: deleteHighlightreset } = useMutation({
    mutationKey: ["delete-highlight-story"],
    mutationFn: (fd) => handleDeleteHighlight(fd),
    onSuccess: (data) => {
      // setLoading(false);
      if (data?.status) {
        queryClient.invalidateQueries(["gethighlight"]);
        setLoading1(false);
        setProgress(0);
        handleClose1();

        showSuccessSnackbar("Story highlight deleted successfully!");
      }
      deleteHighlightreset();
    },
    onError: (data) => {
      handleClose1();
      setLoading(false);
    },
  });
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="story-modal-title"
      aria-describedby="story-modal-description"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        width="100vw"
        bgcolor={"#131212c9"}
      >
        <Tooltip title={"Close"}>
          <IconButton
            sx={{
              position: "absolute",
              top: 20,
              right: 20,
              zIndex: 1000,
            }}
            onClick={handleClose}
            className="fw-600"
          >
            <CloseIcon
              sx={{ height: 35, width: 35, color: "white", fontWeight: "bold" }}
            />
          </IconButton>
        </Tooltip>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={isSmallScreen ? "100vw" : "100vw"}
          height="90vh"
          padding={"0 35px"}
          borderRadius={5}
          position="relative"
          onClick={(e) => e.stopPropagation()}
        >
          <Box
            position="relative"
            height="100%"
            bgcolor="black"
            borderRadius={3}
            width={isSmallScreen ? "100vw" : "22vw"}
            // overflow="hidden"
          >
            {loading && (
              <Box
                position="absolute"
                top="50%"
                left="48%"
                transform="translate(-50%, -50%)"
                zIndex={10}
              >
                {/* <CircularProgress color="primary" /> */}
                <StylishCircularProgress />
              </Box>
            )}
            <span style={{ overflow: "hidden" }}>
              {currentStory?.media_type === "video" ? (
                <video
                  src={currentStory?.mediaUrls}
                  autoPlay
                  ref={videoRef}
                  style={{
                    width: "100%",
                    height: "100%",
                    display: loading ? "none" : "block",
                  }}
                  onCanPlay={handleVideoLoad}
                  onEnded={handleMediaEnded}
                  // poster={currentStory?.mediaUrls} // Optional: add a poster for better UX
                />
              ) : (
                <img
                  src={currentStory?.mediaUrls}
                  alt="story"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    display: loading ? "none" : "block",
                  }}
                  onLoad={handleImageLoad}
                />
              )}
            </span>
            <Box
              position="absolute"
              top={-2}
              left={0}
              right={0}
              display="flex"
              justifyContent="space-between"
              p={1}
            >
              {storiesData[index]?.stories.map((story, i) => (
                <LinearProgress
                  key={i}
                  color="error"
                  variant="determinate"
                  value={storyIndex === i ? progress : i < storyIndex ? 100 : 0}
                  style={{
                    flexGrow: 1,
                    margin: "0 2px",
                    height: "2px",
                  }}
                />
              ))}
            </Box>
            <Box
              position="absolute"
              top={12}
              left={10}
              display="flex"
              alignItems="center"
              color="white"
              sx={{ cursor: "pointer" }}
            >
              {/* <Avatar
                src={`https://api.silocloud.io/get-file-content/${storiesData[index]?.profile_image_path}`}
                alt={storiesData[index]?.title}
                style={{
                  width: 30,
                  textTransform: "capitalize",
                  height: 30,
                  borderRadius: "50%",
                  marginRight: 10,
                }}
              /> */}
              {/* <img
                src={`https://api.silocloud.io/get-file-content/${storiesData[index]?.profile_image_path}`}
                alt="avatar"
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  marginRight: 10,
                }}
              /> */}
              <span style={{ textTransform: "capitalize", gap: 2 }}>
                <span style={{ fontSize: 14 }}>
                  {storiesData[index]?.title?.length > 10
                    ? `${storiesData[index]?.title?.slice(0, 10)}...`
                    : storiesData[index]?.title}
                </span>
                <span
                  style={{ marginLeft: "10px", color: "#CCC4BE", fontSize: 10 }}
                >
                  {formatTimeDifference(new Date(currentStory?.created_at), {
                    // addSuffix: true,
                  })}
                </span>
              </span>
            </Box>
            {currentStory?.media_type === "video" && (
              <>
                <IconButton
                  onClick={toggleMute}
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 54,
                    color: "white",
                    mt: 1.1,
                  }}
                >
                  {muted ? <MuteIcon /> : <UnmuteIcon />}
                </IconButton>
                <IconButton
                  onClick={togglePlayPause}
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 26,
                    color: "white",
                    mt: 1.1,
                  }}
                  aria-label={isPlaying ? "Pause video" : "Play video"}
                >
                  {isPlaying ? <PauseIcon /> : <PlayIcon />}
                </IconButton>
              </>
            )}
            {/* // Rendering play/pause buttons for both images and videos */}
            {currentStory?.media_type === "image" ||
            currentStory?.media_type === "video" ? (
              <>
                <IconButton
                  onClick={togglePlayPause1}
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 26,
                    color: "white",
                    mt: 1.1,
                  }}
                  aria-label={isPlaying ? "Pause" : "Play"}
                >
                  {isPlaying ? <PauseIcon /> : <PlayIcon />}
                </IconButton>

                <IconButton
                  onClick={() =>
                    handleStoryAction(
                      currentStory?.user_id.toString(),
                      currentStory?.id,
                      storiesData[index]?.highlight_id
                    )
                  }
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 0,
                    color: "white",
                    mt: 1.1,
                  }}
                >
                  <MoreHorizOutlined />
                </IconButton>

                {/* <IconButton
                  onClick={() => handleShareStory(currentStory?.mediaUrls)}
                  sx={{
                    position: "absolute",
                    bottom: 10,
                    right: 10,
                    color: "white",
                    mt: 1.1,
                  }}
                  aria-label={isPlaying ? "Pause" : "Play"}
                >
                  <Icon
                    icon="tabler:send"
                    style={{ fontSize: 23, marginTop: "5%" }}
                  />{" "}
                </IconButton> */}
              </>
            ) : null}
            <IconButton
              onClick={() => handleNavigation("prev")}
              style={{
                ...buttonStyles,
                left: -50,
                display: index === 0 && storyIndex === 0 ? "none" : "flex",
              }}
              disabled={index === 0 && storyIndex === 0}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              onClick={() => handleNavigation("next")}
              style={{
                ...buttonStyles,
                right: -50,
                display:
                  index === storiesData.length - 1 &&
                  storyIndex === storiesData[index]?.stories.length - 1
                    ? "none"
                    : "flex",
              }}
              disabled={
                index === storiesData.length - 1 &&
                storyIndex === storiesData[index]?.stories.length - 1
              }
            >
              <ArrowForwardIcon />
            </IconButton>
          </Box>
        </Box>

        {sendModalOpen && (
          <StoryShareModal
            open={sendModalOpen}
            handleClose={handleCloseModalSend}
            shareableUrl={storyUrl}
          />
        )}

        {/* Dialog for Story Actions */}
        <Dialog
          open={open1}
          onClose={handleClose1}
          PaperProps={{
            style: {
              borderRadius: "12px",
              padding: "0px",
              width: "300px",
              textAlign: "center",
              // backgroundColor: darkMode ? "#333" : "#fff",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <DialogActions
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0px",
              overflow: "hidden",
              gap: "0px", // Remove the gap for a list-like appearance
            }}
          >
            {/* Conditional Rendering based on user type */}
            {userCheck === userId ? (
              <>
                {/* Delete Button for self user */}
                <Button
                  onClick={() => handleDeleteStory(currentStory?.id)}
                  style={{
                    width: "100%",
                    backgroundColor: "transparent",
                    color: "#ff3d3d",
                    borderRadius: "0px",
                    // padding: "12px 0",
                    fontWeight: "bold",
                    fontSize: "16px",
                    textTransform: "none",
                    borderBottom: "1px solid #e0e0e0", // Divider between items
                  }}
                >
                  Delete
                </Button>

                {/* Cancel Button */}
                <Button
                  onClick={handleClose1}
                  style={{
                    width: "100%",
                    color: darkMode ? "#fff" : "#111",
                    borderRadius: "0px",
                    // padding: "12px 0",
                    fontWeight: "bold",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                {/* Report Button for other users */}
                <Button
                  onClick={() => handleReportStory(storiesData[index]?.id)}
                  style={{
                    width: "100%",
                    backgroundColor: "transparent",
                    color: "#ff3d3d",
                    borderRadius: "0px",
                    // padding: "12px 0",
                    fontWeight: "bold",
                    fontSize: "16px",
                    textTransform: "none",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  Report
                </Button>

                {/* About this account Button for other users */}
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: "transparent",
                    color: darkMode ? "#fff" : "#111",
                    borderRadius: "0px",
                    // padding: "12px 0",
                    fontWeight: "bold",
                    fontSize: "16px",
                    textTransform: "none",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                  onClick={() =>
                    handleGoToOtherUserProfile(storiesData[index]?.user_id)
                  }
                >
                  About this account
                </Button>

                {/* Cancel Button */}
                <Button
                  onClick={handleClose1}
                  style={{
                    width: "100%",
                    color: darkMode ? "#fff" : "#111",
                    borderRadius: "0px",
                    // padding: "12px 0",
                    fontWeight: "bold",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
              </>
            )}
          </DialogActions>
          <LoadingBackdrop loading={loading1} />
        </Dialog>
      </Box>
    </Modal>
  );
};

export default HighlightStoryViewer;
