import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Checkbox,
  Container,
  CircularProgress,
  Skeleton,
  Avatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleStorySelection,
  setCurrentStep,
} from "../../store/slices/highlightSlice";
import { handleGetArchivedStories } from "../../http/getApiCalls";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../utils/progress/Loader";
import communityLogo from "../../assets/images/Community.png";

const ArchiveStories = ({ open, handleClose }) => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark";
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const selectedStories = useSelector(
    (state) => state.highlight.selectedStories
  );

  const {
    data: archiveStories,
    isLoading: archiveStoryDataIsLoading,
    isRefetching: archiveStoryDataIsRefetch,
    refetch: archiveStoryDataRefetch,
  } = useQuery({
    queryKey: ["getarchiveStoryData"],
    queryFn: async () => handleGetArchivedStories(),
    gcTime: 0,
    staleTime: Infinity,
  });

  const handleStorySelect = (story) => {
    dispatch(toggleStorySelection(story));
  };

  const handleNext = () => {
    dispatch(setCurrentStep(3)); // Proceed to the next step
  };

  const handleGoBack = () => {
    dispatch(setCurrentStep(1)); // Go back to the previous step
  };

  const isNextButtonDisabled = selectedStories.length === 0;

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? 300 : 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    maxHeight: 550,
    minHeight: 520,
    display: "flex",
    flexDirection: "column",
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="archive-modal-title"
      aria-describedby="archive-modal-description"
    >
      <Box sx={modalStyle}>
        <Box p={1} display="flex" alignItems="center">
          <IconButton onClick={handleGoBack} sx={{ mr: 1 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            id="modal-title"
            variant="h6"
            component="h3"
            flexGrow={1}
            textAlign="center"
          >
            Select Stories
          </Typography>
          <IconButton onClick={handleClose} sx={{ mr: 1 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box
          p={1.5}
          sx={{ flexGrow: 1, overflow: "auto" }}
          className="custom-scrollbar-chat"
        >
          <Typography id="archive-modal-description" sx={{ mb: 2 }}>
            Select stories from the list below to add to your highlights.
          </Typography>

          <ImageList variant="masonry" cols={3} gap={8}>
            {archiveStoryDataIsLoading
              ? Array.from(new Array(5))?.map((_, index) => (
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <Skeleton height={250} width={100} />
                  </span>
                ))
              : Array.isArray(archiveStories) && archiveStories.length !== 0
              ? archiveStories?.map((storyData) =>
                  storyData.stories.map((story) => (
                    <ImageListItem
                      key={story.id}
                      sx={{
                        border: "1px solid #e6e3e3",
                        borderRadius: 2,
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {story.media_type === "image" ? (
                        <img
                          src={story.mediaUrls}
                          alt={`Story ${story.id}`}
                          style={{
                            borderRadius: "8px",
                            cursor: "pointer",
                            position: "relative",
                            filter: selectedStories.some(
                              (s) => s.id === story.id
                            )
                              ? "brightness(0.7)"
                              : "none",
                          }}
                          onClick={() => handleStorySelect(story)}
                        />
                      ) : (
                        <video
                          src={story.mediaUrls}
                          controls
                          style={{
                            // borderRadius: "8px",
                            cursor: "pointer",
                            position: "relative",
                            filter: selectedStories.some(
                              (s) => s.id === story.id
                            )
                              ? "brightness(0.7)"
                              : "none",
                            width: "100%", // Ensures the video fits in the container
                          }}
                          onClick={() => handleStorySelect(story)}
                        />
                      )}
                      <ImageListItemBar
                        sx={{
                          background: "transparent",
                        }}
                        title={`Story ${story.id}`}
                        actionIcon={
                          <Checkbox
                            checked={selectedStories.some(
                              (s) => s.id === story.id
                            )}
                            onChange={() => handleStorySelect(story)}
                            icon={
                              <Box
                                sx={{
                                  width: 21,
                                  height: 21,
                                  borderRadius: "50%", // Circular shape
                                  border: "2px solid rgba(255, 99, 71, 0.8)", // Reddish border for unchecked state
                                }}
                              />
                            }
                            checkedIcon={
                              <Box
                                sx={{
                                  width: 24,
                                  height: 24,
                                  borderRadius: "50%", // Circular shape remains after checked
                                  backgroundColor: "#fff5f5", // Reddish background when selected
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#fff", // White checkmark
                                }}
                              >
                                <Avatar
                                  src={communityLogo}
                                  sx={{
                                    height: 18,
                                    width: 18,
                                    borderRadius: 0,
                                  }}
                                />
                              </Box>
                            }
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              left: "50%",
                              transform: "translateX(-50%)",
                            }}
                          />
                        }
                      />
                    </ImageListItem>
                  ))
                )
              : "No stories available"}
          </ImageList>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="center" my={1}>
          <Button
            onClick={handleNext}
            variant="outlined"
            disabled={isNextButtonDisabled}
            sx={{
              border: "1px solid #f44336",
              color: "#f44336",
              borderRadius: 3,
              textTransform: "none",
              padding: "5px 0",
              fontSize: "1rem",
              "&:hover": {
                backgroundColor: "#f44336",
                color: "#fff",
                border: "1px solid #f44336",
              },
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ArchiveStories;
