import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  Box,
  Skeleton,
  Divider,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  Collapse,
  Grid,
} from "@mui/material";
import { ArrowBack, VolumeOff, VolumeUp } from "@mui/icons-material";

import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Cookies from "js-cookie";
import CommentInput from "../../utils/CommentInput";
import { handleGetPosts } from "../../http/getApiCalls";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getRequest } from "../../api-service";
import { queryClient } from "../../http";
import { handlePostLike } from "../../http/postApiCalls";
import { Icon } from "@iconify/react/dist/iconify.js";
import PostMoreMenuModal from "../../more-menus/PostMoreMenuModal";

const PostPage = () => {
  const navigate = useNavigate();
  const { postId } = useParams(); // Extract the postId from the URL params
  const [postData, setPostData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const authToken = Cookies.get("authToken");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();

  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isPosted, setIsPosted] = useState(false);
  //   const [likePost, setLikePost] = useState(postData.liked_by_user);
  const [likePost, setLikePost] = useState(false);

  const [likeCount, setLikeCount] = useState(0);

  const videoRef = useRef(null);
  const emojiPickerRef = useRef(null);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMuteToggle = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handlePlayPauseToggle = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play().catch((error) => {
          console.error("Error playing video:", error);
        });
        setIsPaused(false);
      } else {
        videoRef.current.pause();
        setIsPaused(true);
      }
    }
  };

  const [replyingTo, setReplyingTo] = useState(null);
  const [commentReplyTrue, setCommentReplyTrue] = useState(false);
  const [replyingToName, setReplyingToName] = useState("");
  const [commentReplyId, setCommentReplyId] = useState(null);

  const handleReplyClick = (commentId, commentUsername) => {
    setCommentReplyId(commentId);
    setCommentReplyTrue(true);
    setReplyingToName(`@${commentUsername}`);
    setReplyingTo((prev) => (prev === commentId ? null : commentId));
  };

  const [viewingReplies, setViewingReplies] = useState(null);

  const handleViewRepliesClick = (commentId) => {
    setViewingReplies((prev) => (prev === commentId ? null : commentId));
  };
  const handlePostSuccess = () => {
    // getCommentsOfPost();
    fetchPostData();
    setReplyingTo(null);
    setReplyingToName("");
    setCommentReplyTrue(false);
    setCommentReplyId(null);
  };

  useEffect(() => {
    // getCommentsOfPost();
  }, [isPosted]);

  // api for like post
  const handleLikePost = (id) => {
    setLikePost(!likePost);
    setLikeCount(likePost ? likeCount - 1 : likeCount + 1);
    // postRequest("/community-post/togglelike-post", { post_id: id }).then(
    //   (res) => {
    //     console.log("post liked");
    //   }
    // );
    const formData = new FormData();
    formData.append("post_id", id);

    postLike(formData);
  };

  const { mutate: postLike, reset: postLikeReset } = useMutation({
    mutationKey: ["post-like"],
    mutationFn: handlePostLike,
    onSuccess: (data) => {
      // console.log("final data", data);
      if (data?.status) {
        // showSuccessToast(data.message, darkMode);
        fetchPostData();

        queryClient.invalidateQueries(["getAllPostData"]);
      } else {
        // showErrorToast(data?.message, darkMode);
      }

      postLikeReset();
    },
  });

  useEffect(() => {
    fetchPostData(); // Call the async function

    return () => {
      // Optional cleanup logic if needed
    };
  }, [authToken, commentReplyId, commentReplyTrue]); // Ensure that `authToken` is part of the dependencies
  const fetchPostData = async () => {
    try {
      const fd = new FormData();
      fd.append("unique_link", postId);

      let res = await fetch(
        `https://api.silocloud.io/api/v1/community-post/get-post-by-link`,
        {
          method: "POST",
          cache: "no-store",
          body: fd,
          headers: {
            authToken: authToken,
          },
        }
      );

      const data = await res.json();
      setPostData(data);
      setLikePost(data.liked_by_user);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching post data:", error);
      setError("Failed to fetch post data.");
      setLoading(false);
    }
  };
  const [openMoreMenu, setOpenMoreMenu] = useState(false);
  const [selectedPost, setSelectedPost] = useState([]);
  const handleOpenModal = (post) => {
    setSelectedPost(post);
    setOpenMoreMenu(true);
  };
  const handleCloseModal = () => {
    setOpenMoreMenu(false);
  };
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <Card
          sx={{
            width: 1200,
            maxWidth: 1200,
            display: "flex",
            margin: "auto",
            mt: 3,
          }}
        >
          {/* Post Media Skeleton */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <CardMedia
                component="div"
                sx={{ width: "100%", height: "80%", p: 2 }}
              >
                <Skeleton
                  variant="rectangular"
                  sx={{ height: "100%", borderRadius: 2 }}
                />
              </CardMedia>
            </Grid>
            <Grid item xs={12} sm={4}>
              <CardContent sx={{ flex: 1 }}>
                <Box display="flex" flexDirection="column" height="100%">
                  <Skeleton variant="text" width="30%" />
                  <Skeleton variant="text" width="20%" sx={{ mt: 1 }} />
                  <Box display="flex" alignItems="center" mt={2}>
                    <Skeleton
                      variant="rectangular"
                      width={24}
                      height={24}
                      sx={{ mr: 1 }}
                    />
                    <Skeleton variant="text" width="20%" />
                    <Skeleton
                      variant="rectangular"
                      width={24}
                      height={24}
                      sx={{ ml: 2, mr: 1 }}
                    />
                    <Skeleton variant="text" width="20%" />
                  </Box>
                  <Skeleton variant="text" width="100%" sx={{ mt: 2 }} />
                  <Skeleton variant="text" width="60%" sx={{ mt: 1 }} />
                  <Skeleton variant="text" width="40%" sx={{ mt: 1 }} />
                </Box>
              </CardContent>
            </Grid>
            <Grid item xs={12} sm={4}>
              {Array.from(new Array(8)).map((_, index) => (
                <ListItem key={index}>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={30}
                    height={30}
                    sx={{ mr: 1, mt: -2 }}
                  />
                  <ListItemText
                    primary={
                      <Skeleton animation="wave" variant="text" width="150px" />
                    }
                    secondary={
                      <Skeleton animation="wave" variant="text" width="100px" />
                    }
                  />
                </ListItem>
              ))}
            </Grid>
          </Grid>

          {/* Post Details Skeleton */}
        </Card>
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!postData || !postData.data) {
    return <Typography color="error">No post data available.</Typography>;
  }

  const { post } = postData.data;

  // Parse media and likes data
  const media = JSON.parse(post.media);
  const likes = JSON.parse(post.likes);
  const taggedUsers = post.tagged_users_details;

  // Helper to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  const handleMuteUnmute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box display="flex" justifyContent="center" className="post-unique-id">
      <Card
        sx={{
          maxWidth: "lg",
          // boxShadow: 2,
          width: "100%",
          // pl: 2,
          // pr: 2,
          mt: 2.5,
          // ml: 6,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            {/* Post Media */}
            {/* <IconButton sx={{ ml: -2.5, mt: 1 }}>
              <ArrowBack onClick={handleGoBack} />
            </IconButton> */}
            <Box p={0.5}>
              <CardMedia
                component={media[0].media_type === "image" ? "img" : "video"}
                src={post.media_details[0].media_urls}
                autoPlay={media[0].media_type === "video"}
                muted={media[0].media_type === "video"}
                controls={false}
                loop={media[0].media_type === "video"}
                alt="Post media"
                className="post-unique-id"
                sx={{
                  width: "100%",
                  height: "580px",
                  objectFit: "cover",
                  // borderRadius: 2,
                }}
              />
              {/* Mute/Unmute Button */}
              <IconButton
                onClick={handleMuteUnmute}
                sx={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "#fff",
                }}
              >
                {isMuted ? <VolumeOff /> : <VolumeUp />}
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            {/* Post Details */}
            <CardContent sx={{ flex: 1, p: 2 }}>
              <Box display="flex" flexDirection="column" height="100%">
                <CardHeader
                  avatar={
                    <Avatar
                      src={`https://api.silocloud.io/get-file-content/${post?.profile_image_path}`}
                      alt={post.username}
                    />
                  }
                  action={
                    <IconButton
                      aria-label="settings"
                      onClick={() => handleOpenModal(post)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  }
                  title={post.username}
                  subheader={formatDate(post.created_at)}
                  sx={{ p: 0, mb: 1 }}
                />

                {/* Post Actions */}
                <Box display="flex" alignItems="center" mb={1}>
                  {/* <IconButton
                    aria-label="like"
                    // color={post.liked_by_user ? "error" : "default"}
                    color={post.liked_by_user ? "error" : "default"}
                  > */}
                  <Icon
                    onClick={() => handleLikePost(post.id)}
                    icon={
                      post.liked_by_user
                        ? "ant-design:heart-filled"
                        : "solar:heart-linear"
                    }
                    style={{
                      color: post.liked_by_user
                        ? "#ec1313"
                        : theme.palette.mode === "dark"
                        ? "#fff"
                        : "#000",
                      fontSize: 25,
                      width: "1.1em",
                      height: "1em",
                      cursor: "pointer",
                    }}
                  />
                  {/* <FavoriteIcon />
                  </IconButton> */}
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ ml: 1 }}
                  >
                    {/* {post.likes_count} likes */}
                    {post.likes_count === 1
                      ? `${post.likes_count} like`
                      : `${post.likes_count} likes`}
                  </Typography>
                  {/* <IconButton aria-label="comment" sx={{ ml: 2 }}>
                    <ChatBubbleOutlineIcon />
                  </IconButton> */}
                  <Icon
                    icon="iconamoon:comment-light"
                    style={{ fontSize: 22, marginLeft: "10px" }}
                  />

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ ml: 1 }}
                  >
                    {post.comment_count} comments
                  </Typography>
                </Box>

                {/* Post Caption */}
                <Typography variant="body2" color="text.primary" sx={{ mb: 1 }}>
                  <strong></strong> {post.caption}
                </Typography>

                {/* Tagged Users */}
                {/* {taggedUsers && taggedUsers.length > 0 && (
                  <Typography variant="body2" color="text.secondary">
                    Tagged:{" "}
                    {taggedUsers.map((user) => `@${user.username}`).join(", ")}
                  </Typography>
                )} */}

                {/* Post Location */}
                {post.location && (
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{
                      lineHeight: 1.5,
                      display: post.location === "undefined" ? "none" : "flex",
                    }}
                  >
                    <Icon icon="gis:location-poi" fontSize={15} />{" "}
                    <span style={{ marginLeft: "5px" }}>{post.location}</span>
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Grid>

          <Grid item xs={12} sm={4}>
            {/* Comments Section */}
            <Box
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6">Comments</Typography>

              {/* Scrollable Comments List */}
              <Box
                className="custom-scrollbar-chat"
                sx={{
                  flexGrow: 1,
                  height: 460,
                  overflowY: "auto", // Allow scrolling only in the comment list
                }}
              >
                <List
                  className="custom-scrollbar"
                  sx={{
                    maxHeight: "100%", // Ensure it takes the available space
                  }}
                >
                  {post.comments.length === 0 ? (
                    <Typography variant="subtitle" sx={{ mb: 2 }}>
                      No Comments Yet{" "}
                    </Typography>
                  ) : (
                    post.comments?.map((comment) => (
                      <React.Fragment key={comment.id}>
                        <ListItem sx={{ alignItems: "flex-start" }}>
                          <Avatar
                            src={`https://api.silocloud.io/get-file-content/${comment?.profile_image_path}`}
                            alt={comment?.username}
                            sx={{
                              mr: 1,
                              height: 25,
                              width: 25,
                              textTransform: "capitalize",
                            }}
                          />
                          <ListItemText
                            primary={
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    mr: 1,
                                    mt: -0.5,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {comment.username}
                                </Typography>
                              </Box>
                            }
                            secondary={
                              <Box sx={{ display: "", alignItems: "center" }}>
                                <Typography variant="subtitle2" sx={{ mr: 1 }}>
                                  {comment.comment}
                                </Typography>
                                <span style={{ display: "flex" }}>
                                  <Typography
                                    variant="body"
                                    onClick={() =>
                                      handleReplyClick(
                                        comment.id,
                                        comment.username
                                      )
                                    }
                                    sx={{ padding: "5px", cursor: "pointer" }}
                                  >
                                    Reply
                                  </Typography>

                                  {comment.reply_on_comment?.length > 0 && (
                                    <Typography
                                      variant="body"
                                      onClick={() =>
                                        handleViewRepliesClick(comment.id)
                                      }
                                      sx={{
                                        padding: "5px",
                                        cursor: "pointer",
                                        mt: 0.1,
                                      }}
                                    >
                                      {viewingReplies === comment.id
                                        ? "Hide "
                                        : `—View Replies (${comment.reply_on_comment.length})`}
                                    </Typography>
                                  )}
                                </span>
                              </Box>
                            }
                          />
                        </ListItem>

                        <Collapse
                          in={viewingReplies === comment.id}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ ml: 5 }}>
                            {comment.reply_on_comment?.map((reply) => (
                              <ListItem
                                key={reply.id}
                                sx={{ alignItems: "flex-start" }}
                              >
                                <Avatar
                                  src={`https://api.silocloud.io/get-file-content/${reply?.profile_image_path}`}
                                  alt={reply?.profile_image_path}
                                  sx={{
                                    mr: 1,
                                    mt: 0.7,
                                    height: 20,
                                    width: 20,
                                  }}
                                />
                                <ListItemText
                                  primary={
                                    <Typography variant="subtitle2">
                                      {reply.username}
                                    </Typography>
                                  }
                                  secondary={reply.reply}
                                />
                              </ListItem>
                            ))}
                          </Box>
                        </Collapse>
                      </React.Fragment>
                    ))
                  )}
                </List>
              </Box>

              {/* Fixed Comment Input at the bottom */}
              <Box
                sx={{
                  position: "sticky", // Keeps it fixed at the bottom
                  bottom: 0,
                  mt: 2, // Margin from the List
                  bgcolor: "background.paper", // Set background color to avoid overlap
                }}
              >
                <CommentInput
                  replyToComment={commentReplyTrue}
                  postId={commentReplyTrue ? commentReplyId : post.id}
                  onPostSuccess={handlePostSuccess}
                  commentReplyUserName={replyingToName}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Card>

      <PostMoreMenuModal
        open={openMoreMenu}
        onClose={handleCloseModal}
        post={selectedPost}
      />
    </Box>
  );
};

export default PostPage;
