import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Grid,
  Card,
  Modal,
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ImageListItemBar,
  ImageListItem,
  ImageList,
  Skeleton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  handleGetArchivedPosts,
  handleGetArchivedStories,
} from "../../http/getApiCalls";
import ArchivePost from "./ArchivePost";
import { handleDeleteArchiveStory } from "../../http/postApiCalls";
import { queryClient } from "../../http";
import { useSnackbarUtils } from "../../utils/snackbar/snackbarUtils";
import LoadingBackdrop from "../../utils/progress/LoadingBackdrop";

export default function Archive() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 345px)");
  const isMediumScreen = useMediaQuery(
    "(min-width: 375px) and (max-width: 455px)"
  );

  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarUtils();

  const [activeTab, setActiveTab] = useState(0);
  const [openStoryModal, setOpenStoryModal] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const openMenu = Boolean(anchorEl);

  console.log("selectedStory", selectedStory);

  const {
    data: archiveStories,
    isLoading: archiveStoryDataIsLoading,
    isRefetching: archiveStoryDataIsRefetch,
    refetch: archiveStoryDataRefetch,
  } = useQuery({
    queryKey: ["getarchiveStoryData"],
    queryFn: async () => handleGetArchivedStories(),
    gcTime: 0,
    staleTime: Infinity,
  });

  const {
    data: posts,
    isLoading: archivePostDataIsLoading,
    isRefetching: archivePostDataIsRefetch,
    refetch: archivePostDataRefetch,
  } = useQuery({
    queryKey: ["getarchivePostsData"],
    queryFn: async () => handleGetArchivedPosts(),
    gcTime: 0,
    staleTime: Infinity,
  });
  useEffect(() => {
    if (activeTab === 1) {
      archivePostDataRefetch();
    } else {
      archiveStoryDataRefetch();
    }
  }, [activeTab]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedStory(null);
    console.log("close");
  };
  const handleArchivedStoryDelete = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("story_id", selectedStory?.id);
    deleteArchiveStory(formData); // Trigger mutation
  };

  // Mutation for deleting the post
  const { mutate: deleteArchiveStory, reset: deleteArchiveStoryreset } =
    useMutation({
      mutationKey: ["archive-story-delete"],
      mutationFn: (fd) => handleDeleteArchiveStory(fd),
      onSuccess: (data) => {
        // setLoading(false);
        if (data?.status) {
          queryClient.invalidateQueries(["gethighlight"]);
          setLoading(false);
          setAnchorEl(null);

          showSuccessSnackbar("Story deleted successfully!");
        }
        deleteArchiveStoryreset();
      },
      onError: (data) => {
        setLoading(false);
        setAnchorEl(null);
      },
    });

  const handleStorySelect = (story) => {
    console.log(story);
    setSelectedStory(story);
  };

  // Function to get the date (day) from the timestamp
  const getDayFromTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.getDate(); // Returns the day of the month (1-31)
  };

  // Function to get the month from the timestamp
  const getMonthFromTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("default", { month: "short" }); // Returns the short month name (e.g., "Oct")
  };

  return (
    <div>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="archive tabs"
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "red", // Set the indicator color to red
          },
          "& .MuiTab-root": {
            textTransform: "capitalize",
            fontSize: 16,
            fontWeight: 500,
            // color: "#111", // Set the text color to red for tabs
            "&.Mui-selected": {
              color: "red", // Optional: Set a different color for selected tab
            },
          },
        }}
        centered
      >
        <Tab label="Stories" />
        <Tab label="Posts" />
      </Tabs>

      <TabPanel value={activeTab} index={0}>
        <Box
          sx={{
            flexGrow: 1,
            // overflow: "auto",
            textAlign: "center",
          }}
          className="custom-scrollbar-chat"
        >
          <Grid container spacing={4}>
            {archiveStoryDataIsLoading ? (
              Array.from(new Array(5)).map((_, index) => (
                <Grid
                  item
                  xs={isSmallScreen ? 12 : isMediumScreen ? 6 : 2.4}
                  key={index}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Skeleton height={650} width={300} />
                </Grid>
              ))
            ) : archiveStories?.length > 0 ? (
              archiveStories.map((storyData) =>
                storyData?.stories?.map((story) => (
                  <Grid item xs={12} sm={6} md={3} key={story.id}>
                    <Box
                      sx={{
                        border: "1px solid #e6e3e3",
                        borderRadius: 1,
                        display: "flex",
                        minHeight: 400,
                        maxHeight: 400,
                        alignItems: "center",
                        justifyContent: "center", // Center the media inside the box
                        position: "relative",
                        // overflow: "hidden", // Prevent media from overflowing
                        "&:hover .moreAction": { opacity: 1 }, // Show moreAction button on hover
                      }}
                    >
                      {/* More Actions Button */}
                      <Box
                        className="moreAction"
                        sx={{
                          position: "absolute",
                          top: -12, // Move the button slightly above the box
                          right: -45, // Move the button slightly to the right of the box
                          opacity: 0, // Initially hidden
                          transition: "opacity 0.3s ease", // Smooth transition on hover
                          zIndex: 1, // Ensure it is on top of other content
                        }}
                        onClick={() => handleStorySelect(story)}
                      >
                        <IconButton
                          aria-label="more"
                          aria-controls="post-menu"
                          aria-haspopup="true"
                          onClick={handleMenuClick}
                          sx={iconButtonStyle}
                        >
                          <MoreVertIcon />
                        </IconButton>

                        <Menu
                          anchorEl={anchorEl}
                          open={openMenu}
                          onClose={handleMenuClose}
                        >
                          <MenuItem onClick={handleArchivedStoryDelete}>
                            Delete
                          </MenuItem>
                        </Menu>
                      </Box>

                      {/* Render Image or Video */}
                      {story.media_type === "image" ? (
                        <img
                          src={story.mediaUrls}
                          alt={`Story ${story.id}`}
                          style={{
                            cursor: "pointer",
                            maxHeight: "398px", // Ensure the video fits within the Box
                            maxWidth: "100%", // Ensure the image fits within the Box
                            objectFit: "cover", // Maintain the aspect ratio while covering the box
                          }}
                        />
                      ) : (
                        <video
                          src={story.mediaUrls}
                          controls={false}
                          loop
                          autoPlay
                          style={{
                            cursor: "pointer",
                            maxHeight: "398px", // Ensure the video fits within the Box
                            maxWidth: "100%", // Ensure the video fits within the Box
                            objectFit: "cover", // Maintain the aspect ratio while covering the box
                          }}
                        />
                      )}

                      {/* Date badge */}
                      <div
                        style={{
                          top: "2px",
                          left: "2px",
                          position: "absolute",
                          background: "inherit",
                          borderRadius: "4px",
                          padding: "2px",
                          textAlign: "center",
                        }}
                      >
                        <p style={{ margin: 0, fontWeight: "bold" }}>
                          {getDayFromTimestamp(story.created_at)}
                        </p>
                        <p style={{ margin: 0 }}>
                          {getMonthFromTimestamp(story.created_at)}
                        </p>
                      </div>
                    </Box>
                  </Grid>
                ))
              )
            ) : (
              <Grid item xs={12} sx={{ textAlign: "center", padding: 4 }}>
                <Typography variant="h6" color="textSecondary">
                  No stories available.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
        <LoadingBackdrop loading={loading} />
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <ArchivePost
          posts={posts}
          archivePostDataIsLoading={archivePostDataIsLoading}
        />{" "}
        {/* Use the PostsTab component */}
      </TabPanel>
    </div>
  );
}

// TabPanel component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

// Styles for the modal and its contents
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const modalImageStyle = {
  height: "200px",
  width: "200px",
  objectFit: "cover",
  marginTop: "16px",
};

const iconButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  zIndex: 6,
  boxShadow: 0,
  // color: "#262626", // Icon color to match Instagram's neutral theme
};
