import React, { useState, useEffect } from "react";
import {
  TextField,
  IconButton,
  InputAdornment,
  CircularProgress,
  Avatar,
  useMediaQuery,
  useTheme,
  Autocomplete,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { handleGetSiloUsers } from "../http/postApiCalls";
import { useMutation } from "@tanstack/react-query";
import micImg from "../assets/images/mic.png";

const SearchUsers = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [isListening, setIsListening] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to control dropdown
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const matches = useMediaQuery("(max-width:320px)");
  const userCheck = localStorage.getItem("loggedIn_user_id");

  // Fetch users with a limit of 30 initially
  const { mutate: getUsers, reset: getUsersReset } = useMutation({
    mutationKey: ["get-users"],
    mutationFn: (fd) => handleGetSiloUsers(fd),
    onSuccess: (data) => {
      setLoading(false);
      setIsListening(false); // Stop listening animation

      if (data?.status) {
        setUsers(data.message.users);
        setDropdownOpen(searchQuery && true); // Open dropdown on successful fetch
      }
      getUsersReset();
    },
    onError: () => {
      setLoading(false);
      setIsListening(false); // Stop listening animation on error
    },
  });

  // Fetch initial 30 users
  useEffect(() => {
    const fd = new FormData();
    fd.append("limit", 30);
    getUsers(fd);

    // Cleanup on unmount
    return () => {
      if (recognition) {
        recognition.onresult = null;
        recognition.onerror = null;
        recognition.onend = null;
      }
    };
  }, [getUsers]);

  // Check for browser support for SpeechRecognition
  const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = SpeechRecognition ? new SpeechRecognition() : null;

  // Function to handle search input change
  const handleSearchChange = (event, value) => {
    const query =
      value !== null && value !== undefined ? value : event?.target?.value;
    setSearchQuery(query);

    if (query.trim()) {
      setLoading(true);
      const fd = new FormData();
      fd.append("limit", 30);
      fd.append("search", query); // Send search query to the backend
      getUsers(fd);
    } else {
      setIsListening(false); // Stop listening animation
      setUsers([]); // Clear results if the query is empty
      setDropdownOpen(false); // Close dropdown when the query is cleared
    }
  };

  // Function to handle voice search (using Web Speech API)
  const handleVoiceSearch = () => {
    if (recognition) {
      recognition.start();
      setIsListening(true); // Start listening animation

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setSearchQuery(transcript); // Set the search query to recognized speech
        handleSearchChange(null, transcript); // Trigger the search
      };

      recognition.onend = () => {
        setIsListening(false); // Stop listening animation when recognition ends
      };

      recognition.onerror = (event) => {
        setIsListening(false); // Stop listening animation on error
        console.error("Speech recognition error", event.error);
        alert("An error occurred during voice recognition.");
      };
    } else {
      setIsListening(false); // Stop listening animation
      alert("Voice search is not supported in this browser.");
    }
  };

  // Function to navigate to user's profile
  const handleGoToOtherUserProfile = (id) => {
    navigate(userCheck === id.toString() ? "/profile" : `/other/profile/${id}`);
    setLoading(false);
    setIsListening(false); // Stop listening animation
    setSearchQuery("");
    setDropdownOpen(false); // Close dropdown after navigating
  };

  // Function to handle Enter key press and open dropdown
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && searchQuery.trim()) {
      setDropdownOpen(true); // Open dropdown when "Enter" is pressed
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Autocomplete
        freeSolo
        options={users}
        getOptionLabel={(option) => option?.username || ""} // Ensure a fallback if username is missing
        inputValue={searchQuery}
        onInputChange={handleSearchChange}
        open={dropdownOpen} // Control dropdown open state
        onOpen={() => setDropdownOpen(true)} // Open dropdown on input focus
        onClose={() => setDropdownOpen(false)} // Close dropdown on blur
        noOptionsText={
          searchQuery && !loading && !isListening ? "No user found" : ""
        }
        renderOption={(props, option) => (
          <li
            {...props}
            onClick={() => handleGoToOtherUserProfile(option.id)}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              zIndex: 1,
            }}
          >
            <Avatar
              alt={option.username}
              src={option.profile_image_path || "/default-avatar.png"}
              sx={{
                width: isSmallScreen ? 20 : 32,
                height: isSmallScreen ? 20 : 32,
                mr: isSmallScreen ? 0.5 : 2,
                ml: isSmallScreen ? -1 : 0,
              }}
            />
            {option.username}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Search users"
            onKeyPress={handleKeyPress} // Detect Enter key press
            InputProps={{
              ...params.InputProps,
              style: { paddingRight: 10 }, // Add padding-right logic

              startAdornment: (
                <InputAdornment position="start">
                  <IconButton type="submit" sx={{ p: 0 }}>
                    <Search fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {loading || isListening ? (
                    <CircularProgress size={15} color="error" />
                  ) : (
                    <IconButton
                      onClick={handleVoiceSearch}
                      sx={{ p: 0 }}
                      size="small"
                    >
                      <img src={micImg} height={22} width={16} />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            sx={{
              mx: 2,
              width: isSmallScreen ? (matches ? 105 : 150) : 240,
              height: 35,
              zIndex: 1,
            }}
          />
        )}
        sx={{
          zIndex: 1,
        }}
      />

      {users.length === 0 && searchQuery && !loading && !isListening && (
        <div style={{ marginTop: 10 }}>No user available</div>
      )}
    </div>
  );
};

export default SearchUsers;
