import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Paper,
  Box,
  Typography,
  useTheme,
  IconButton,
} from "@mui/material";
import StoryModal from "./stories/StoryModal"; // Import the StoryModal component
import { Icon } from "@iconify/react/dist/iconify.js";
import AddStoryModal from "./stories/AddStoryModal ";
import { getRequest } from "../api-service";
import storyBG from "../assets/images/storyBG.jpg";
import storyBG1 from "../assets/images/storyBG1.jpg.png";
import { useCookies } from "react-cookie";
import { handleGetStories } from "../http/getApiCalls";
import { useQuery } from "@tanstack/react-query";
import communityLOgo from "../../src/assets/images/Community.png";
import Cookies from "js-cookie";
import highlightLogo from "../assets/images/addHighLight.png";

function Stories() {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [addStoryModalOpen, setAddStoryModalOpen] = useState(false);
  const [stories1, setStories1] = useState([]);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(null);
  const storiesRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);
  const [hovered, setHovered] = useState(false);

  const uesrpro = Cookies.get("uesrpro");

  const scrollLeft = () => {
    storiesRef.current.scrollBy({ left: -350, behavior: "smooth" });
  };

  const scrollRight = () => {
    storiesRef.current.scrollBy({ left: 350, behavior: "smooth" });
  };

  const handleScroll = () => {
    const scrollLeftPos = storiesRef.current.scrollLeft;
    const maxScrollLeft =
      storiesRef.current.scrollWidth - storiesRef.current.clientWidth;

    // Update button visibility based on the scroll position
    setShowLeftButton(scrollLeftPos > 0);
    setShowRightButton(scrollLeftPos < maxScrollLeft);
  };

  useEffect(() => {
    const refCurrent = storiesRef.current;
    refCurrent.addEventListener("scroll", handleScroll);

    // Initial check to set correct button visibility
    handleScroll();

    return () => {
      refCurrent.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const {
    data: storyData,
    isLoading: storyDataIsLoading,
    isRefetching: storyDataIsRefetch,
    refetch: storyDataRefetch,
  } = useQuery({
    queryKey: ["getStoryData"],
    queryFn: async () => handleGetStories(),
    gcTime: 0,
    staleTime: Infinity,
  });

  console.log("storydata", storyData);

  const handleOpenModal = (index) => {
    console.log("currentStoryIndex", index);
    setCurrentStoryIndex(index);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentStoryIndex(null);
  };

  const handleAddStory = () => {
    setAddStoryModalOpen(true);
  };

  const handleCloseAddStoryModal = () => {
    setAddStoryModalOpen(false);
  };

  const userCheck = localStorage.getItem("loggedIn_user_id");
  const [userStory, setUserStory] = useState(null);
  console.log("userStory", userStory);
  // useEffect(() => {
  //   if (!storyData) return;
  //   const foundStory = storyData?.find((story) => story.user_id === userCheck);
  //   setUserStory(foundStory);
  // }, [storyData, userCheck]);

  return (
    <div style={{ position: "relative", marginTop: "5px" }}>
      {/* <Paper> */}
      {showLeftButton && (
        <IconButton
          onClick={scrollLeft}
          sx={{
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            borderRadius: "50%",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
            color: "#fff",
            padding: "3px",
          }}
        >
          <Icon icon="mdi:chevron-left" fontSize={20} />
        </IconButton>
      )}
      <Box
        ref={storiesRef}
        display="flex"
        className="custom-scrollbar"
        elevation={2}
        style={{
          padding: "10px 0px",
          marginBottom: 20,
          overflowX: "auto",
          whiteSpace: "nowrap",
          paddingRight: "10px",
        }}
      >
        {/* Add Story Box */}
        <div style={{ display: "block" }} ref={storiesRef}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "3px",
              ml: 1,
              mt: 0.7,
              cursor: "pointer",
              minHeight: "150px",
              maxHeight: "150px",
              minWidth: "110px",
              maxWidth: "110px",
            }}
            onClick={handleAddStory}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "125px", // 80% of the box height
                width: "100%",
                position: "relative",
              }}
            >
              {/* Always display the communityLogo */}
              <Avatar
                src={communityLOgo}
                sx={{
                  height: "fit-content",
                  width: "fit-content",
                  borderRadius: "10px",
                  overflow: "hidden",
                  objectFit: "contain",
                }}
              />

              {/* The user avatar stays constant */}
              <Avatar
                src={uesrpro}
                sx={{
                  position: "absolute",
                  top: "21%",
                  left: "21%",
                  height: 74,
                  width: 74,
                  borderRadius: "50%",
                  overflow: "hidden",
                  objectFit: "contain",
                  border: "2px solid #fff",
                }}
              />

              {/* Show + icon on hover */}
              {hovered && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "51%",
                    left: "57%",
                    transform: "translate(-50%, -50%)",
                    height: 71,
                    width: 71,
                    borderRadius: "50%",
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))", // Gradient background

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // boxShadow:
                    //   "0 0 15px rgba(255, 75, 43, 0.6), 0 0 20px rgba(255, 65, 108, 0.5)", // Multiple layers of shadow for a glowing effect
                    // border: "2px solid #fff",
                    // transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth transition for hover effects
                    "&:hover": {
                      // transform: "translate(-50%, -50%) scale(1.01)", // Slightly grow on hover
                      // boxShadow:
                      //   "0 0 25px rgba(255, 75, 43, 0.8), 0 0 30px rgba(255, 65, 108, 0.7)", // Glow more on hover
                    },
                  }}
                >
                  <Avatar
                    src={highlightLogo}
                    // height={100}
                    // width={100}
                    sx={{ cursor: "pointer", height: 50, width: 50 }}
                  />
                  {/* <Icon
                    icon="mingcute:add-fill"
                    height={28} // Slightly larger icon
                    style={{ color: "#fff" }}
                  /> */}
                </Box>
              )}
            </Box>

            {/* Optionally, add 'Add Story' text here */}
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Your Story
            </Typography>
          </Box>
        </div>

        {Array.isArray(storyData?.data) &&
          storyData.data.map((story, index) => (
            <div style={{ display: "block" }} ref={storiesRef}>
              <Box
                key={index}
                sx={{
                  display: "flex",
                  padding: "3px",
                  ml: 1,
                  borderRadius: "5%",
                  // backgroundImage: `url(${story.stories[0].mediaUrls})`,
                  backgroundSize: "cover", // Ensure the background covers the entire box
                  backgroundPosition: "center", // Center the image
                  cursor: "pointer",
                  minHeight: "150px",
                  minWidth: "115px",
                  maxWidth: "110px",
                  overflow: "hidden",
                  position: "relative", // Needed to position the overlay
                }}
                ref={storiesRef}
                onClick={() => handleOpenModal(index)}
              >
                <Box
                  sx={{
                    position: "absolute", // Overlay to darken the background
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    // backgroundColor: "rgba(0, 0, 0, 0.4)", // Black with 50% opacity
                    borderRadius: "5%", // Match the parent box's borderRadius
                    zIndex: 1, // Ensure the overlay is above the background image but below content
                  }}
                />
                <Box
                  sx={{
                    // display: "flex",
                    justifyContent: "start",
                    mt: 1,
                    ml: 1,
                    cursor: "pointer",
                    position: "relative", // Ensure content is above the overlay
                    zIndex: 2,
                  }}
                  onClick={() => handleOpenModal(index)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "125px", // 80% of the box height
                      width: "100%",
                      position: "relative",
                      objectFit: "contain",
                    }}
                  >
                    <Avatar
                      src={communityLOgo}
                      sx={{
                        height: "fit-content",
                        width: "fit-content",
                        borderRadius: "10px",
                        overflow: "hidden",
                        objectFit: "contain",
                      }}
                    />
                    <Avatar
                      src={`https://api.silocloud.io/get-file-content/${story?.profile_image_path}`}
                      sx={{
                        position: "absolute",
                        top: "21%",
                        left: "21%",
                        height: 74,
                        width: 74,
                        borderRadius: "50%",
                        overflow: "hidden",
                        objectFit: "contain",
                        border: "2px solid #ffff",
                      }}
                    />
                  </Box>
                  <span style={{ display: "flex", justifyContent: "center" }}>
                    {/* <span
                      style={{
                        backgroundImage: `url(${storyBG1})`,
                        backgroundSize: "cover", // Ensure the background covers the entire box
                        backgroundPosition: "center", // Center the image
                        padding: "3.2px",
                        borderRadius: "50%",
                        overflow: "hidden",
                        height: 34,
                        width: 34,
                        marginTop: "auto",
                      }}
                    >
                      <Avatar
                        src={`https://api.silocloud.io/get-file-content/${story?.profile_image_path}`}
                        alt={story.username}
                        sx={{
                          height: 28,
                          width: 28,
                          borderRadius: "50%",
                          textTransform: "capitalize",
                        }}
                      />
                    </span> */}
                    <Typography
                      sx={{
                        mt: "auto",
                        mb: 0.5,
                        ml: 1,
                        fontWeight: "500 !important",
                        // color: "#000", // White color for better visibility on dark background
                        textTransform: "capitalize",
                      }}
                      fontSize={14}
                    >
                      {story.username.length > 10
                        ? story.username.substring(0, 10) + "..."
                        : story.username}
                    </Typography>
                  </span>
                </Box>
              </Box>
            </div>
          ))}
      </Box>
      {showRightButton && (
        <IconButton
          onClick={scrollRight}
          sx={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            borderRadius: "50%",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
            color: "#fff",
            padding: "3px",
          }}
        >
          <Icon icon="mdi:chevron-right" fontSize={20} />
        </IconButton>
      )}
      {/* </Paper> */}
      {openModal && (
        <StoryModal
          open={openModal}
          handleClose={handleCloseModal}
          storiesData={storyData?.data}
          currentIndex={currentStoryIndex}
        />
      )}

      {addStoryModalOpen && (
        <AddStoryModal
          open={addStoryModalOpen}
          handleClose={handleCloseAddStoryModal}
        />
      )}
    </div>
  );
}

export default Stories;
