import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  Avatar,
  IconButton,
  Divider,
  CircularProgress,
  Tabs,
  Tab,
  Skeleton,
  useMediaQuery,
  useTheme,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@tanstack/react-query";
import {
  handleFollowUser,
  handleGetFollowersFollowing,
  handleRemoveFollower,
} from "../../http/postApiCalls";
import { SearchOutlined } from "@mui/icons-material";
import ConfirmationModal from "./ConfirmationModal ";
import { Link, useNavigate } from "react-router-dom";

const scrollableContainerStyle = {
  overflowY: "auto",
  flexGrow: 1,
  maxHeight: "calc(100% - 150px)", // Adjust based on header and tabs height
};

const LoadingSkeleton = () => (
  <List>
    {[...Array(10)].map((_, index) => (
      <Box
        key={index}
        display="flex"
        alignItems="center"
        textAlign={"start"}
        sx={{ mb: 1, ml: 1.8 }}
      >
        <Skeleton variant="circular" width={40} height={40} sx={{ mr: 2 }} />
        <Box flexGrow={1}>
          <Skeleton variant="text" width={200} height={30} sx={{ mb: 0.5 }} />
        </Box>
      </Box>
    ))}
  </List>
);

const FollowersFollowingModal = ({ open, handleClose, UserType }) => {
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(UserType);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading1, setLoading1] = useState(false);
  const darkMode = theme.palette.mode === "dark";
  // Modal Style
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? 300 : 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    // p: 2,
    borderRadius: "8px",
    maxHeight: 500,
    minHeight: 500,

    display: "flex",
    flexDirection: "column",
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const observer = useRef();

  const {
    mutate: getFollowersFollowing,
    reset: followerFollowingReset,
    isError,
  } = useMutation({
    mutationKey: ["follower-following"],
    mutationFn: handleGetFollowersFollowing,
    onSuccess: (data) => {
      const newData = data?.message?.data?.list || [];
      setData((prev) => (page === 1 ? newData : [...prev, ...newData]));
      setHasMore(newData.length === 10);
      setLoading(false);
      setInitialLoading(false);
      followerFollowingReset();
    },
    onError: (error) => {
      console.error("Error fetching data", error);
      setLoading(false);
      setInitialLoading(false);
    },
  });

  const loadMore = useCallback(() => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1);
      setLoading(true);
    }
  }, [loading, hasMore]);

  const lastUserElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, loadMore]
  );

  useEffect(() => {
    if (open) {
      setInitialLoading(true);
      setPage(1);
      setData([]);
      setHasMore(true);
      const formData = new FormData();
      formData.append("type", activeTab);
      formData.append("page", 1);
      formData.append("limit", 10);
      formData.append("module", "community");

      if (searchQuery) {
        formData.append("search", searchQuery.toString());
      }
      getFollowersFollowing(formData);
    }
  }, [open, activeTab, getFollowersFollowing, searchQuery, loading1]);

  useEffect(() => {
    if (page > 1) {
      const formData = new FormData();
      formData.append("type", activeTab);
      formData.append("page", page);
      formData.append("limit", 10);
      formData.append("module", "community");

      getFollowersFollowing(formData);
    }
  }, [page, activeTab, getFollowersFollowing]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setPage(1);
    setData([]);
    setHasMore(true);
    setInitialLoading(true);
  };

  const [open1, setOpen1] = useState(false);
  const [modalInfo, setModalInfo] = useState({ user: null, action: "" });

  const handleOpenModal = (user, action) => {
    setModalInfo({ user, action });
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleConfirmAction = () => {
    const { user, action } = modalInfo;
    if (action === "remove") {
      onRemove(user.user_id);
    } else if (action === "unfollow") {
      setLoading1(true);
      onUnfollow(user.user_id);
    }
    setOpen1(false);
  };

  // Define the onRemove and onUnfollow functions
  const onRemove = (userId) => {
    console.log(`Removing follower with ID: ${userId}`);
    setLoading1(true);
    const formData = new FormData();
    formData.append("follower_id", userId);
    formData.append("module", "community");

    removeUser(formData);
  };

  const { mutate: removeUser, reset: removeUserReset } = useMutation({
    mutationKey: ["follow-users"],
    mutationFn: (fd) => handleRemoveFollower(fd),
    onSuccess: (data) => {
      // setLoading(false);
      if (data?.status) {
        setLoading1(false);
        getFollowersFollowing();
      }
      removeUserReset();
    },
    onError: (data) => {
      setLoading1(false);
    },
  });

  const onUnfollow = (userId) => {
    console.log(`Unfollowing user with ID: ${userId}`);
    setLoading1(true);
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("type", "single");
    formData.append("status", 2);
    formData.append("module", "community");

    followUser(formData);
    // Logic to unfollow user
  };

  const { mutate: followUser, reset: followUserReset } = useMutation({
    mutationKey: ["follow-users"],
    mutationFn: (fd) => handleFollowUser(fd),
    onSuccess: (data) => {
      // setLoading(false);
      if (data?.status) {
        setLoading1(false);
        getFollowersFollowing();
      }
      followUserReset();
    },
    onError: (data) => {
      setLoading1(false);
    },
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        {/* Modal Header */}
        {/* <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6">Followers and Following</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box> */}

        <Divider />

        {/* Tabs for Followers and Following */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "red", // Set the indicator color to red
            },
            "& .MuiTab-root": {
              textTransform: "capitalize",
              fontSize: 16,
              fontWeight: 500,
              color: "#111", // Set the text color to red for tabs
              "&.Mui-selected": {
                color: "red", // Optional: Set a different color for selected tab
              },
            },
          }}
          centered
        >
          <Tab label="Followers" value="followers" />
          <Tab label="Following" value="following" />
        </Tabs>

        <Divider />
        <span
          style={{
            display: "flex",
            alignItems: "center",
            // margin: "0 16px",
            padding: "8px 16px",
            // backgroundColor: darkMode ? "#2b2b2b" : "#f2f2f2",
            // borderRadius: "50px",
            // boxShadow: darkMode
            //   ? "0 1px 4px rgba(0, 0, 0, 0.4)"
            //   : "0 1px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <TextField
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search users"
            variant="outlined"
            size="small"
            fullWidth
            margin="none"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" sx={{ mr: -2 }}>
                    <SearchOutlined />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchQuery && (
                    <IconButton
                      edge="end"
                      onClick={() => setSearchQuery("")}
                      size="small"
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                padding: "0px 16px",
                borderRadius: "50px",

                backgroundColor: darkMode ? "#3c3c3c" : "#ffffff",
                height: 40,
                "& fieldset": {
                  border: darkMode
                    ? "0.7px solid #2b2b2b"
                    : "0.7px solid #pbdfea", // Remove default border
                },
                "&:hover fieldset": {
                  borderColor: darkMode ? "#De3744 " : "#De3744 ", // Hover effect
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                  boxShadow: "0 0 0 1px #De3744 ", // Instagram-style shadow on focus
                },
              },
              input: {
                padding: "10px 0px", // Ensure input text is vertically aligned
              },
            }}
          />
        </span>
        {/* <Divider /> */}

        {/* Scrollable container for user list */}
        <Box sx={scrollableContainerStyle} className="custom-scrollbar-chat">
          {/* Show Loading Spinner */}
          {initialLoading ? (
            <LoadingSkeleton />
          ) : (
            <List>
              {data.map((user, index) => (
                <ListItem
                  key={user.id}
                  ref={index === data.length - 1 ? lastUserElementRef : null}
                >
                  <Avatar
                    src={user.avatar_url}
                    alt={user.username}
                    component={Link}
                    to={`/other/profile/${user.user_id}`}
                  />
                  <Box ml={1} flexGrow={1}>
                    <Typography
                      variant="body1"
                      component={Link}
                      to={`/other/profile/${user.user_id}`}
                      sx={{ color: "inherit", textDecoration: "none" }}
                    >
                      {user.username.length > 15
                        ? `${user.username.slice(0, 15)}...`
                        : user.username}
                    </Typography>
                    {/* <Typography variant="body2" color="textSecondary">
                      {user.email}
                    </Typography> */}
                  </Box>
                  {activeTab === "following" ? (
                    <Button
                      variant="outlined"
                      style={{
                        color: "#757678", // Reddish color for the cancel button
                        borderColor: "#FCEBEC",
                        borderRadius: "30px",
                        padding: "6px 20px",
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                      onClick={() => handleOpenModal(user, "unfollow")}
                    >
                      Following
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      style={{
                        color: "#757678", // Reddish color for the cancel button
                        borderColor: "#FCEBEC",
                        borderRadius: "30px",
                        padding: "6px 20px",
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                      onClick={() => handleOpenModal(user, "remove")}
                    >
                      Remove
                    </Button>
                  )}
                </ListItem>
              ))}
              {!data.length && !loading && (
                <Box textAlign="center" mt={4}>
                  <Typography>No users found.</Typography>
                </Box>
              )}
            </List>
          )}

          {/* Load more skeletons */}
          {loading && page > 1 && <LoadingSkeleton />}
          {/* Handle errors */}
          {/* {isError && !searchQuery && (
            <Box display="flex" justifyContent="center" my={2}>
              <Typography color="error">Failed to load users.</Typography>
            </Box>
          )} */}
        </Box>

        <ConfirmationModal
          open={open1}
          handleClose={handleClose1}
          handleConfirm={handleConfirmAction}
          modalInfo={modalInfo}
          loading={loading1}
        />
      </Box>
    </Modal>
  );
};

export default FollowersFollowingModal;
