import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";
import { keyframes } from "@emotion/react";
import ReportModal from "./ReportModal";
import UnfollowModal from "./UnfollowModal";
import ShareToModal from "./ShareToModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import CSS for Toastify
import EmbedModal from "./EmbedModal";
import { useLocation, useNavigate } from "react-router-dom";
import ShareModal from "../share/ShareModal ";
import {
  handleArchivePost,
  handleDeletePost,
  handleFollowUser,
} from "../http/postApiCalls";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../http";
import { useSnackbarUtils } from "../utils/snackbar/snackbarUtils";
import LoadingBackdrop from "../utils/progress/LoadingBackdrop";
import EditPost from "../components/post/EditPost";

const slideUp = keyframes`
  from {
    transform: translate(-50%, 100%);
  }
  to {
    transform: translate(-50%, -50%);
  }
`;

const PostMoreMenuModal = ({
  open,
  onClose,
  post,
  userId,
  closeCommentModal,
  following_status,
}) => {
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarUtils();
  const [openModal, setOpenModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const handleOpenModal = (modalType) => {
    if (modalType === "copyLink") {
      handleCopyLink(); // Invoke the function to copy the link
    } else if (modalType === "goToPost") {
      handleGoToPost(); // Invoke the function to copy the link
    } else if (modalType === "share") {
      handleModalSend(); // Invoke the function to copy the link
    } else if (modalType === "unfollow") {
      handleDoFollowUser(userId ? userId : post.user_id);
    }
    {
      setOpenModal(modalType);
    }
    onClose();
  };

  const handleDoFollowUser = (id) => {
    const formData = new FormData();
    formData.append("user_id", id);
    formData.append("type", "single");
    formData.append("module", "community");
    formData.append("status", post.following_status ? 2 : 1);
    followUser(formData);
  };

  const { mutate: followUser, reset: followUserReset } = useMutation({
    mutationKey: ["follow-users"],
    mutationFn: (fd) => handleFollowUser(fd),
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries(["getAllPostData"]);
      }
      followUserReset();
    },
    onError: (data) => {},
  });

  const handleCloseModal = () => {
    setOpenModal(null);
  };
  const handleGoToPost = () => {
    const postUrl = post.shareable_link;

    navigate(postUrl, { replace: true });
  };

  const handleCopyLink = () => {
    const postUrl = `https://community.silocloud.io${post.shareable_link}`; // Replace with the actual post URL
    navigator.clipboard.writeText(postUrl);
    // navigate(post.shareable_link);
    // toast.success("Link copied to your clipboard!", {
    //   position: "bottom-right",
    //   autoClose: 2000,
    //   closeOnClick: true,
    //   pauseOnHover: false,
    //   draggable: true,
    //   progress: undefined,
    // });
  };

  const [sendModalOpen, setSendModalOpen] = useState(false);

  const handleModalSend = (post) => {
    // setPostForShareModal(post);
    setSendModalOpen(true);
  };
  const handleCloseModalSend = () => {
    setSendModalOpen(false);
  };

  const userCheck = localStorage.getItem("loggedIn_user_id");

  const handleGoToOtherUserProfile = (id) => {
    console.log("id to go", id);
    navigate(userCheck === id.toString() ? "/profile" : `/other/profile/${id}`);
  };

  const [open1, setOpen1] = useState(false);
  const [postIdToDelete, setPostIdToDelete] = useState(null);

  const handleOpenConfirmModal = (id) => {
    setPostIdToDelete(id); // Store the post ID to delete
    setOpen1(true); // Open the modal
  };

  const handleEditModal = (id) => {
    setIsModalOpen(true);
  };

  const handleAddToArchive = (id) => {
    setLoading(true); // Open the modal

    const formData = new FormData();
    formData.append("post_id", id);
    addArchive(formData); // Trigger mutation    setOpen1(true); // Open the modal
  };

  // Handle closing the confirmation dialog
  const handleClose = () => {
    setOpen1(false);
    setPostIdToDelete(null); // Clear the selected post ID when the modal closes
  };

  // Mutation for deleting the post
  const { mutate: deletePost, reset: deletePostreset } = useMutation({
    mutationKey: ["delete-post"],
    mutationFn: (fd) => handleDeletePost(fd),
    onSuccess: (data) => {
      // setLoading(false);
      if (data?.status) {
        queryClient.invalidateQueries(["getAllPostData"]);
        onClose();
        closeCommentModal();
        setOpen1(false);
        setLoading(false);

        showSuccessSnackbar("Post deleted successfully!");
      }
      deletePostreset();
    },
    onError: (data) => {
      onClose();
      closeCommentModal();
      setLoading(false);
    },
  });

  // Handle delete after confirmation
  const handleDeleteConfirmed = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("post_id", postIdToDelete);
    deletePost(formData); // Trigger mutation
  };

  // Mutation for deleting the post
  const { mutate: addArchive, reset: addArchivereset } = useMutation({
    mutationKey: ["add-archive-post"],
    mutationFn: (fd) => handleArchivePost(fd),
    onSuccess: (data) => {
      // setLoading(false);
      if (data?.status) {
        queryClient.invalidateQueries(["getAllPostData"]);
        onClose();
        closeCommentModal();
        setLoading(false);

        showSuccessSnackbar("Post archived successfully!");
      }
      addArchivereset();
    },
    onError: (data) => {
      onClose();
      closeCommentModal();
      setLoading(false);
    },
  });

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 350,
            bgcolor: "background.paper",
            borderRadius: 5,
            boxShadow: 24,
            p: 0,
            animation: `${slideUp} 0.3s ease-out`,
          }}
        >
          <List sx={{ alignItems: "center" }}>
            <ListItem
              button
              onClick={() => handleOpenConfirmModal(post.id)}
              sx={{
                display:
                  userCheck === post.user_id?.toString() ||
                  userCheck === userId?.toString()
                    ? "flex"
                    : "none",
              }}
            >
              <ListItemText
                primary={
                  <Typography variant="h6" fontSize={16} color="#f03737">
                    Delete
                  </Typography>
                }
                sx={{ textAlign: "center" }}
              />
            </ListItem>
            <Divider
              sx={{
                display:
                  userCheck === post.user_id?.toString() ||
                  userCheck === userId?.toString()
                    ? "flex"
                    : "none",
              }}
            />

            <ListItem
              button
              onClick={() => handleEditModal(post.id)}
              sx={{
                display:
                  userCheck === post.user_id?.toString() ||
                  userCheck === userId?.toString()
                    ? "flex"
                    : "none",
              }}
            >
              <ListItemText
                primary={
                  <Typography variant="h6" fontSize={16}>
                    Edit
                  </Typography>
                }
                sx={{ textAlign: "center" }}
              />
            </ListItem>
            <Divider
              sx={{
                display:
                  userCheck === post.user_id?.toString() ||
                  userCheck === userId?.toString()
                    ? "flex"
                    : "none",
              }}
            />

            <ListItem
              button
              onClick={() => handleAddToArchive(post.id)}
              sx={{
                display:
                  userCheck === post.user_id?.toString() ||
                  userCheck === userId?.toString()
                    ? "flex"
                    : "none",
              }}
            >
              <ListItemText
                primary={
                  <Typography variant="h6" fontSize={16} color="#f03737">
                    Add to archive
                  </Typography>
                }
                sx={{ textAlign: "center" }}
              />
            </ListItem>
            <Divider
              sx={{
                display:
                  userCheck === post.user_id?.toString() ||
                  userCheck === userId?.toString()
                    ? "flex"
                    : "none",
              }}
            />
            <ListItem
              button
              onClick={() => handleOpenModal("report")}
              // disabled={userCheck === post.user_id?.toString()}
              sx={{
                display:
                  userCheck === post.user_id?.toString() ||
                  userCheck === userId?.toString()
                    ? "none"
                    : "flex",
              }}
            >
              <ListItemText
                primary={
                  <Typography variant="h6" fontSize={16} color="#f03737">
                    Report
                  </Typography>
                }
                sx={{ textAlign: "center" }}
              />
            </ListItem>
            <Divider
              sx={{
                display:
                  userCheck === post.user_id?.toString() ||
                  userCheck === userId?.toString()
                    ? "none"
                    : "flex",
              }}
            />
            <ListItem
              button
              onClick={() => handleOpenModal("unfollow")}
              disabled={userCheck === post.user_id?.toString()}
              sx={{
                display:
                  userCheck === post.user_id?.toString() ||
                  userCheck === userId?.toString()
                    ? // || location.pathname.startsWith("/other/profile")
                      "none"
                    : "flex",
              }}
            >
              <ListItemText
                primary={
                  <Typography variant="h6" fontSize={16} color="#f03737">
                    {post.following_status || following_status
                      ? "Unfollow"
                      : "Follow"}
                  </Typography>
                }
                sx={{ textAlign: "center" }}
              />
            </ListItem>
            <Divider
              sx={{
                display:
                  userCheck === post.user_id?.toString() ||
                  userCheck === userId?.toString()
                    ? "none"
                    : "flex",
              }}
            />
            <ListItem button onClick={() => handleOpenModal("favourites")}>
              <ListItemText
                primary="Add to favourites"
                sx={{ textAlign: "center" }}
              />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => handleOpenModal("goToPost")}>
              <ListItemText primary="Go to post" sx={{ textAlign: "center" }} />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => handleOpenModal("share")}>
              <ListItemText
                primary="Share to..."
                sx={{ textAlign: "center" }}
              />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => handleOpenModal("copyLink")}>
              <ListItemText primary="Copy Link" sx={{ textAlign: "center" }} />
            </ListItem>
            {/* <Divider />
            <ListItem button onClick={() => handleOpenModal("embed")}>
              <ListItemText primary="Embed" sx={{ textAlign: "center" }} />
            </ListItem> */}
            <Divider />
            <ListItem
              button
              onClick={() => handleGoToOtherUserProfile(post.user_id)}
            >
              <ListItemText
                primary="About this account"
                sx={{ textAlign: "center" }}
              />
            </ListItem>
            <Divider />
            <ListItem button onClick={onClose}>
              <ListItemText primary="Cancel" sx={{ textAlign: "center" }} />
            </ListItem>
          </List>

          <LoadingBackdrop loading={loading} />
        </Box>
      </Modal>
      {/* Conditionally render each modal */}
      {openModal === "report" && (
        <ReportModal open={true} onClose={handleCloseModal} post={post} />
      )}
      {/* {openModal === "unfollow" && (
        <UnfollowModal open={true} onClose={handleCloseModal} user={post} />
      )} */}

      {openModal === "share" && (
        // <ShareToModal
        //   open={true}
        //   onClose={handleCloseModal}
        //   postUrl={`https://community.silocloud.io${post.shareable_link}`}
        // />
        <ShareModal
          open={sendModalOpen}
          handleClose={handleCloseModalSend}
          post={post}
        />
      )}

      {openModal === "embed" && (
        <EmbedModal open={true} onClose={handleCloseModal} post={post} />
      )}
      <ToastContainer />

      {isModalOpen && (
        <EditPost
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          postData={post}
          imageUrl={post?.media_details[0]?.media_urls}
          caption={post.caption}
          closeCommentModal={closeCommentModal}
        />
      )}

      {/* Confirmation Dialog */}
      <Dialog open={open1} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this post?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmed} color="secondary">
            Confirm
          </Button>
        </DialogActions>
        <LoadingBackdrop loading={loading} />
      </Dialog>
    </>
  );
};

export default PostMoreMenuModal;
