import React, { useState } from "react";
import {
  Avatar,
  Typography,
  Button,
  useMediaQuery,
  Box,
  Container,
  Tooltip,
  Skeleton,
} from "@mui/material";
import ProfileSectionPosts from "./profile/ProfileSectionPosts";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useTheme } from "@emotion/react";
import { handleGetUsersPosts } from "../http/getApiCalls";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import FollowersFollowingModal from "./profile/FollowersFollowingModal";
import AddHighlight from "./profile/AddHighlight";
import ArchiveStories from "./profile/ArchiveStories";
import ChooseCoverImage from "./profile/ChooseCoverImage";
import FinalConfirm from "./profile/FinalConfirm";
import { useDispatch, useSelector } from "react-redux";
import {
  resetHighlight,
  selectHighlights,
  setCoverImage,
  setCurrentStep,
} from "../store/slices/highlightSlice";
import highlightLogo from "../assets/images/addHighLight.png";
import HighlightStory from "./profile/highlight/HighlightStory";
function Profile({ username }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isSmallScreen1 = useMediaQuery("(max-width: 426px)");

  const fallbackUser = {
    username: <Skeleton component={Skeleton.Text} width={100} height={40} />,
    avatarUrl: "https://via.placeholder.com/150",
    bio: "No bio available.",
    followers: 0,
    following: 0,
    posts: [],
  };

  const {
    data: userPostsData,
    isLoading: userPostFinalIsLoading,
    isRefetching: userPostFinalIsRefetch,
    refetch: userPostFinalRefetch,
  } = useQuery({
    queryKey: ["getUserPosts"],
    queryFn: async () => handleGetUsersPosts(),
    cacheTime: 0,
    staleTime: Infinity,
  });
  const userPosts = userPostsData || fallbackUser; // Fallback to avoid undefined errors

  const handleEditProfile = () => {
    navigate("/edit/profile");
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState("");

  // Function to toggle modal open/close for Followers/Following
  const handleModalOpen = (type) => {
    setType(type);
    setIsModalOpen(true);
  };

  const gotoarchive = () => {
    navigate("/profile/archive");
  };
  const handleModalClose = () => {
    setType("");
    setIsModalOpen(false);
  };

  const [isHighlightModalOpen, setHighlightModalOpen] = useState(false); // Manage highlight modal state

  const dispatch = useDispatch();
  const { highlightName, selectedStories, currentStep, coverImage } =
    useSelector(selectHighlights);

  const handleNext = () => {
    dispatch(setCurrentStep(currentStep + 1));
  };

  const handleBack = () => {
    dispatch(setCurrentStep(currentStep - 1));
  };

  const handleClose = () => {
    dispatch(resetHighlight());
    setHighlightModalOpen(false);
  };

  const handleOpenHighlightModal = () => {
    setHighlightModalOpen(true);
    dispatch(resetHighlight());
    dispatch(setCurrentStep(1));
  };

  const handleCoverSelect = (selectedCover) => {
    dispatch(setCoverImage(selectedCover));
    dispatch(setCurrentStep(4)); // Move to FinalConfirm step
  };

  const handleFinalSubmit = async () => {
    const highlightData = {
      name: highlightName,
      stories: selectedStories,
      coverImage: coverImage,
    };

    try {
      // Implement your API call here
      // Example:
      // const response = await api.saveHighlight(highlightData);
      // For demonstration, we'll mock the API call with a timeout
      await new Promise((resolve) => setTimeout(resolve, 1000));
      console.log("Highlight saved successfully:", highlightData);
      // Provide user feedback, e.g., success message

      // Close the modal and reset the state
      handleClose();
    } catch (error) {
      console.error("Error saving highlight:", error);
      // Handle error, e.g., show error message
    }
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "0 !important",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        borderBottom="0.5px solid #ddd"
        mb={2}
        mt={3}
        pb={2}
        width="100%"
        px={2}
        position="relative"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={isSmallScreen ? "column" : "row"}
          width="100%"
        >
          <Avatar
            // src={user.avatarUrl}
            src={`https://api.silocloud.io/get-file-content/${userPosts?.profile_image_path}`}
            alt={userPosts?.username}
            style={{
              width: 150,
              height: 150,
              marginRight: isSmallScreen ? "0px" : "50px",
              marginBottom: isSmallScreen ? "20px" : "0px",
            }}
          />
          <Box display={isSmallScreen1 ? "flex" : "none"}>
            <Typography
              variant="h5"
              className="fw-600"
              sx={{
                fontWeight: 900,
                textTransform: "capitalize",
                mb: 1,
              }}
            >
              {userPosts?.username}
            </Typography>
          </Box>
          <Box textAlign={isSmallScreen ? "center" : "left"}>
            <Box
              display={"flex"}
              justifyContent={isSmallScreen ? "center" : "flex-start"}
            >
              <Box display={isSmallScreen1 ? "none" : "flex"}>
                <Typography
                  variant="h5"
                  className="fw-600"
                  sx={{
                    fontWeight: 900,
                    textTransform: "capitalize",
                  }}
                >
                  {userPosts?.username}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent={isSmallScreen ? "center" : "flex-start"}
                ml={2}
              >
                <Button
                  className={
                    theme.palette.mode === "dark"
                      ? "profile-section-btn-dark"
                      : "profile-section-btn"
                  }
                  variant="outlined"
                  sx={{ mr: 1, color: "inherit" }}
                  onClick={handleEditProfile}
                >
                  Edit Profile
                </Button>
                <Button
                  className={
                    theme.palette.mode === "dark"
                      ? "profile-section-btn-dark"
                      : "profile-section-btn"
                  }
                  onClick={gotoarchive}
                  variant="outlined"
                  sx={{ mr: 1, color: "inherit" }}
                  // onClick={() => handleModalOpen('viewArchive')}
                >
                  View Archive
                </Button>
                <Button
                  className={
                    theme.palette.mode === "dark"
                      ? "profile-section-btn-dark"
                      : "profile-section-btn"
                  }
                  sx={{ mr: 1, color: "inherit", border: "transparent" }}
                  // onClick={() => handleModalOpen('settings')}
                >
                  <Icon icon="lets-icons:setting-alt-line" fontSize={30} />
                </Button>
              </Box>
            </Box>

            <Box
              display="flex"
              justifyContent={isSmallScreen ? "center" : "flex-start"}
              mt={2}
              mb={1}
            >
              <Box mx={0} textAlign="center" className="user-info">
                <Typography variant="subtitle1" className="fw-700">
                  {userPosts?.posts?.length}
                </Typography>
                <Typography variant="subtitle1" className="fw-500">
                  Posts
                </Typography>
              </Box>
              <Box
                ml={3}
                textAlign="center"
                className="user-info"
                sx={{ cursor: "pointer" }}
                onClick={() => handleModalOpen("followers")}
              >
                <Typography variant="subtitle1" className="fw-700">
                  {userPosts.followers_count}
                </Typography>
                <Typography variant="subtitle1" className="fw-500">
                  Followers
                </Typography>
              </Box>
              <Box
                ml={3}
                textAlign="center"
                className="user-info"
                sx={{ cursor: "pointer" }}
                onClick={() => handleModalOpen("following")}
              >
                <Typography variant="subtitle1" className="fw-700">
                  {userPosts.following_count}
                </Typography>
                <Typography variant="subtitle1" className="fw-500">
                  Following
                </Typography>
              </Box>
            </Box>

            <Typography
              variant="body1"
              color="textSecondary"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "wrap",
                maxWidth: "500px",
              }}
            >
              {userPosts.about_me}
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent={isSmallScreen ? "center" : "flex-start"}
          width="100%"
          mt={6}
          mb={1}
          padding={isSmallScreen ? "0 10px" : 0}
        >
          <div>
            <Tooltip title="Add Highlight" placement="top">
              <Avatar
                src={highlightLogo}
                onClick={handleOpenHighlightModal}
                sx={{ cursor: "pointer", height: 56, width: 56 }}
              />
            </Tooltip>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "wrap",
                maxWidth: "500px",
                textAlign: "center",
                // fontWeight: "bold !important",
                fontSize: "12px",
                lineHeight: "16px",
                mt: 1.2,
                color: theme.palette.mode === "dark" ? "#696666" : "#535455",
              }}
            >
              New
            </Typography>
          </div>
          <HighlightStory />
        </Box>
      </Box>

      <ProfileSectionPosts posts={userPosts?.posts} postData={userPosts} />

      {/* Highlight Flow Modal */}
      {isHighlightModalOpen && (
        <>
          {currentStep === 1 && (
            <AddHighlight
              open={currentStep === 1}
              handleClose={handleClose}
              handleNext={handleNext}
            />
          )}
          {currentStep === 2 && (
            <ArchiveStories
              open={currentStep === 2}
              handleClose={handleClose}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          )}
          {currentStep === 3 && (
            <ChooseCoverImage
              open={currentStep === 3}
              handleClose={handleClose}
              handleBack={handleBack}
              handleNext={handleNext}
              handleCoverSelect={handleCoverSelect}
            />
          )}
          {currentStep === 4 && (
            <FinalConfirm
              open={currentStep === 4}
              handleClose={handleClose}
              handleBack={handleBack}
              handleSubmit={handleFinalSubmit}
              highlightName={highlightName}
              selectedStories={selectedStories}
              coverImage={coverImage}
            />
          )}
        </>
      )}

      {/* Modal for Followers/Following */}
      {isModalOpen && (
        <FollowersFollowingModal
          open={isModalOpen}
          handleClose={handleModalClose}
          UserType={type}
        />
      )}
    </Container>
  );
}

export default Profile;
