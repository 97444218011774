import React, { useState, useEffect } from "react";
import {
  useMediaQuery,
  Box,
  ImageList,
  ImageListItem,
  CircularProgress,
  IconButton,
  Typography,
  Skeleton,
  Grid,
} from "@mui/material";
import { Icon } from "@iconify/react";
import InfiniteScroll from "react-infinite-scroll-component";
import CommentsModal from "../comments/CommentsModal";
import ShareModal from "../../share/ShareModal ";

export default function MasonryImageList({ postsData, postFinalIsLoading }) {
  const isMobile = useMediaQuery("(max-width:767px)"); // For mobile screens
  const isLaptop = useMediaQuery("(min-width:1440px)"); // For laptop screens

  const [displayedPosts, setDisplayedPosts] = useState([]); // Holds the posts to display
  const [postIndex, setPostIndex] = useState(10); // Start with 10 posts displayed
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [post, setPost] = useState(null); // Holds the post ID for the selected image
  const [focusedPostId, setFocusedPostId] = useState(null); // Track the focused post ID

  const [sendModalOpen, setSendModalOpen] = useState(false);
  // const [post, setPost] = useState("");
  const handleOpenModalSend = (post) => {
    console.log("post", post);
    setSendModalOpen(true);
    setPost(post);
  };

  const handleCloseModalSend = () => {
    setSendModalOpen(false);
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Load the first 10 posts when the component mounts
    setDisplayedPosts(postsData?.slice(0, 12));
  }, [postsData]);

  const fetchMoreData = () => {
    // Load the next 10 posts when the user scrolls
    const newIndex = postIndex + 10;
    setDisplayedPosts((prevPosts) => [
      ...prevPosts,
      ...postsData?.slice(postIndex, newIndex),
    ]);
    setPostIndex(newIndex);
  };

  const handlePostModal = (item) => {
    setIsModalOpen(true);
    setPost(item);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleFocusPost = (itemId) => {
    setFocusedPostId(itemId); // Set focused post ID
  };

  const handleBlurPost = () => {
    setFocusedPostId(null); // Clear focused post ID when leaving the post
  };

  if (postFinalIsLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
          width: "100%",
          paddingTop: "10px",
          ml: 2,
          overflow: "hidden",
        }}
      >
        <Grid container spacing={2}>
          {/* Display skeletons in place of images */}
          {Array.from(new Array(12)).map((_, index) => (
            <Grid item xs={12} sm={3} key={index}>
              <Skeleton
                variant="rectangular"
                width={250}
                height={250} // adjust height as needed
                animation="wave" // You can use 'pulse' or 'wave'
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          paddingTop: "10px",
          ml: 2,
        }}
      >
        <InfiniteScroll
          dataLength={displayedPosts?.length || 0} // Length of currently displayed data
          next={fetchMoreData} // Function to call when more data is needed
          hasMore={displayedPosts?.length < postsData?.length}
          loader={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          } // Loading indicator
          style={{ overflow: "visible" }} // Prevent scroll component from breaking the layout
        >
          <ImageList
            variant="masonry"
            cols={isMobile ? 3 : 4}
            gap={3}
            sx={{ cursor: "pointer" }}
          >
            {displayedPosts?.map((item) =>
              item?.media_details?.map((image) => (
                <ImageListItem key={image.id}>
                  <div
                    className="explore-image"
                    onMouseEnter={() => handleFocusPost(item.id)} // Show icons when focused
                    onMouseLeave={handleBlurPost} // Hide icons when not focused
                    onClick={() => handlePostModal(item)}
                    style={{ position: "relative" }} // Ensure the controls are positioned absolutely
                  >
                    {item?.media_details?.map((media) => (
                      <div key={media.id}>
                        {media.media_type === "image" && (
                          <img
                            srcSet={`${media.media_urls}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            src={`${media.media_urls}?w=248&fit=crop&auto=format`}
                            loading="lazy"
                            alt={item.title}
                            className="hover-on-explore"
                            style={{ cursor: "pointer", width: "100%" }} // Ensure it scales well
                          />
                        )}

                        {media.media_type === "video" && (
                          <video
                            src={`${media.media_urls}`}
                            alt={item.title}
                            className="hover-on-explore"
                            style={{ cursor: "pointer", width: "100%" }}
                          />
                        )}
                      </div>
                    ))}

                    <Box
                      className="controls"
                      sx={{
                        position: "absolute",
                        bottom: "43%",
                        left: 0,
                        right: 0,
                        padding: "8px 50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        opacity: focusedPostId === item.id ? 1 : 0, // Show controls if this post is focused
                        transition: "opacity 0.3s ease-in-out",
                        color: "#fff",
                        fontWeight: 700,
                        "& .MuiIconButton-root": {
                          color: "#fff",
                        },
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton>
                          <Icon icon={"solar:heart-linear"} fontSize={30} />
                        </IconButton>
                        <Typography variant="h6" ml={-0.5} mr={1}>
                          {item.likes_count || 0}
                        </Typography>

                        <IconButton>
                          <Icon icon="iconamoon:comment-light" fontSize={30} />
                        </IconButton>
                        <Typography variant="h6" ml={-0.5} mr={1}>
                          {item.comment_count || 0}
                        </Typography>
                      </Box>

                      <IconButton
                        onClick={() => handleOpenModalSend(item)} // Open modal on click
                      >
                        <Icon icon="tabler:send" fontSize={30} />
                      </IconButton>
                    </Box>
                  </div>
                </ImageListItem>
              ))
            )}
          </ImageList>
        </InfiniteScroll>
      </Box>

      {isModalOpen && !sendModalOpen && (
        <CommentsModal
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          postData={post}
          imageUrl={post?.media_details[0]?.media_urls}
          caption={post.caption}
        />
      )}
      {/* Share Modal */}
      {sendModalOpen && (
        <ShareModal
          open={sendModalOpen}
          handleClose={handleCloseModalSend} // Pass close handler
          post={post} // Pass post data
        />
      )}
    </>
  );
}
