import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
  Switch,
} from "@mui/material";
import {
  AccountCircle,
  PieChart,
  ExitToApp,
  AccountCircleOutlined,
  DarkMode,
} from "@mui/icons-material";
import LightModeTwoToneIcon from "@mui/icons-material/LightModeTwoTone";
import NightsStayTwoToneIcon from "@mui/icons-material/NightsStayTwoTone";
import { useCookies } from "react-cookie";
import Cookies from "js-cookie";
import { Icon } from "@iconify/react";
import blockChain from "../../assets/images/blockchain.png";
import blockChainW from "../../assets/images/blockChainW.png";
import walletL from "../../assets/images/WalletL.png";
import walletW from "../../assets/images/WalletW.png";

import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 39,
  height: 20,
  padding: 0,
  marginLeft: "50%",
  marginTop: "2%",
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 17,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(19px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2.3,
    "&.Mui-checked": {
      transform: "translateX(19px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "red" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 14,
    marginTop: 1,
    height: 14,
    borderRadius: "50%",
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const HoverMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: "14px !important",
  fontWeight: "500 !important",
  padding: "8px 14px",
  "&:hover": {
    color: "#e85347",
    backgroundColor: theme.palette.mode === "dark" ? "#666666" : "#f2f0f0",
    "& .MuiListItemIcon-root, & .MuiListItemText-root": {
      color: "#e85347",
    },
    "& img": {
      filter:
        "invert(55%) sepia(99%) saturate(2128%) hue-rotate(330deg) brightness(101%) contrast(97%)", // example filter to change color
    },
    "& .iconify": {
      color: "#e85347", // change color of iconify icons
    },
  },
}));

function UserAvatarDropdown({ onToggleDarkMode, darkMode }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [cookies, setCookie] = useCookies(["username", "email"]);
  const profileDefault = Cookies.get("uesrpro");

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

    Cookies.set("currentURL", window.location.href, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });

    const logoutUrl = `${process.env.REACT_APP_ACCOUNT_URL}logout`;
    window.location.href = logoutUrl;
    handleMenuClose();
  };

  return (
    <>
      <IconButton edge="end" color="inherit" onClick={handleMenuOpen}>
        <Avatar
          alt={cookies.username || "User"}
          src={cookies.profile_pic ? cookies.profile_pic : profileDefault}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            boxShadow: "0 3px 12px 1px #82252626",

            width: "280px",
            // height: "335.5px",
            // boxShadow: "none",
            borderTop: "3px solid #e14954",
          },
        }}
      >
        <div
          style={{
            padding: "10px 16px",
            display: "flex",
            alignItems: "center",
            backgroundColor:
              theme.palette.mode === "dark" ? "#545453" : "#f5f6fa ",
          }}
        >
          <Avatar
            alt={cookies.username || "User"}
            src={cookies.profile_pic ? cookies.profile_pic : profileDefault}
            sx={{ width: 40, height: 40, mr: 2 }}
          />
          <div>
            <Typography variant="body1">
              {cookies.username || "John Doe"}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {cookies.email || "john@example.com"}
            </Typography>
          </div>
        </div>
        <Divider />
        <HoverMenuItem
          component={Link}
          to="https://silocloud.com/enumblockchain-explorer"
          sx={{ mt: 1 }}
        >
          <ListItemIcon>
            {darkMode ? (
              <img src={blockChainW} height={18} width={18} alt="BlockChain" />
            ) : (
              <img src={blockChain} height={18} width={18} alt="BlockChain" />
            )}
          </ListItemIcon>
          <ListItemText primary="BlockChain" />
        </HoverMenuItem>

        <HoverMenuItem
          component={Link}
          to="https://silocloud.com/wallet-dashboard"
        >
          <ListItemIcon>
            {darkMode ? (
              <img src={walletW} height={18} width={18} alt="BlockChain" />
            ) : (
              <img src={walletL} height={18} width={18} alt="BlockChain" />
            )}
          </ListItemIcon>
          <ListItemText primary="Silo Wallet" />
        </HoverMenuItem>
        <HoverMenuItem component={Link} to="https://accounts.silocloud.io/">
          <ListItemIcon>
            <Icon icon="lucide:settings" fontSize={19} />{" "}
          </ListItemIcon>
          <ListItemText primary="Account Settings" />
        </HoverMenuItem>
        <HoverMenuItem component={Link} to="#" onClick={onToggleDarkMode}>
          <span style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ display: "flex" }}>
              <ListItemIcon>
                {darkMode ? (
                  <LightModeTwoToneIcon />
                ) : (
                  <Icon icon="mingcute:moon-line" fontSize={20} />
                )}
              </ListItemIcon>
              <ListItemText primary={darkMode ? "Light Mode" : "Dark Mode"} />
            </span>

            <AntSwitch
              checked={darkMode ? true : false}
              // inputProps={{ "aria-label": "ant design" }}
            />
          </span>
        </HoverMenuItem>
        <Divider />

        <HoverMenuItem
          onClick={handleLogout}
          style={{
            backgroundColor:
              theme.palette.mode === "dark" ? "#545453" : "#f5f6fa ",
          }}
        >
          <ListItemIcon sx={{ fontWeight: "700" }}>
            <Icon icon="ph:sign-out" fontSize={18} fontWeight={700} />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </HoverMenuItem>
      </Menu>
    </>
  );
}

export default UserAvatarDropdown;
