import React, { useState } from "react";
import {
  Modal,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CustomCropper from "./CustomCropper ";

function AddStoryModal({ open, handleClose }) {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);

  const handleCloseModal = () => {
    if (fileSelected) {
      setShowConfirmation(true);
    } else {
      handleClose();
    }
  };

  const handleCloseConfirmation = (action) => {
    setShowConfirmation(false);
    if (action === "discard") {
      handleClose();
    } else if (action === "saveAsDraft") {
      // Implement save as draft functionality
      handleClose();
    } else if (action === "keepEditing") {
      // Simply close the confirmation dialog and keep editing
    }
  };

  const handleFileSelect = () => {
    setFileSelected(true);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
        }}
      >
        <Box
          sx={{
            width: isSmallScreen ? "100%" : fileSelected ? 800 : 350,
            bgcolor: "background.paper",
            borderRadius: 3,
            boxShadow: 24,
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            overflow: "hidden",
            maxHeight: "90vh",
            // minHeight: "530px",
            height: fileSelected ? "fit-content" : 400,
            outline: "none",
          }}
        >
          <Box
            sx={{
              flex: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
              position: "relative",
              // backgroundColor: "#fff",
            }}
          >
            <CustomCropper
              handleFileSelect={handleFileSelect}
              handleCloseModal={handleCloseModal}
              handleClose={handleClose}
              fileSelected={fileSelected}
            />
          </Box>
        </Box>
      </Modal>

      {/* Confirmation Dialog */}
      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 5,
            width: "90vw",
            maxWidth: "350px",
            padding: "0px 20px",
          },
          "& .MuiDialogTitle-root": {
            fontSize: "18px",
            fontWeight: 600,
            // color: "#262626",
          },
          "& .MuiDialogContent-root": {
            fontSize: "13px",
            color: "#8e8e8e",
          },
          "& .MuiDialogActions-root": {
            padding: "10px 0",
            display: "flex",
            flexDirection: "column",
          },
          "& .MuiButton-root": {
            fontSize: "13px",
            fontWeight: 500,
            padding: "10px",
            textTransform: "none",
            borderRadius: "8px",
            boxShadow: "none", // Remove box shadow
            "&:hover": {
              boxShadow: "none", // Prevent box shadow on hover
            },
          },
          "& .MuiButton-containedPrimary": {
            backgroundColor: "#f0f0f0",
          },
          "& .MuiButton-containedRed": {
            color: "#f44336",
            border: "1px solid #fff",
            "&:hover": {
              color: "#f44336", // Change text color to #f44336 on hover
              border: "1px solid #f44336",
            },
          },
          "& .MuiButton-outlinedRed": {
            border: "1px solid #f44336",
            "&:hover": {
              border: "1px solid #f44336",
              backgroundColor: "#f0f0f0 !important",
            },
          },
        }}
      >
        <DialogTitle>Discard Story?</DialogTitle>
        <DialogContent>
          <Typography>If you leave, your edits won't be saved.</Typography>
        </DialogContent>
        <DialogActions>
          <Stack spacing={2} width="100%">
            <Button
              onClick={() => handleCloseConfirmation("keepEditing")}
              variant="contained"
              // className="MuiButton-containedRed"
              sx={{
                color: "#f44336",
                border: "1px solid transparent",

                "&:hover": {
                  border: "1px solid #f44336",
                  backgroundColor: "#f0f0f0 !important",
                },
              }}
            >
              Keep editing
            </Button>
            {/* <Button
              onClick={() => handleCloseConfirmation("saveAsDraft")}
              variant="contained"
              className="MuiButton-containedBlue"
              color="primary"
            >
              Save as draft
            </Button> */}
            <Button
              onClick={() => handleCloseConfirmation("discard")}
              variant="contained"
              color="error"
            >
              Discard
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddStoryModal;
