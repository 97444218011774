import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  useTheme,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import { handleReportPost, handleReportStory } from "../http/postApiCalls";
import { queryClient } from "../http";
import { useSnackbarUtils } from "../utils/snackbar/snackbarUtils";

const reasons = [
  "It's spam",
  "Nudity or sexual activity",
  "Hate speech or symbols",
  "Violence or dangerous organizations",
  "Sale of illegal or regulated goods",
  "Bullying or harassment",
  "Intellectual property violation",
  "Suicide or self-injury",
  "Eating disorders",
  "Scam or fraud",
  "Drugs",
  "False information",
  "I just don't like it",
  "Other",
];

const ReportModal = ({ open, onClose, post, storyId }) => {
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarUtils();

  const [selectedReason, setSelectedReason] = useState("");
  const [customReason, setCustomReason] = useState("");
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  console.log("post report", post);

  const handleReasonSelect = (reason) => {
    setSelectedReason(reason);
    setCustomReason(""); // Reset custom reason when a new reason is selected
  };

  const handleCustomReasonChange = (event) => {
    setCustomReason(event.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    if (storyId) {
      formData.append("story_id", storyId);
    } else {
      formData.append("post_id", post.id);
    }
    formData.append(
      "report_reason",
      selectedReason === "Other" ? customReason : selectedReason
    );

    if (storyId) {
      reportStory(formData);
    } else {
      reportPost(formData);
    }
  };

  const { mutate: reportPost, reset: reportPostReset } = useMutation({
    mutationKey: ["report-post"],
    mutationFn: handleReportPost,
    onSuccess: (data) => {
      console.log("Post Reported", data);

      if (data?.status) {
        setLoading(false);

        showSuccessSnackbar(data.message);
        onClose();
      } else {
        setLoading(false);

        showErrorSnackbar(data.message);
        onClose();
      }
      reportPostReset();
    },
  });

  const { mutate: reportStory, reset: reportStoryReset } = useMutation({
    mutationKey: ["report-story"],
    mutationFn: handleReportStory,
    onSuccess: (data) => {
      console.log("Story Reported", data);

      if (data?.status) {
        setLoading(false);

        showSuccessSnackbar(data.message);
        onClose();
      } else {
        setLoading(false);

        showErrorSnackbar(data.message);
        onClose();
      }
      reportStoryReset();
    },
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="report-modal-title"
      aria-describedby="report-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "90%",
          maxWidth: 350,
          maxHeight: "90vh",
          bgcolor: "background.paper",
          borderRadius: 3,
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h6"
          fontSize={16}
          textAlign="start"
          fontWeight={600}
          sx={{
            padding: "10px 20px",
            borderBottom: "1px solid #dbdbdb",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span> Report</span>
          <Close onClick={onClose} sx={{ mt: 0.3, cursor: "pointer" }} />
        </Typography>
        <Divider />
        <Box
          className="custom-scrollbar-chat"
          sx={{
            overflowY: "auto",
            flex: 1,
            p: 2,
          }}
        >
          <Typography variant="h6" fontSize={16}>
            Why are you reporting this post?
          </Typography>
          <List disablePadding>
            {reasons.map((reason, index) => (
              <React.Fragment key={index}>
                <ListItem
                  button
                  onClick={() => handleReasonSelect(reason)}
                  sx={{
                    borderRadius: 2,
                    textAlign: "start",
                    bgcolor:
                      selectedReason === reason
                        ? theme.palette.mode === "dark"
                          ? "#828385"
                          : "#ddd"
                        : "background.paper",
                    "&:hover": {
                      bgcolor: "inherit",
                    },
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body2" fontSize={14} color="inherit">
                        {reason}
                      </Typography>
                    }
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
          {selectedReason === "Other" && (
            <Box mt={2}>
              <Typography
                variant="body2"
                fontSize={14}
                color="text.secondary"
                mb={1}
              >
                Please describe the issue:
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Describe the issue..."
                variant="outlined"
                value={customReason}
                onChange={handleCustomReasonChange}
              />
            </Box>
          )}
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" p={2}>
          <Button
            variant="text"
            color="primary"
            onClick={handleSubmit}
            disabled={
              !selectedReason ||
              (selectedReason === "Other" && !customReason.trim())
            }
            sx={{
              color: "#3897f0",
              textTransform: "none",
              fontWeight: 600,
            }}
          >
            Submit
          </Button>
          {/* <Button
            variant="outlined"
            color="error"
            onClick={onClose}
            sx={{
              textTransform: "none",
              fontWeight: 600,
            }}
          >
            Cancel
          </Button> */}
        </Box>
        <Backdrop
          open={loading}
          sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
        >
          <CircularProgress color="error" />
        </Backdrop>
      </Box>
    </Modal>
  );
};

export default ReportModal;
