import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Avatar,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Icon } from "@iconify/react";
import home1 from "../assets/images/home.png";
import PostModal from "./PostModal";
import homeLight from "../assets/images/sidebar/Home.png";
import homeLightWhite from "../assets/images/sidebar/homeWhite.png";

import homeActive from "../assets/images/sidebar/Home Fill.png";
import homeActiveWhite from "../assets/images/sidebar/homeWhiteFill.png";

import exploreLight from "../assets/images/sidebar/Explore.png";
import exploreLightWhite from "../assets/images/sidebar/exploreWhite.png";

import exploreActive from "../assets/images/sidebar/Explore Fill.png";
import exploreActiveWhite from "../assets/images/sidebar/exploreWhiteFill.png";

import profileLight from "../assets/images/sidebar/Profile.png";
import profileLightWhite from "../assets/images/sidebar/profileWhite.png";

import profileActive from "../assets/images/sidebar/Profile Fill.png";
import profileActiveWhite from "../assets/images/sidebar/profileWhiteFill.png";

import createImg from "../assets/images/sidebar/Add.png";
import createImgWhite from "../assets/images/sidebar/createPostWhite.png";

import chatLight from "../assets/images/sidebar/Chat.png";
import chatLightWhite from "../assets/images/sidebar/chatWhite.png";
import chatActiveWhite from "../assets/images/sidebar/chatWhiteFill.png";

import chatActive from "../assets/images/sidebar/Chat Fill.png";

import Cookies from "js-cookie";

function Sidebar() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const darkMode = theme.palette.mode === "dark";

  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // New media query for mobile
  const location = useLocation();
  const [searchAnchorEl, setSearchAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const handlePostSubmit = (post) => {
    // Handle post submission
    console.log(post);
    handleCloseModal();
  };
  const handleSearchClick = (event) => {
    setSearchAnchorEl(event.currentTarget);
  };

  const handleSearchClose = () => {
    setSearchAnchorEl(null);
  };

  const isSearchOpen = Boolean(searchAnchorEl);
  const searchId = isSearchOpen ? "search-popover" : undefined;
  const uesrpro = Cookies.get("uesrpro");
  const userName = Cookies.get("username");

  const menuItems = [
    {
      text: "Home",
      img: darkMode ? homeLightWhite : homeLight,
      imgActive: darkMode ? homeActiveWhite : homeActive,
      icon: "material-symbols:home-outline",
      iconActive: "material-symbols:home",
      link: "/",
    },
    {
      text: "Explore",
      img: darkMode ? exploreLightWhite : exploreLight,
      imgActive: darkMode ? exploreActiveWhite : exploreActive,
      icon: "ic:outline-explore",
      iconActive: "material-symbols:explore",
      link: "/explore",
    },
    {
      text: "Profile",
      // img: darkMode ? profileLightWhite : profileLight,
      // imgActive: darkMode ? profileActiveWhite : profileActive,
      img: uesrpro,
      imgActive: uesrpro,
      borderRadius: "50%",
      icon: "iconamoon:profile-light",
      iconActive: "iconamoon:profile-fill",
      link: "/profile",
    },
  ];

  const menuItems1 = [
    {
      text: "Home",
      img: darkMode ? homeLightWhite : homeLight,
      imgActive: darkMode ? homeActiveWhite : homeActive,
      icon: "material-symbols:home-outline",
      iconActive: "material-symbols:home",
      link: "/",
    },
    {
      text: "Explore",
      img: darkMode ? exploreLightWhite : exploreLight,
      imgActive: darkMode ? exploreActiveWhite : exploreActive,
      icon: "ic:outline-explore",
      iconActive: "material-symbols:explore",
      link: "/explore",
    },
    {
      text: "Chats",
      img: darkMode ? chatLightWhite : chatLight,
      imgActive: darkMode ? chatActiveWhite : chatActive,
      icon: "fluent:chat-28-regular",
      iconActive: "fluent:chat-48-filled",
      link: "/chats",
      fontSize: 28,
    },
    {
      text: "Profile",
      // img: darkMode ? profileLightWhite : profileLight,
      // imgActive: darkMode ? profileActiveWhite : profileActive,
      img: uesrpro,
      imgActive: uesrpro,
      borderRadius: "50%",
      icon: "iconamoon:profile-light",
      iconActive: "iconamoon:profile-fill",
      link: "/profile",
    },
  ];

  const isActive = (link) => location.pathname === link;
  useEffect(() => {
    // Preload active images
    menuItems.forEach((item) => {
      const img = new Image();
      img.src = item.imgActive;
    });
  }, [menuItems, location.pathname]);
  // Preload the image dynamically before route change
  const handleClick = (item) => {
    const img = new Image();
    img.src = item.imgActive;
  };

  return (
    <>
      {/* Sidebar for larger screens */}
      {!isMobile && (
        <div
          style={{
            width: isMobile ? "100%" : 170,
            position: "fixed",
            height: "100%",
            top: 60,
            left: 0,
            zIndex: 1, // Ensure Sidebar is on top of other elements
            borderRight: "1px solid #dbdbd9",
            // backgroundColor: theme.palette.background.paper,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <List component="nav" sx={{ width: "100%", p: 1 }}>
            {menuItems.slice(0, 2).map((item, index) => (
              <ListItem
                button
                component={Link}
                to={item.link}
                key={index}
                sx={{
                  fontSize: "16px",
                  mb: 1,
                  borderRadius: 2,
                  width: "100%",
                  justifyContent: isSmallScreen ? "center" : "flex-start",
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? "rgba(255, 255, 255, 0.1)"
                        : "rgba(0,0,0,0.05)",
                  },
                }}
                className="listinSidebar"
                onClick={() => handleClick(item)} // Preload the next image on click
              >
                <ListItemIcon sx={{ minWidth: 0, mr: isSmallScreen ? 0 : 1 }}>
                  <img
                    loading={isActive(item.link) ? "eager" : "lazy"}
                    src={isActive(item.link) ? item.imgActive : item.img}
                    height={30}
                    width={30}
                    className="icon-size-incr-onHover"
                    style={{
                      borderRadius: item.borderRadius ? item.borderRadius : "",
                    }}
                  />
                  {/* <Icon
                    icon={isActive(item.link) ? item.iconActive : item.icon}
                    style={{
                      fontSize: 30,
                      color: isActive(item.link)
                        ? theme.palette.mode === "dark"
                          ? "#fff"
                          : "#080809"
                        : theme.palette.mode === "dark"
                        ? "#fff"
                        : "#080809",
                    }}
                    className="icon-size-incr-onHover" // Ensure this class is present
                  /> */}
                </ListItemIcon>
                {!isSmallScreen && (
                  <ListItemText
                    primary={item.text}
                    sx={{
                      mt: 0.8,
                      fontSize: "14px",
                      fontWeight: isActive(item.link) ? "700" : "400", // Conditionally set font weight
                    }}
                    className={isActive(item.link) ? "fw-700" : ""}
                  />
                )}
              </ListItem>
            ))}

            <ListItem
              button
              onClick={handleOpenModal}
              component={Link}
              sx={{
                fontSize: "16px",
                mb: 1,
                borderRadius: 2,
                width: "100%", // Ensure full width for ListItem
                justifyContent: isSmallScreen ? "center" : "flex-start", // Center items on small screens

                // Hover effect
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? "rgba(255, 255, 255, 0.1)"
                      : "rgba(0,0,0,0.05)",
                },
              }}
              className="listinSidebar"
            >
              <ListItemIcon sx={{ minWidth: 0, mr: isSmallScreen ? 0 : 1 }}>
                {/* <Icon
                  icon={"hugeicons:add-square"}
                  style={{
                    color: theme.palette.mode === "dark" ? "#fff" : "#080809",
                    fontSize: 28,
                    fontWeight: "900 !important",
                  }}
                  className="icon-size-incr-onHover" // Ensure this class is present
                /> */}
                <img
                  src={darkMode ? createImgWhite : createImg}
                  height={25}
                  width={25}
                  className="icon-size-incr-onHover" // Ensure this class is present
                />
              </ListItemIcon>
              {!isSmallScreen && (
                <ListItemText
                  primary={"Create"}
                  sx={{
                    fontSize: "20px",
                  }}
                />
              )}
            </ListItem>

            {menuItems.slice(2).map((item, index) => (
              <ListItem
                button
                component={Link}
                to={item.link}
                key={index}
                sx={{
                  fontSize: "16px",
                  mb: 1,
                  borderRadius: 2,
                  width: "100%",
                  justifyContent: isSmallScreen ? "center" : "flex-start",
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? "rgba(255, 255, 255, 0.1)"
                        : "rgba(0,0,0,0.05)",
                  },
                }}
                className="listinSidebar"
                onClick={() => handleClick(item)} // Preload the next image on click
              >
                <ListItemIcon sx={{ minWidth: 0, mr: isSmallScreen ? 0 : 1 }}>
                  <Avatar
                    loading={isActive(item.link) ? "eager" : "lazy"}
                    src={uesrpro ? uesrpro : undefined} // Only set the `src` if `userpro` is available
                    alt={userName}
                    className="icon-size-incr-onHover"
                    sx={{
                      height: 28,
                      width: 28,
                    }}
                  />
                  {/* <img
                    loading={isActive(item.link) ? "eager" : "lazy"}
                    src={isActive(item.link) ? item.imgActive : item.img}
                    height={25}
                    width={25}
                    className="icon-size-incr-onHover"
                    style={{
                      borderRadius: item.borderRadius ? item.borderRadius : "",
                    }}
                  /> */}
                  {/* <Icon
                    icon={isActive(item.link) ? item.iconActive : item.icon}
                    style={{
                      fontSize: 30,
                      color: isActive(item.link)
                        ? theme.palette.mode === "dark"
                          ? "#fff"
                          : "#080809"
                        : theme.palette.mode === "dark"
                        ? "#fff"
                        : "#080809",
                    }}
                    className="icon-size-incr-onHover" // Ensure this class is present
                  /> */}
                </ListItemIcon>
                {!isSmallScreen && (
                  <ListItemText
                    primary={item.text}
                    sx={{
                      mt: 0.8,
                      fontSize: "14px",
                      fontWeight: isActive(item.link) ? "700" : "400", // Conditionally set font weight
                    }}
                    className={isActive(item.link) ? "fw-700" : ""}
                  />
                )}
              </ListItem>
            ))}
            <ListItem
              sx={{ mt: "auto", width: "100%", justifyContent: "center" }}
            >
              <Link to={"https://silocloud.io/"}>
                <img
                  src={home1}
                  alt="Fixed"
                  className="fixed-image"
                  height={isSmallScreen ? 30 : 40}
                  width={isSmallScreen ? 30 : 40}
                />
              </Link>
            </ListItem>
          </List>
          <Popover
            id={searchId}
            open={isSearchOpen}
            anchorEl={searchAnchorEl}
            onClose={handleSearchClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box p={2} width={300}>
              <TextField
                fullWidth
                label="Search"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit" color="primary">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Popover>
        </div>
      )}

      {/* -------------------------------------------------------------------------------------------------------------------------------- */}

      {/* Bottom navigation for mobile screens */}

      {isMobile && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            height: 45,
            // borderTop: "1px solid #dbdbd9",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            // backgroundColor: theme.palette.background.paper,
            zIndex: 1100, // Ensure Bottom Navigation is on top of other elements
          }}
        >
          {isMobile && (
            <div
              style={{
                position: "fixed",
                bottom: 0,
                width: "100%",
                height: 40,
                borderTop: "1px solid #dbdbd9",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: theme.palette.background.paper,
                zIndex: 1100, // Ensure Bottom Navigation is on top of other elements
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                {/* Render first half of the menu items */}
                {menuItems1.slice(0, 2).map((item, index) => (
                  <IconButton
                    component={Link}
                    to={item.link}
                    key={index}
                    sx={{
                      color: isActive(item.link)
                        ? theme.palette.mode === "dark"
                          ? "#fff"
                          : "#080809"
                        : theme.palette.mode === "dark"
                        ? "#fff"
                        : "#080809",
                    }}
                    className="listinSidebar"
                    onClick={() => handleClick(item)} // Preload the next image on click
                  >
                    <img
                      loading={isActive(item.link) ? "eager" : "lazy"}
                      src={isActive(item.link) ? item.imgActive : item.img}
                      height={22}
                      width={22}
                      className="icon-size-incr-onHover"
                    />
                    {/* <Icon
                      icon={isActive(item.link) ? item.iconActive : item.icon}
                      style={{ fontSize: 30 }}
                    /> */}
                  </IconButton>
                ))}
              </div>

              {/* Centered Create Post button */}
              <IconButton className="listinSidebar">
                <img
                  onClick={handleOpenModal}
                  loading={"lazy"}
                  src={darkMode ? createImgWhite : createImg}
                  height={22}
                  width={22}
                  style={{
                    margin: "0 19px",
                    color: theme.palette.mode === "dark" ? "#fff" : "#080809",
                    fontSize: 28,
                    fontWeight: "900 !important",
                  }}
                  className="icon-size-incr-onHover"
                />
                {/* <Icon
                  onClick={handleOpenModal}
                  icon={"hugeicons:add-square"}
                  style={{
                    margin: "0 20px",
                    color: theme.palette.mode === "dark" ? "#fff" : "#080809",
                    fontSize: 28,
                    fontWeight: "900 !important",
                  }}
                  className="icon-size-incr-onHover"
                /> */}
              </IconButton>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                {/* Render second half of the menu items */}
                {menuItems1.slice(2).map((item, index) => (
                  <IconButton
                    component={Link}
                    to={item.link}
                    key={index}
                    sx={{
                      color: isActive(item.link)
                        ? theme.palette.mode === "dark"
                          ? "#fff"
                          : "#080809"
                        : theme.palette.mode === "dark"
                        ? "#fff"
                        : "#080809",
                    }}
                    className="listinSidebar"
                    onClick={() => handleClick(item)} // Preload the next image on click
                  >
                    <img
                      loading={isActive(item.link) ? "eager" : "lazy"}
                      src={isActive(item.link) ? item.imgActive : item.img}
                      height={22}
                      width={22}
                      className="icon-size-incr-onHover"
                      style={{
                        borderRadius: item.borderRadius
                          ? item.borderRadius
                          : "",
                      }}
                    />
                    {/* <Icon
                      icon={isActive(item.link) ? item.iconActive : item.icon}
                      style={{ fontSize: item.fontSize ? item.fontSize : 30 }}
                    /> */}
                  </IconButton>
                ))}
              </div>

              <Link to={"https://silocloud.io/"}>
                <img
                  src={home1}
                  alt="Fixed"
                  className="fixed-image"
                  height={30}
                  width={30}
                  style={{ display: isMobile ? "none" : "flex" }}
                />
                <img
                  src={home1}
                  alt="Fixed"
                  className="fixed-image1"
                  height={30}
                  width={30}
                  style={{
                    display:
                      isMobile && location.pathname === "/chats"
                        ? "none"
                        : "flex",
                  }}
                />
              </Link>
            </div>
          )}

          <Icon
            onClick={handleOpenModal}
            icon={"hugeicons:add-square"}
            style={{
              color: theme.palette.mode === "dark" ? "#fff" : "#080809",
              fontSize: 28,
              fontWeight: "900 !important",
            }}
          />
          <Link to={"https://silocloud.io/"}>
            <img
              src={home1}
              alt="Fixed"
              className="fixed-image"
              height={30}
              width={30}
              style={{ display: isMobile ? "none" : "flex" }}
            />
            <img
              src={home1}
              alt="Fixed"
              className="fixed-image1"
              height={30}
              width={30}
              style={{
                // display: isMobile ? "flex" : "none",
                margin: isMobile && location.pathname === "/chats" ? "10px" : 0,
                display:
                  isMobile && location.pathname === "/chats"
                    ? "none !important"
                    : "flex !important",
              }}
            />
          </Link>
        </div>
      )}

      <PostModal
        open={modalOpen}
        handleClose={handleCloseModal}
        handleSubmit={handlePostSubmit}
      />
    </>
  );
}

export default Sidebar;
