import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Avatar,
  Box,
  Backdrop,
  CircularProgress,
} from "@mui/material";

const ConfirmationModal = ({
  open,
  handleClose,
  handleConfirm,
  modalInfo,
  loading1,
}) => {
  const { user, action } = modalInfo;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxWidth: 350,
          borderRadius: "16px", // Softer corners for a more modern look
          padding: "0 16px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
        },
      }}
    >
      {/* Modal Title */}
      <DialogTitle disableTypography>
        <Typography
          variant="h6"
          style={{
            fontWeight: "bold",
            textAlign: "center",
            color: "#e63946", // Reddish primary color
          }}
        >
          {action === "remove" ? "Remove Follower" : "Unfollow User"}
        </Typography>
      </DialogTitle>

      {/* User Info */}
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
          <Avatar
            src={user?.avatar_url}
            alt={user?.username}
            sx={{
              width: 80,
              height: 80,
              mb: 2,
              border: "3px solid #De3744 ", // Reddish border to match the theme
            }}
          />
          <Typography
            variant="body1"
            style={{ fontWeight: "bold", color: "#e63946" }} // Reddish username text
          >
            {user?.username}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {user?.email}
          </Typography>
        </Box>

        {/* Confirmation Text */}
        <DialogContentText
          style={{
            textAlign: "center",
            marginBottom: "10px",
            color: "#555",
            fontSize: "16px",
          }}
        >
          {action === "remove"
            ? `Are you sure you want to remove ${user?.username} as a follower?`
            : `Are you sure you want to unfollow ${user?.username}?`}
        </DialogContentText>
      </DialogContent>

      {/* Action Buttons */}
      <DialogActions
        style={{
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          style={{
            color: "#e63946", // Reddish color for the cancel button
            borderColor: "#e63946",
            borderRadius: "30px",
            padding: "6px 20px",
            fontWeight: "bold",
            textTransform: "none",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          style={{
            backgroundColor: "#De3744 ", // Solid red for the confirm button
            color: "#fff",
            borderRadius: "30px",
            padding: "6px 20px",
            fontWeight: "bold",
            textTransform: "none",
            marginLeft: "10px",
          }}
        >
          {action === "remove" ? "Remove" : "Unfollow"}
        </Button>
      </DialogActions>
      <Backdrop open={loading1}>
        <CircularProgress color="error" />
      </Backdrop>
    </Dialog>
  );
};

export default ConfirmationModal;
