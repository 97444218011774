import React, { useEffect, useState } from "react";
import { Box, TextField, Paper, useMediaQuery, useTheme } from "@mui/material";
import { Search } from "@mui/icons-material";
import ChatUsers from "./ChatUsers";
import ChatOpen from "./ChatOpen";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import Cookies from "js-cookie";
import { postRequestGetUserProfile } from "../../api-service";
import { useLocation } from "react-router-dom";

function Chat() {
  const theme = useTheme();
  const location = useLocation(); // Call useLocation as a function
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [profile, setProfile] = useState(null); // State for profile
  const authToken = Cookies.get("authToken");
  const [currentUserAvatar, setCurrentUserAvatar] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const selectUser = (user) => {
    setSelectedUser(user);
    setUsers(
      users.map((u) =>
        u.id === user.id ? { ...u, selected: true } : { ...u, selected: false }
      )
    );
  };

  const goBackToUserList = () => {
    setSelectedUser(null);
    setUsers(users.map((u) => ({ ...u, selected: false })));
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        if (authToken) {
          const res = await postRequestGetUserProfile("get-profile", {
            authToken,
          });
          setProfile(res.data.profile); // Set profile state
          const avatarUrl = res.data.communityProfile
            .profile_image_temporary_url
            ? res.data.communityProfile.profile_image_temporary_url
            : res.data.profile.profile_image_path;
          setCurrentUserAvatar(avatarUrl);
          // console.log("Profile fetched:", res.data.profile);
        } else {
          console.log("No token found");
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchUserProfile();
  }, [authToken]); // Re-fetch profile if token changes

  useEffect(() => {
    const fetchCurrentUser = async () => {
      if (!profile || !profile.user_id) {
        console.error("Profile or user ID is missing");
        return;
      }

      const userRef = doc(
        db,
        "Users",
        `${profile.username}_${profile.user_id}`.toString()
      );
      try {
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          setCurrentUser(userDoc.data());
        } else {
          // If the user doesn't exist, create the user in Firestore
          const newUser = {
            id: profile.user_id,
            name: profile.username || "Unknown User", // Fallback to 'Unknown User'
            createdAt: new Date().toISOString(),
          };
          await setDoc(userRef, newUser);
          setCurrentUser(newUser);
        }
      } catch (error) {
        console.error("Error fetching or creating user:", error);
      }
    };

    fetchCurrentUser();
  }, [profile]);

  useEffect(() => {
    if (currentUser) {
      // If the current user is fetched or created, add them to the chat users list
      setUsers((prevUsers) => {
        if (!prevUsers.some((user) => user.id === currentUser.id)) {
          return [...prevUsers, currentUser];
        }
        return prevUsers;
      });
    }
  }, [currentUser]);

  // Conditionally render chat only on small screens and when location is /chats
  if (isSmallScreen && location.pathname !== "/chats") {
    return null;
  }

  return (
    <Paper
      sx={{
        padding: "10px",
        mt: 1.3,
        height: isSmallScreen ? "calc(100vh - 115px)" : "calc(100vh - 70px)",
        width: isSmallScreen ? "96.5%" : "22%",
        textAlign: "center",
        position: "fixed",
        top: 58,
        right: isSmallScreen ? "10px" : "16px",
        backgroundColor: "transparent",
        borderRadius: 3,
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
        overflow: "hidden",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {!selectedUser && (
          <Box
            sx={{
              padding: 2,
              borderBottom: "1px solid #ddd",
            }}
          >
            <TextField
              fullWidth
              placeholder="Search chats"
              variant="outlined"
              size="small"
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: <Search />,
              }}
              sx={{
                "& .MuiInputBase-input::placeholder": {
                  color: theme.palette.text.secondary,
                  fontSize: "12px",
                },
              }}
            />
          </Box>
        )}
        <Box className="custom-scrollbar" sx={{ overflowY: "auto", flex: 1 }}>
          {!selectedUser ? (
            <ChatUsers
              users={users}
              selectUser={selectUser}
              currentUser={profile?.user_id}
            />
          ) : (
            <ChatOpen
              user={selectedUser}
              onBack={goBackToUserList}
              currentUser={profile?.user_id}
              currentUserAvatar={currentUserAvatar}
            />
          )}
        </Box>
      </Box>
    </Paper>
  );
}

export default Chat;
