import React from "react";
import { useParams } from "react-router-dom";
import Profile from "../components/Profile";

function ProfilePage() {
  const { username } = useParams();

  return (
    <div>
      <Profile username={username} />
    </div>
  );
}

export default ProfilePage;
