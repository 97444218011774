// src/components/profile/FinalConfirm.js

import React from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme, useMediaQuery } from '@mui/material';

const FinalConfirm = ({
  open,
  handleClose,
  handleBack,
  handleSubmit,
  highlightName,
  selectedStories,
  coverImage,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallScreen ? 300 : 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    p: 2,
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="final-confirm-title">
      <Box sx={modalStyle}>
        <Box p={1} display="flex" alignItems="center">
          <IconButton onClick={handleBack} sx={{ mr: 1 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography id="final-confirm-title" variant="h6" component="h3" flexGrow={1} textAlign="center">
            Confirm Highlight
          </Typography>
          <IconButton onClick={handleClose} sx={{ mr: 1 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box p={2}>
          <Typography variant="subtitle1"><strong>Name:</strong> {highlightName}</Typography>
          <Typography variant="subtitle1"><strong>Number of Stories:</strong> {selectedStories.length}</Typography>
          <Typography variant="subtitle1"><strong>Cover Image:</strong> {coverImage ? coverImage.title : 'None'}</Typography>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="center" my={1} p={1}>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FinalConfirm;
