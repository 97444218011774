import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  CircularProgress,
  Box,
  Dialog,
  IconButton,
  Typography,
  Skeleton,
  useTheme,
} from "@mui/material";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import PlayArrowOutlined from "@mui/icons-material/PlayArrowOutlined";
import PauseOutlined from "@mui/icons-material/PauseOutlined";
import {
  StopCircleOutlined,
  FavoriteBorder,
  Comment,
  Share,
} from "@mui/icons-material";
import CommentsModal from "../comments/CommentsModal";
import { Icon } from "@iconify/react/dist/iconify.js";
import ShareModal from "../../share/ShareModal ";

const ProfileSectionPosts = ({ posts, postData }) => {
  const [loading, setLoading] = useState(true);
  const [selectedPost, setSelectedPost] = useState(null);
  const [muted, setMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRefs = useRef({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark"; // Check if the theme is dark
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const [post, setPost] = useState("");
  const handleOpenModalSend = (post) => {
    setSendModalOpen(true);
    setPost(post);
  };

  const handleCloseModalSend = () => {
    setSendModalOpen(false);
    setIsModalOpen(false);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    Object.values(videoRefs.current).forEach((video) => {
      if (video !== videoRefs.current[selectedPost?.id]) {
        video?.pause();
      }
    });
  }, [selectedPost]);

  const handlePostClick = (post) => {
    setSelectedPost(post);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedPost(null);
  };

  const toggleMute = () => {
    if (videoRefs.current[selectedPost?.id]) {
      videoRefs.current[selectedPost?.id].muted = !muted;
      setMuted(!muted);
    }
  };

  const handlePlayPause = () => {
    if (videoRefs.current[selectedPost?.id]) {
      if (videoRefs.current[selectedPost?.id].paused) {
        videoRefs.current[selectedPost?.id].play();
        setIsPlaying(true);
      } else {
        videoRefs.current[selectedPost?.id].pause();
        setIsPlaying(false);
      }
    }
  };

  const handleStop = () => {
    if (videoRefs.current[selectedPost?.id]) {
      videoRefs.current[selectedPost?.id].pause();
      videoRefs.current[selectedPost?.id].currentTime = 0;
      setIsPlaying(false);
    }
  };

  return (
    <Box sx={{ padding: "16px" }}>
      {loading ? (
        <Grid container spacing={0.4} rowSpacing={2.5} textAlign={"start"}>
          {Array.from(new Array(9))?.map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Skeleton
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={250}
                sx={{ mr: 1, mt: -2 }}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={0.4} rowSpacing={-2} textAlign={"start"}>
          {posts?.map((post) => {
            const media = post.media_details[0];
            const mediaPath = media.media_urls;
            const mediaType = media.media_type;
            const isImage = mediaType === "image";
            const isVideo = mediaType === "video";

            return (
              <Grid item xs={12} sm={6} md={4} key={post.id} mt={-0.29}>
                <Box
                  sx={{
                    position: "relative",
                    cursor: "pointer",
                    height: 250,
                    "&:hover .media-element": {
                      opacity: 0.3, // Lower opacity on hover
                      transition: "opacity 0.3s ease-in-out",
                    },
                    "&:hover .controls": {
                      opacity: 1,
                    },
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.8)", // Apply same background to image/video box
                    },
                  }}
                  onClick={() => handlePostClick(post)}
                >
                  {isImage && (
                    <Box
                      component="img"
                      src={mediaPath}
                      alt={post.caption}
                      className="media-element"
                      sx={{
                        width: "100%",
                        height: 250,
                        objectFit: "cover",
                        transition: "opacity 0.3s ease-in-out",
                      }}
                    />
                  )}

                  {isVideo && (
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: 250,
                        overflow: "hidden",
                        "&:hover video": {
                          opacity: 0.3, // Sync opacity with image hover effect
                          transition: "opacity 0.3s ease-in-out",
                        },
                      }}
                    >
                      <video
                        ref={(el) => (videoRefs.current[post.id] = el)}
                        src={mediaPath}
                        loop
                        autoPlay
                        muted={muted}
                        playsInline
                        className="media-element"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          transition: "opacity 0.3s ease-in-out",
                        }}
                      />
                    </Box>
                  )}

                  {/* Post Controls */}
                  <Box
                    className="controls"
                    sx={{
                      position: "absolute",
                      bottom: "43%",
                      left: 0,
                      right: 0,
                      padding: "8px 50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      opacity: 0, // Hidden by default, shows on hover
                      transition: "opacity 0.3s ease-in-out",
                      color: "#fff",
                      fontWeight: 700,
                      "& .MuiIconButton-root": {
                        color: "#fff",
                      },
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton>
                        <Icon icon={"solar:heart-linear"} fontSize={30} />
                      </IconButton>
                      <Typography variant="h6" ml={-0.5} mr={1}>
                        {post.likes_count || 0}
                      </Typography>

                      <IconButton>
                        <Icon icon="iconamoon:comment-light" fontSize={30} />
                      </IconButton>
                      <Typography variant="h6" ml={-0.5} mr={1}>
                        {post.comment_count || 0}
                      </Typography>
                    </Box>

                    <IconButton
                      onClick={() => handleOpenModalSend(post)} // Open modal on click
                    >
                      <Icon icon="tabler:send" fontSize={30} />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      )}

      {/* Share Modal */}
      {sendModalOpen && (
        <ShareModal
          open={sendModalOpen}
          handleClose={handleCloseModalSend} // Pass close handler
          post={post} // Pass post data
        />
      )}

      {/* <Dialog
        open={isModalOpen}
        onClose={handleModalClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: "hidden",
          },
        }}
      > */}
      {selectedPost && !sendModalOpen && (
        <CommentsModal
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          postData={selectedPost}
          imageUrl={selectedPost?.media_details[0]?.media_urls}
          caption={selectedPost.caption}
          userId={postData?.user_id}
          userName={postData?.username}
          profileImage={postData?.profile_image_path}
          following_status={postData?.following_status}
        />
      )}
      {/* </Dialog> */}
    </Box>
  );
};

export default ProfileSectionPosts;
