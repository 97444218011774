import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
  Typography,
  IconButton,
  Box,
  useTheme,
  TextField,
  Avatar,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import { db } from "../../firebase/firebaseConfig";
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import ChatInput from "./ChatInput";
import CloseIcon from "@mui/icons-material/Close";

import { Menu, Item, useContextMenu } from "react-contexify";
import "react-contexify/ReactContexify.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import { MoreVertOutlined } from "@mui/icons-material";

const MENU_ID = "message_menu";

const ChatOpen = ({ user, onBack, currentUser, currentUserAvatar }) => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark";
  const [messages, setMessages] = useState([]);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [replyingToMessageId, setReplyingToMessageId] = useState(null);
  const [messageText, setMessageText] = useState("");
  const [incomingID, setIncomingID] = useState(false);
  // console.log("currentUserAvatar", currentUserAvatar);

  const messagesEndRef = useRef(null);
  const repliedMessageRef = useRef(null); // Ref for the replied message
  console.log("messages", messages);
  const { show } = useContextMenu({ id: MENU_ID });

  // Handle right-click context menu
  // const handleContextMenu = (event, messageId, isIncoming) => {
  //   event.preventDefault();
  //   show({ event, props: { messageId, isIncoming } });
  // };

  const handleClearReply = () => {
    setReplyingToMessageId(null);
  };
  // Handle actions from context menu
  const handleMenuAction = async ({ id, props }) => {
    const { messageId, isIncoming } = props;

    switch (id) {
      case "edit":
        const messageToEdit = messages.find((msg) => msg.id === messageId);
        if (messageToEdit) {
          setEditingMessageId(messageId);
          setMessageText(messageToEdit.text);
        }
        break;
      case "delete":
        try {
          await deleteDoc(doc(db, "Chats", user.chatId, "messages", messageId));
        } catch (error) {
          console.error("Error deleting message:", error);
        }
        break;
      case "copy":
        navigator.clipboard.writeText(
          messages.find((msg) => msg.id === messageId)?.text || ""
        );
        break;
      case "reply":
        setReplyingToMessageId(messageId);
        break;
      default:
        break;
    }
  };

  // Scroll to the bottom of the chat messages
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Scroll to the replied message
  useEffect(() => {
    if (replyingToMessageId && repliedMessageRef.current) {
      repliedMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [replyingToMessageId]);

  // Fetch messages from Firestore
  useEffect(() => {
    if (user?.chatId) {
      const messagesRef = collection(db, "Chats", user.chatId, "messages");
      const q = query(messagesRef, orderBy("timestamp", "asc"));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const updatedMessages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMessages(updatedMessages);
      });

      return () => unsubscribe();
    }
  }, [user?.chatId]);

  // Handle sending a new message
  const handleSendMessage = async (replyingMsg, message, replyingToUserId) => {
    if (message?.trim() && user?.chatId && currentUser) {
      const messagesRef = collection(db, "Chats", user.chatId, "messages");
      try {
        await addDoc(messagesRef, {
          replyingMsg: replyingMsg,
          replyingToUserId: replyingToUserId || null, // Store replying message ID if applicable
          replyingToMessageId: replyingToMessageId,
          text: message,
          senderId: currentUser,
          timestamp: new Date(),
          reply: replyingToMessageId ? true : false,
          edited: false,
        });
        setMessageText("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  // Send a new message
  const handleSendMessage1 = async (message) => {
    if (message.trim() && user?.chatId && currentUser) {
      const messagesRef = collection(db, "Chats", user.chatId, "messages");
      try {
        await addDoc(messagesRef, {
          text: message,
          senderId: currentUser,
          timestamp: new Date(),
        });
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  // Handle editing a message
  const handleEditMessage = async () => {
    if (editingMessageId && messageText.trim()) {
      try {
        await updateDoc(
          doc(db, "Chats", user.chatId, "messages", editingMessageId),
          {
            text: messageText,
            edited: true,
          }
        );
        setEditingMessageId(null);
        setMessageText("");
        setIncomingID(false);
      } catch (error) {
        console.error("Error updating message:", error);
        setIncomingID(false);
      }
    }
  };

  // Handle replying to a message
  const handleReply = (reply) => {
    const replyingToUserId = repliedMessage?.senderId; // Get the userId of the message you're replying to

    handleSendMessage(
      ` ${messages.find((msg) => msg.id === replyingToMessageId)?.text} `,
      `${reply}`,
      replyingToUserId
    );
    setReplyingToMessageId(null);
  };

  const repliedMessage = messages.find((msg) => msg.id === replyingToMessageId);

  const messageRefs = useRef({});

  const handleContextMenu = (event, messageId, isIncoming) => {
    event.preventDefault();
    setIncomingID(isIncoming);
    show({ event, props: { messageId, isIncoming } });
  };

  const scrollToMessage = (messageId) => {
    if (messageRefs.current[messageId]) {
      messageRefs.current[messageId].scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Paper className="chat-open">
      <Box
        className="chat-header"
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "8px 2px",
          // backgroundColor: "#f5f5f5", // optional background color
        }}
      >
        {onBack && (
          <IconButton
            onClick={onBack}
            className="back-button"
            sx={{ marginRight: "1px", ml: -1 }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Avatar
          src={user.profileAvatar}
          sx={{ height: 35, width: 35, marginRight: "8px" }}
        />
        <Typography
          variant="h6"
          className="user-name"
          sx={{ fontSize: "16px" }}
        >
          {user.name}
        </Typography>
      </Box>

      <Box className="chat-messages custom-scrollbar-chat">
        {messages.map((msg) => (
          <Box
            key={msg.id}
            ref={(el) => (messageRefs.current[msg.id] = el)} // Set ref for each message
            onContextMenu={(e) =>
              handleContextMenu(e, msg.id, msg.senderId !== currentUser)
            }
            className={`message-container ${
              msg.senderId === currentUser
                ? darkMode
                  ? "outgoing-dark"
                  : "outgoing"
                : darkMode
                ? "incoming-dark"
                : "incoming"
            }`}
            sx={{ display: "flex" }}
          >
            {/* <Typography
              variant="body2"
              color="textSecondary"
              className="message-sender"
              // display={"flex"}
            >
              {msg.senderId === currentUser ? (
                msg.reply === true ? (
                  `replied to ${
                    msg.senderId === msg.replyingToUserId
                      ? "yourself"
                      : user.name
                  }`
                ) : (
                  <span span style={{ display: "flex", justifyContent: "end" }}>
                    {" "}
                    <Avatar
                      src={currentUserAvatar}
                      sx={{ height: 15, width: 15, marginRight: "2px" }}
                    />
                    You
                  </span>
                )
              ) : (
                <span style={{ display: "flex" }}>
                  <Avatar
                    src={user.profileAvatar}
                    sx={{ height: 15, width: 15, marginRight: "2px" }}
                  />
                  {user.name}
                </span>
              )}
            </Typography> */}
            {msg.reply === true && (
              <Box
                className={`message-bubble `}
                sx={{
                  backgroundColor: darkMode
                    ? "#909090 !important"
                    : "#e8e8e8 !important",
                  mb: 0.1,
                }}
              >
                <Typography
                  onClick={() => scrollToMessage(msg.replyingToMessageId)}
                  variant="body1"
                  sx={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    textAlign: "start",
                    fontSize: "13px",
                    cursor: "pointer",
                  }}
                >
                  {msg.replyingMsg
                    .slice(0, 80)
                    .split(" ")
                    .map((word, i) =>
                      word.match(/(https?:\/\/[^\s]+)/g) ? (
                        <span
                          key={i}
                          href={word}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {word}
                          {msg.replyingMsg.length > 80 ? " ..." : ""}
                        </span>
                      ) : (
                        <span key={i}>{word} </span>
                      )
                    )}
                </Typography>

                {/* <Typography>{msg.replyingMsg}</Typography> */}
              </Box>
            )}

            <Box className="message-bubble">
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  textAlign: "start",
                  fontSize: "13px",
                }}
              >
                {msg.text.split(" ").map((word, i) =>
                  word.match(/(https?:\/\/[^\s]+)/g) ? (
                    <a
                      key={i}
                      href={word}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "blue" }}
                    >
                      {word}
                    </a>
                  ) : (
                    <span key={i}>{word} </span>
                  )
                )}
                {msg.edited && (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ display: "block", marginTop: "2px" }}
                  >
                    (Edited)
                  </Typography>
                )}
              </Typography>
            </Box>
            {/* <MoreVertOutlined /> */}

            {msg.timestamp?.seconds && (
              <Typography
                variant="caption"
                color="textSecondary"
                className="message-timestamp"
                fontSize={9}
              >
                {new Date(msg.timestamp.seconds * 1000).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Typography>
            )}
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </Box>
      {/* {replyingToMessageId && (
        <Box className="replying-message">
          <Typography variant="body2" color="textSecondary">
            Replying:
          </Typography>
          <Typography variant="body1">{messageText}</Typography>
        </Box>
      )} */}
      {replyingToMessageId && repliedMessage && (
        <Box className="replied-message" ref={repliedMessageRef}>
          <span style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="caption" className="replied-message-title">
              <Icon
                icon="bytesize:reply"
                fontSize={19}
                style={{ marginRight: "7px", marginBottom: "-3px" }}
              />{" "}
              Replying to:
            </Typography>
            <IconButton
              className="clear-icon"
              onClick={handleClearReply}
              size="small"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </span>
          <Typography
            variant="body2"
            className="replied-message-text"
            onClick={() => scrollToMessage(replyingToMessageId)}
            style={{ cursor: "pointer", textDecoration: "underline" }} // Add some styles to indicate it's clickable
          >
            {repliedMessage.text}
          </Typography>
        </Box>
      )}

      <Box>
        {editingMessageId || replyingToMessageId ? (
          <>
            <Box className="chat-input">
              <TextField
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.shiftKey === false) {
                    e.preventDefault();
                    if (editingMessageId) {
                      handleEditMessage();
                    } else if (replyingToMessageId) {
                      handleReply(messageText);
                    }
                  }
                }}
                multiline
                rows={2}
                variant="standard"
                fullWidth
                placeholder="Type your message..."
                className="textField"
              />
              <IconButton
                onClick={() => {
                  if (editingMessageId) {
                    handleEditMessage();
                  } else if (replyingToMessageId) {
                    handleReply(messageText);
                  } else {
                    handleSendMessage(messageText);
                  }
                  setMessageText("");
                  setEditingMessageId(null);
                  setReplyingToMessageId(null);
                }}
              >
                <SendIcon />
              </IconButton>
            </Box>
          </>
        ) : (
          // <ChatInput
          //   onSend={(message) => {
          //     handleSendMessage(message);
          //   }}
          // />
          <ChatInput onSend={handleSendMessage1} />
        )}
      </Box>

      {/* Context Menu */}
      <Menu id={MENU_ID} theme="light">
        {!incomingID && (
          <Item id="edit" onClick={handleMenuAction}>
            <Icon
              icon="iconamoon:edit-thin"
              fontSize={20}
              style={{ marginRight: "10px" }}
            />{" "}
            Edit
          </Item>
        )}
        {!incomingID && (
          <Item id="delete" onClick={handleMenuAction}>
            <Icon
              icon="material-symbols:delete-outline"
              fontSize={19}
              style={{ marginRight: "10px" }}
            />{" "}
            Delete
          </Item>
        )}
        <Item id="copy" onClick={handleMenuAction}>
          <Icon
            icon="fluent:copy-24-regular"
            fontSize={19}
            style={{ marginRight: "10px" }}
          />{" "}
          Copy
        </Item>
        <Item id="reply" onClick={handleMenuAction}>
          <Icon
            icon="bytesize:reply"
            fontSize={19}
            style={{ marginRight: "10px" }}
          />{" "}
          Reply
        </Item>
      </Menu>
    </Paper>
  );
};

export default ChatOpen;
