import { createSlice } from "@reduxjs/toolkit";

export const mediaUrlSlice = createSlice({
  name: "mediaUrl",
  initialState: {
    imageUrl: null,
    videoUrl: null,
  },
  reducers: {
    setImageUrl: (state, action) => {
      state.imageUrl = action.payload;
      state.videoUrl = null;
    },
    setVideoUrl: (state, action) => {
      state.videoUrl = action.payload;
      state.imageUrl = null;
    },
    clearMediaUrl: (state) => {
      state.imageUrl = null;
      state.videoUrl = null;
    },
  },
});

export const { setImageUrl, setVideoUrl, clearMediaUrl } = mediaUrlSlice.actions;
export default mediaUrlSlice.reducer;
