import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "../pages/Home";
import Explore from "../pages/Explore";
import ProfilePage from "../pages/ProfilePage";
import NotFoundPage from "../pages/NotFoundPage";
import Cookies from "js-cookie";
import { postRequestGetUserProfile } from "../api-service";
import { updateUserStatus } from "../firebase/firebase-service";
import { Button } from "@mui/material";
import PostPage from "../components/post/PostPage";
import Chat from "../components/chats/Chat";
import OtherUserProfile from "../components/OtherUserProfile";
import EditProfile from "../components/profile/EditProfile";
import { io } from "socket.io-client";
import Archive from "../components/Archive/Archive";

const AppRouter = () => {
  const location = useLocation();
  const authToken = Cookies.get("authToken");
  const username = Cookies.get("username");

  const [profile, setProfile] = useState(null); // State for profile

  // Fetch user profile and set status to active when browser opens
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        if (authToken) {
          const res = await postRequestGetUserProfile("get-profile", {
            authToken,
          });
          const userProfile = res.data.profile;
          const communityProfile = res.data.communityProfile;

          setProfile(userProfile); // Set profile state
          localStorage.setItem("loggedIn_user_id", userProfile.user_id);

          if (userProfile?.user_id) {
            // Set user as active on browser open
            const avatarUrl = communityProfile
              ? communityProfile.profile_image_temporary_url
              : userProfile.profile_image_path;
            localStorage.setItem("loggedIn_user_id", userProfile.user_id);

            Cookies.set(
              "uesrpro",
              communityProfile
                ? communityProfile.profile_image_temporary_url
                : userProfile.profile_image_path
            );
            // console.log(
            //   "avatarUrl",
            //   res.data.communityProfile.profile_image_temporary_url
            // );
            updateUserStatus(
              `${userProfile.username}_${userProfile.user_id}`.toString(),
              true,
              avatarUrl
            );
          }

          // console.log("Profile fetched:", userProfile);
        } else {
          console.log("No token found");
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchUserProfile();
  }, [authToken]); // Re-fetch profile if token changes

  // Handle browser/tab close and set status to inactive
  useEffect(() => {
    const handleBrowserClose = () => {
      if (profile?.user_id) {
        updateUserStatus(profile.user_id, false); // Set user as inactive on browser close
      }
    };

    window.addEventListener("beforeunload", handleBrowserClose);

    return () => {
      window.removeEventListener("beforeunload", handleBrowserClose);
    };
  }, [profile]);

  // Handle token absence (logout redirect)
  useEffect(() => {
    if (!authToken) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours expiry for the current URL cookie
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });

      window.location = process.env.REACT_APP_ACCOUNT_URL;
    }
    try {
      if (!authToken) {
        const now = new Date();
        const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
        Cookies.set("currentURL", window.location.href, {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
          expires: expires,
        });
      }
    } catch (error) {
      console.error("Error checking KYC status:", error);
    }
  }, [authToken]);

  // Handle logout
  const handleLogout = () => {
    if (profile?.user_id) {
      updateUserStatus(profile.user_id, false); // Set user as inactive on logout
    }
    // Logout logic (e.g., remove cookies, redirect)
  };

  // const username = getCookie("username");
  const moduleName = 18;
  const socket = io("https://live.silocloud.io");
  useEffect(() => {
    socket.on(`notification_${moduleName}_${username}`, (data) => {
      console.log("Notification received:", data);
      console.log(data);
      if (data) {
        if ("Notification" in window) {
          if (Notification.permission === "granted") {
            new Notification(`Notification: ${data.title}`, {
              body: `${data.body}`,
              icon: "https://community.silocloud.io/static/media/Community.716f308d74ad37937639.png",
            });
          } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                new Notification(`Notification: ${data.title}`, {
                  body: `${data.body}`,
                  icon: "https://community.silocloud.io/static/media/Community.716f308d74ad37937639.png",
                });
              }
            });
          }
        } else {
          console.log("Browser does not support notifications.");
        }
      }
    });

    return () => {
      socket.off(`notification_${moduleName}_${username}`);
    };
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/profile/archive" element={<Archive />} />
        <Route path="/chats" element={<Chat />} />

        <Route path="/community_post/:postId" element={<PostPage />} />
        <Route path="/other/profile/:userID" element={<OtherUserProfile />} />
        <Route path="/edit/profile" element={<EditProfile />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default AppRouter;
