import React, { useState, useCallback } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Box,
  ImageList,
  ImageListItem,
  Modal,
  Typography,
  IconButton,
  Skeleton,
  Grid,
  useTheme,
  Menu,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { handleSelectDraft } from "../../../http/getApiCalls";
import { useDispatch } from "react-redux";
import {
  setImageUrl,
  setVideoUrl,
} from "../../../store/slices/DraftStoryURLSlice";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { handleDeleteDraftStories } from "../../../http/postApiCalls";
import { queryClient } from "../../../http";
import LoadingBackdrop from "../../../utils/progress/LoadingBackdrop";
import { useSnackbarUtils } from "../../../utils/snackbar/snackbarUtils";

const DraftStories = ({ open, handleClose, onSelectMedia }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarUtils();

  const darkMode = theme.palette.mode === "dark";
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    data: savedDrafts,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["getDraftStories"],
    queryFn: handleSelectDraft,
    staleTime: Infinity,
  });

  const draftData = savedDrafts?.draftData || [];

  const handleMediaSelect = useCallback(
    (url, type) => {
      // Check if anchorEl is false
      if (!anchorEl) {
        console.log("triggered");
        if (type === "video") {
          onSelectMedia(url, "video");
          dispatch(setVideoUrl(url));
        } else {
          onSelectMedia(url, "image");
          dispatch(setImageUrl(url));
        }
      }
    },
    [dispatch, onSelectMedia, anchorEl] // Include anchorEl in the dependency array
  );

  const handleMenuOpen = (event, item) => {
    event.stopPropagation(); // Prevent event bubbling
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const handleDeleteDraft = (event) => {
    // Prevent the click event from propagating to the parent ImageListItem
    event.stopPropagation();
    setLoading(true);
    const formData = new FormData();

    if (selectedItem) {
      console.log("Deleting draft with ID:", selectedItem.id);
      formData.append("id", selectedItem.id);

      deleteDraftedStory(formData);
    }
    handleMenuClose();
  };
  // Mutation for deleting the post
  const { mutate: deleteDraftedStory, reset: deleteDraftedStoryreset } =
    useMutation({
      mutationKey: ["drafted-story-delete"],
      mutationFn: (fd) => handleDeleteDraftStories(fd),
      onSuccess: (data) => {
        // setLoading(false);
        if (data?.status) {
          queryClient.invalidateQueries(["getDraftStories"]);
          setLoading(false);
          setAnchorEl(null);

          showSuccessSnackbar("Story deleted successfully!");
        }
        deleteDraftedStoryreset();
      },
      onError: (data) => {
        setLoading(false);
        setAnchorEl(null);
      },
    });

  if (isError) {
    return <Typography>Error loading drafts</Typography>;
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%",
        borderRadius: 2,
        p: 2,
        overflowY: "auto",
        border: darkMode ? "0.5px solid #2b2b2b" : "0.5px solid #f2f2f2",
      }}
      className="custom-scrollbar-chat"
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: "18px",
          fontWeight: "bolder",
          textAlign: "center",
          textTransform: "capitalize",
          letterSpacing: "1px",
          color: darkMode ? "#fff" : "#111",
        }}
      >
        Your Draft
      </Typography>

      {isLoading ? (
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            {[...Array(12)].map((_, index) => (
              <Grid item xs={4} key={index}>
                <Skeleton
                  variant="rectangular"
                  width={130}
                  height={100}
                  sx={{ mb: 1 }}
                  animation="wave"
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : draftData.length === 0 ? (
        <Typography>No drafts available.</Typography>
      ) : (
        <ImageList
          sx={{
            width: "100%",
            padding: "16px",
            cursor: "pointer",
            overflow: "hidden",
            "& .MuiImageListItem-root": {
              position: "relative",
              transition: "transform 0.4s ease, box-shadow 0.4s ease",
              borderRadius: "10px",
            },
            "& .MuiImageListItem-root:hover": {
              boxShadow: "0 8px 20px rgba(0, 0, 0, 0.25)",
            },
            "& .hamburger-action": {
              opacity: 0,
              transition: "opacity 0.3s ease",
              position: "absolute",
              top: "0px", // Positioned outside the media
              right: "-30px",
              zIndex: 2, // Ensures it stays on top of the media
            },
            "& .MuiImageListItem-root:hover .hamburger-action": {
              opacity: 1,
            },
          }}
          variant="quilted"
          cols={2}
          rowHeight={120}
          className="custom-scrollbar-chat"
          gap={20}
        >
          {draftData.map(
            (item) =>
              item.mediaUrls &&
              item.mediaUrls.map((url) => {
                const isVideo = url.includes(".mp4");
                return (
                  <ImageListItem
                    key={url}
                    cols={1}
                    rows={1}
                    onClick={() =>
                      handleMediaSelect(url, isVideo ? "video" : "image")
                    }
                    sx={{
                      border: darkMode
                        ? "0.5px solid #2b2b2b"
                        : "0.5px solid #f2f2f2",
                    }}
                  >
                    {isVideo ? (
                      <video
                        src={url}
                        alt={`Draft ${item.id}`}
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                        }}
                        controls
                        preload="metadata"
                      />
                    ) : (
                      <img
                        src={url}
                        alt={`Draft ${item.id}`}
                        loading="lazy"
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                          filter: "brightness(0.9)",
                          transition: "filter 0.3s ease",
                        }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.filter = "brightness(1)")
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.filter = "brightness(0.9)")
                        }
                      />
                    )}

                    {/* Hamburger Icon (MoreVertIcon) outside the media */}
                    <IconButton
                      className="hamburger-action"
                      aria-label="more"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleMenuOpen(event, item);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>

                    {/* Menu for delete option */}
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                      PaperProps={{
                        style: {
                          width: "150px",
                        },
                      }}
                    >
                      <MenuItem onClick={handleDeleteDraft}>
                        Delete Draft
                      </MenuItem>
                    </Menu>
                  </ImageListItem>
                );
              })
          )}
        </ImageList>
      )}
      <LoadingBackdrop loading={loading} />
    </Box>
  );
};

export default DraftStories;
