import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Avatar,
  Typography,
  Tooltip,
  Skeleton,
  IconButton,
  useTheme,
} from "@mui/material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { useMutation, useQuery } from "@tanstack/react-query";
import HighlightStoryViewer from "../../stories/HighlightStoryViewer";
import { handleGetHighlightStories } from "../../../http/getApiCalls";
import { handleAddHighLightInExistingHighlight } from "../../../http/postApiCalls";
import { queryClient } from "../../../http";
import { useSnackbarUtils } from "../../../utils/snackbar/snackbarUtils";
import LoadingBackdrop from "../../../utils/progress/LoadingBackdrop";

const HighlightStory = ({ liveStory, storyId, handleClose1, handleClose }) => {
  const theme = useTheme();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarUtils();

  const [isStoryViewerOpen, setIsStoryViewerOpen] = useState(false);
  const [currentHighlightIndex, setCurrentHighlightIndex] = useState(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true); // Initially true
  const scrollContainerRef = useRef(null);
  const [loading, setLoading] = useState(false);

  // Mutation for add highlight in existing highlight
  const { mutate: addHighlightInExisting, reset: addHighlightInExistingReset } =
    useMutation({
      mutationKey: ["add-highlight"],
      mutationFn: (formData) => handleAddHighLightInExistingHighlight(formData),
      onSuccess: (data) => {
        if (data?.status) {
          queryClient.invalidateQueries(["getHighlights"]);
          setLoading(false);
          showSuccessSnackbar("Highlight added successfully!");
          handleClose1();
          handleClose();
        }
        addHighlightInExistingReset();
      },
      onError: (error) => {
        setLoading(false);
        handleClose1();
        handleClose();
        console.error("Error adding highlight:", error);
      },
    });

  const {
    data: highlightStories,
    isLoading: highlightStoryLoading,
    isRefetching: highlightStoryIsRefetch,
    error: highlightStoryError,
  } = useQuery({
    queryKey: ["gethighlight"],
    queryFn: async () => {
      try {
        return await handleGetHighlightStories();
      } catch (error) {
        console.error("Error fetching highlight stories:", error);
        throw error; // Rethrow to let react-query handle it
      }
    },
    gcTime: 0,
    staleTime: Infinity,
  });
  const stories = Array.isArray(highlightStories) ? highlightStories : [];
  // Attach event listener to handle scroll updates
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check to set arrow visibility

      return () => scrollContainer.removeEventListener("scroll", handleScroll);
    }
  }, [stories]);

  // Show skeleton loader while fetching data
  if (highlightStoryLoading || highlightStoryIsRefetch) {
    return (
      <Box display="flex" justifyContent="start" width="100%" gap={2} ml={2}>
        {Array(liveStory ? 5 : 10)
          .fill()
          .map((_, index) => (
            <Skeleton
              key={index}
              variant="circular"
              width={56}
              height={56}
              animation="wave"
            />
          ))}
      </Box>
    );
  }

  // Handle error state
  if (highlightStoryError) {
    return (
      <Box display="flex" justifyContent="center" width="100%" mt={2}>
        <Typography color="error">
          Error loading stories: {highlightStoryError.message}
        </Typography>
      </Box>
    );
  }

  const openStoryViewer = (index, highlightId) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("story_ids", JSON.stringify([storyId])); // Convert ID to array format as ["3", "4"]
    formData.append("id", highlightId);

    addHighlightInExisting(formData);
    setCurrentHighlightIndex(index);
    setIsStoryViewerOpen(liveStory ? false : true);
  };

  const handleStoryEnd = () => {
    if (currentHighlightIndex + 1 < stories.length) {
      setCurrentHighlightIndex(currentHighlightIndex + 1);
    } else {
      setIsStoryViewerOpen(false);
    }
  };

  const closeStoryViewer = () => {
    setIsStoryViewerOpen(false);
    setCurrentHighlightIndex(null);
  };

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({
      left: -150, // Adjust scroll distance as needed
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({
      left: 150, // Adjust scroll distance as needed
      behavior: "smooth",
    });
  };

  // Check if the user has reached the end of the scrolling container
  const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;

    // Check if we are at the start or end of the container
    setShowLeftArrow(scrollLeft > 0); // Show left arrow if not at the beginning
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth); // Show right arrow if not at the end
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        position="relative"
        mb={1}
      >
        {/* Scroll Left Button */}
        {showLeftArrow && (
          <IconButton
            onClick={scrollLeft}
            sx={{
              position: "absolute",
              left: 0,
              zIndex: 1,
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <ArrowBackIos />
          </IconButton>
        )}

        {/* Story Highlights */}
        <Box
          ref={scrollContainerRef}
          display="flex"
          justifyContent="flex-start"
          width="100%"
          gap={2}
          ml={2}
          sx={{
            overflowX: "auto",
            scrollBehavior: "smooth",
            padding: stories?.length > 12 ? "0 20px" : 0, // Add padding to avoid overlap with right arrow
          }}
          className="custom-scrollbar"
        >
          {stories.map((highlight, index) => (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              alignItems="center"
              onClick={() => openStoryViewer(index, highlight?.highlight_id)}
              sx={{ cursor: "pointer" }}
            >
              <Tooltip title={highlight.title} placement="top">
                <Box
                  sx={{
                    height: 56,
                    width: 56,
                    borderRadius: "50%",
                    border:
                      theme.palette.mode === "dark"
                        ? "2px solid #2e2d2d"
                        : "2px solid #e3e3e3",
                    display: "flex", // Add flex display
                    justifyContent: "center", // Horizontally center the Avatar
                    alignItems: "center", // Vertically center the Avatar
                  }}
                >
                  <Avatar
                    src={
                      highlight?.cover_img_temp_url ||
                      "https://via.placeholder.com/80"
                    }
                    sx={{
                      height: 50,
                      width: 50,
                      border:
                        theme.palette.mode === "dark"
                          ? "2px solid #262625"
                          : "2px solid #ffffff",
                    }}
                  />
                </Box>
              </Tooltip>
              <Typography
                variant="body1"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "80px",
                  textAlign: "center",
                  fontSize: "12px",
                  lineHeight: "16px",
                  mt: 1.2,
                  color: theme.palette.mode === "dark" ? "#696666" : "#535455",
                }}
              >
                {highlight.title}
              </Typography>
            </Box>
          ))}
        </Box>

        {/* Scroll Right Button */}
        {showRightArrow && (
          <IconButton
            onClick={scrollRight}
            sx={{
              position: "absolute",
              right: 0,
              zIndex: 1,
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <ArrowForwardIos />
          </IconButton>
        )}
      </Box>
      <LoadingBackdrop loading={loading} />

      {isStoryViewerOpen && (
        <HighlightStoryViewer
          open={isStoryViewerOpen}
          handleClose={closeStoryViewer}
          storiesData={stories}
          currentIndex={currentHighlightIndex}
          highlight={true}
        />
      )}
    </>
  );
};

export default HighlightStory;
