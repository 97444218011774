import React, { useState, useContext, useEffect } from "react";
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  List,
  ListItem,
  useTheme,
} from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { useQuery, useMutation } from "@tanstack/react-query";
import { postRequest } from "../api-service";
import { Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";

const NotificationsDropdown = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [offset, setOffset] = useState(0);

  const open = Boolean(anchorEl);

  // Fetch notifications with `useQuery`
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["notifications", offset],
    queryFn: () =>
      postRequest("/public/get-notifications", {
        limit: 10,
        offset,
        module: 18,
      }).then((res) => res.data),
  });

  // console.log("notification", data);

  const markAllAsReadMutation = useMutation({
    mutationFn: () =>
      postRequest("/public/set-notification-status", {
        module: 18,
        notification_id: 0,
        status: 1,
      }),
    onSuccess: () => {
      refetch(); // Refetch notifications after marking as read
    },
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAllAsRead = () => {
    markAllAsReadMutation.mutate();
  };

  const handleViewAll = () => {
    setOffset((prevOffset) => prevOffset + 10);
  };

  const handleReadNotification = (id) => {
    postRequest("/public/set-notification-status", {
      module: 18,
      notification_id: id,
      status: 1,
    }).then(() => {
      refetch(); // Refetch notifications after reading one
    });
  };

  return (
    <div>
      <IconButton
        aria-label="notifications"
        aria-controls="notification-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Badge
          badgeContent={data?.total_count || 0}
          color="error"
          sx={{ height: "fit-content", width: "fit-content" }}
        >
          <NotificationsNoneOutlinedIcon fontSize="20px" />
        </Badge>
      </IconButton>
      <Menu
        id="notification-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            boxShadow: "0 3px 12px 1px #82252626",
            width: "300px",
            borderTop: "3px solid #e14954",
          },
        }}
      >
        <div
          style={{
            padding: "4px 16px",
            height: "fit-content",
            borderBottom: "1px solid #ccc",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor:
              theme.palette.mode === "dark" ? "#545453" : "#f5f6fa ",
          }}
        >
          <Typography variant="subtitle1">Notifications</Typography>
          <Typography
            variant="body2"
            color="inherit"
            sx={{ cursor: "pointer", mt: 0.5 }}
            onClick={handleMarkAllAsRead}
          >
            Mark All as Read
          </Typography>
        </div>
        <List>
          {isLoading ? (
            <MenuItem>
              <Typography variant="h6" align="center">
                Loading...
              </Typography>
            </MenuItem>
          ) : !data?.notifications ? (
            <MenuItem>
              <Typography
                variant="h6"
                align="center"
                fontSize={15}
                sx={{ m: "auto" }}
                color={"error"}
                fontWeight={"700 !important"}
              >
                No new notifications
              </Typography>
            </MenuItem>
          ) : (
            data?.notifications?.map((notification) => (
              <MenuItem
                key={notification.id}
                onClick={() => handleReadNotification(notification.id)}
              >
                <ListItem
                  disableGutters
                  component={Link}
                  to={notification.link}
                >
                  <ListItemAvatar>
                    <Avatar src={notification.avatarUrl} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={notification.user}
                    secondary={
                      <>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ textWrap: "wrap" }}
                        >
                          {notification.title}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ display: "block", marginTop: 0.5 }}
                        >
                          {formatDistanceToNow(
                            new Date(notification.created_at),
                            { addSuffix: true }
                          )}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              </MenuItem>
            ))
          )}
        </List>
        {data?.notifications?.length > 5 && (
          <div style={{ padding: "8px 16px", textAlign: "center" }}>
            <Typography
              variant="body2"
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={handleViewAll}
            >
              View All
            </Typography>
          </div>
        )}
      </Menu>
    </div>
  );
};

export default NotificationsDropdown;
