import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  Tooltip,
  IconButton,
  useMediaQuery,
  Autocomplete,
  Chip,
  useTheme,
  Backdrop,
  CircularProgress,
  Avatar,
  InputAdornment,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import EmojiPicker from "emoji-picker-react";
import LocationAutocomplete from "./LocationAutocomplete";
import MusicPicker from "./MusicPicker";
import { postRequest } from "../../api-service";

import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import VolumeOffOutlinedIcon from "@mui/icons-material/VolumeOffOutlined";
import { Visibility } from "@mui/icons-material";
import PeopleIcon from "@mui/icons-material/People";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../http";
import { handleGetSiloUsers, handlePostFinal } from "../../http/postApiCalls";
import { useSnackbarUtils } from "../../utils/snackbar/snackbarUtils";

const PostFinalStepForm = ({
  selectedImage,
  handleCloseModal,
  handleFormSubmit,
  mediaType,
}) => {
  const theme = useTheme();
  const fixedOptions = []; // You can add fixed users if needed
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallScreen1 = useMediaQuery("(max-width: 780px)");

  const darkMode = theme.palette.mode === "dark";
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarUtils();

  const [caption, setCaption] = useState("");
  const [location, setLocation] = useState("");
  const [mentions, setMentions] = useState([]);
  const [mentions1, setMentions1] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [music, setMusic] = useState(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [binaryImage, setBinaryImage] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [searching, setSearching] = useState(false);

  const videoRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 800px)");

  useEffect(() => {
    if (selectedImage && mediaType !== "video") {
      convertImageToBinary(selectedImage);
    } else if (mediaType === "video") {
      fetchVideoBlob(selectedImage);
    }
  }, [selectedImage]);

  const convertImageToBinary = (base64String) => {
    const byteString = atob(base64String.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    setBinaryImage(uint8Array);
  };

  const fetchVideoBlob = async (blobUrl) => {
    try {
      const response = await fetch(blobUrl);
      const blob = await response.blob();
      setBinaryImage(blob);
    } catch (error) {
      console.error("Error fetching video blob:", error);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  // const [users, setUsers] = useState([]);

  // Fetch users with a limit of 30 initially
  const { mutate: getUsers, reset: getUsersReset } = useMutation({
    mutationKey: ["get-users"],
    mutationFn: (fd) => handleGetSiloUsers(fd),
    onSuccess: (data) => {
      setLoading(false);
      setSearching(false);

      if (data?.status) {
        setUsers(data.message.users);
      }
      getUsersReset();
    },
    onError: () => {
      setLoading(false);
      setSearching(false);
    },
  });

  // Fetch initial 30 users
  useEffect(() => {
    const fd = new FormData();
    fd.append("limit", 30);
    getUsers(fd);
  }, [getUsers]);
  const handleSearchChange = (event, value) => {
    console.log("evenet", event.target.value);
    const query = value || event.target.value;
    setSearchQuery(query);
    if (query) {
      // Trigger search
      setSearching(true);
      const fd = new FormData();
      fd.append("limit", 30);
      fd.append("search", query); // Send search query to the backend
      getUsers(fd);
    } else {
      setSearching(false);

      setUsers([]); // Clear results if the query is empty
    }
  };

  // const getUsersData = () => {
  //   postRequest("/wallet/users").then((res) => {
  //     setUsers(res.data.users);
  //   });
  // };

  // useEffect(() => {
  //   getUsersData();
  // }, []);

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleEmojiClick = (event, emojiObject) => {
    const emoji = emojiObject?.emoji || event.emoji || event.native;
    if (emoji) {
      setCaption((prevCaption) => prevCaption + emoji);
    } else {
      console.error("Emoji not found in the event object", event);
    }
  };

  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePost = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("visibility", 0);
    formData.append("caption", caption ? caption : "");
    formData.append("location", location?.place_name);
    formData.append("tagged_users", mentions1);

    if (mediaType === "video" && binaryImage) {
      formData.append("media", binaryImage);
    } else if (binaryImage) {
      const blob = new Blob([binaryImage], { type: "image/png" });
      formData.append("media", blob);
    }
    postDone(formData);
  };
  console.log("loading post final", loading);

  const { mutate: postDone, reset: postDoneReset } = useMutation({
    mutationKey: ["post-done"],
    mutationFn: handlePostFinal,
    onSuccess: (data) => {
      console.log("final data", data);
      if (data?.status) {
        showSuccessSnackbar(data.message);
        queryClient.invalidateQueries(["getAllPostData"]);
        setLoading(false);
        handleCloseModal();
        handleFormSubmit();
      } else {
        setLoading(false);

        showErrorSnackbar(data.message);
      }

      postDoneReset();
    },
  });

  const handleVideoClick = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  const handleMuteToggle = (event) => {
    event.preventDefault();
    const video = videoRef.current;
    video.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  return (
    <form
      //   onSubmit={handlePost}
      style={{ width: "100%", height: "50%" }}
    >
      <Grid container spacing={2}>
        {/* Image Preview Section */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f8f8f8",
              // padding: 2,
              // borderRadius: 2,
              overflow: "hidden",
              minWidth: isMobile ? "100%" : 500,
              maxHeight: isMobile ? "fit-content" : 375,
            }}
          >
            {/* <img
              src={selectedImage}
              alt="Selected"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            /> */}
            {mediaType === "video" ? (
              <div
                style={{ position: "relative", width: "100%", height: "100%" }}
              >
                <video
                  ref={videoRef}
                  src={selectedImage}
                  autoPlay
                  loop
                  muted={isMuted}
                  style={{ width: "100%", height: "100%" }}
                  onClick={handleVideoClick}
                />
                <IconButton
                  type="button" // Ensure button type is "button"
                  onClick={handleMuteToggle}
                  style={{
                    height: 30,
                    width: 30,
                    position: "absolute",
                    bottom: "20px",
                    right: "10px",
                    background: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    border: "none",
                    borderRadius: "50%",
                    padding: "5px 10px",
                  }}
                >
                  {isMuted ? (
                    <VolumeOffOutlinedIcon fontSize="small" />
                  ) : (
                    <VolumeUpOutlinedIcon fontSize="small" />
                  )}
                </IconButton>
              </div>
            ) : (
              <img
                src={selectedImage}
                alt="Selected"
                style={{
                  maxWidth: "100%",
                  maxHeight: isSmallScreen ? "100%" : "fit-content",
                  objectFit: "contain",
                }}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box padding={"0 20px 10px 20px"}>
            {/* Caption Section with Emoji Picker and Send Button */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 0,
                  position: "relative",
                  borderBottom: "1px solid #dbdbdb",
                }}
              >
                <Tooltip title="Add Emoji">
                  <IconButton
                    onClick={toggleEmojiPicker}
                    edge="start"
                    sx={{
                      pr: 1,
                      backgroundColor: "transparent",
                    }}
                  >
                    <EmojiEmotionsIcon
                      sx={{ color: "#d1d1d1" }}
                      fontSize="18px"
                    />
                  </IconButton>
                </Tooltip>
                <TextField
                  fullWidth
                  multiline
                  maxRows={2}
                  value={caption}
                  onChange={(e) => setCaption(e.target.value)}
                  variant="standard"
                  placeholder="Add a caption..."
                  sx={{
                    backgroundColor: "transparent",
                    "& .MuiInputBase-root": {
                      backgroundColor: "transparent",
                      paddingTop: "12px",
                      paddingBottom: "12px",
                      // borderBottom: "1px solid #dbdbdb",
                      height: "auto",
                      maxHeight: "50px",
                      resize: "none",
                      "&.Mui-focused": {
                        backgroundColor: "transparent",
                        "& .MuiInputBase-input": {
                          borderBottomColor: "none",
                        },
                      },
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    },
                    "& .MuiInput-underline:before": {
                      display: "none",
                    },
                    "& .MuiInput-underline:after": {
                      display: "none",
                    },
                    "& .MuiInput-underline:hover:before": {
                      display: "none",
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                {showEmojiPicker && (
                  <Box
                    ref={emojiPickerRef}
                    sx={{
                      position: "absolute",
                      bottom: "35px",
                      left: "0",
                      zIndex: 1,
                    }}
                  >
                    <EmojiPicker onEmojiClick={handleEmojiClick} height={375} />
                  </Box>
                )}
              </Box>
            </Grid>

            {/* Add Location Button */}
            <Grid item xs={12} mt={0}>
              <LocationAutocomplete
                value={location}
                onChange={(newValue) => setLocation(newValue)}
              />
            </Grid>

            {/* Music Picker */}
            {/* <Grid item xs={12} mt={1}>
            <MusicPicker
              value={music}
              onChange={(newValue) => setMusic(newValue)}
            />
          </Grid> */}

            {/* Tag People Button */}
            <Grid item xs={12} mt={1}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: -1,
                  position: "relative",
                  borderBottom: "1px solid #dbdbdb",
                }}
              >
                <IconButton
                  edge="start"
                  sx={{
                    pr: 1,
                    backgroundColor: "transparent",
                  }}
                >
                  <PeopleIcon sx={{ color: "#d1d1d1" }} fontSize="18px" />
                </IconButton>
                <Autocomplete
                  multiple
                  id="mention-tags"
                  value={mentions}
                  noOptionsText={searching ? "searching..." : "No user found"}
                  onChange={(event, newValue) => {
                    const selectedIds = newValue
                      .filter(
                        (option) => fixedOptions.indexOf(option.id) === -1
                      )
                      .map((option) => option.id); // Extract only the ids

                    setMentions1([...fixedOptions, ...selectedIds]);
                    setMentions([
                      ...fixedOptions,
                      ...newValue.filter(
                        (option) => fixedOptions.indexOf(option.id) === -1
                      ),
                    ]);
                  }}
                  onInputChange={handleSearchChange}
                  options={users}
                  getOptionLabel={(option) => option.username} // Use username here
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        avatar={
                          <Avatar
                            alt={option.username}
                            src={option.profile_image_path}
                            sx={{
                              fontSize: "17px", // Set the delete icon size to 17px
                            }}
                          />
                        }
                        key={option.id}
                        label={option.username} // Use username here
                        {...getTagProps({ index })}
                        sx={{
                          backgroundColor: "#E0E0E0",
                          borderRadius: "25px",
                          ml: -2,
                          height: "fit-content",
                          "& .MuiChip-label": {
                            fontWeight: 500,
                          },
                          "& .MuiChip-deleteIcon": {
                            fontSize: "17px", // Set the delete icon size to 17px
                          },
                        }}
                        disabled={fixedOptions.indexOf(option) !== -1}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      maxRows={2}
                      variant="standard"
                      placeholder="Add mention..."
                      sx={{
                        backgroundColor: "transparent", // Instagram-like background
                        "& .MuiInputBase-root": {
                          backgroundColor: "transparent", // Instagram-like background
                          paddingTop: "12px", // Adjust padding for multiline TextField
                          paddingBottom: "12px",
                          borderBottom: "none", // Instagram-like border

                          // borderBottom: "1px solid #dbdbdb", // Instagram-like border
                          height: "auto", // Allows the TextField to be flexible with content but limited by maxHeight
                          resize: "none", // Disable resizing to maintain fixed height
                          "&.Mui-focused": {
                            backgroundColor: "transparent",
                            "& .MuiInputBase-input": {
                              borderBottomColor: "none", // Ensure the border color remains consistent
                            },
                          },
                          "&:hover": {
                            backgroundColor: "transparent", // Remove background color on hover
                          },
                        },
                        "& .MuiInput-underline:before": {
                          display: "none", // Remove underline before focus
                        },
                        "& .MuiInput-underline:after": {
                          display: "none", // Remove underline after focus
                        },
                        "& .MuiInput-underline:hover:before": {
                          display: "none", // Remove underline on hover
                        },
                        "& .MuiInputBase-input::placeholder": {
                          marginLeft: "-16px", // Apply ml: -2 (equivalent to -16px)
                        },
                      }}
                    />
                  )}
                  sx={{
                    width: "100%",
                    "& .MuiChip-root": {
                      marginRight: "5px",
                      marginBottom: "5px",
                    },
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "5px 0px",
                    },
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Avatar
                        alt={option.username}
                        src={option.profile_image_path || "/default-avatar.png"}
                        sx={{
                          width: isSmallScreen ? 20 : 32,
                          height: isSmallScreen ? 20 : 32,
                          mr: isSmallScreen ? 0.5 : 2,
                          ml: isSmallScreen ? -1 : 0,
                        }}
                      />
                      {option.username}
                    </li>
                  )}
                />
              </Box>
            </Grid>

            {/* Share Button */}
            <Grid item xs={12} mt={2}>
              <Button
                // type="submit"
                onClick={handlePost}
                variant="contained"
                color="primary"
                sx={{
                  mb: 1,
                  backgroundColor: "#De3744",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#C60804 ",
                  },
                  width: "100%",
                  textTransform: "none",
                }}
              >
                Share
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Backdrop open={loading}>
        <CircularProgress color="error" />
      </Backdrop>
    </form>
  );
};

export default PostFinalStepForm;
