import React, { useState } from "react";
import {
  TextField,
  IconButton,
  InputAdornment,
  Box,
  Tooltip,
  Menu,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import EmojiPicker from "emoji-picker-react";
import { Icon } from "@iconify/react/dist/iconify.js";

const ChatInput = ({ onSend }) => {
  const [message, setMessage] = useState("");
  const [emojiMenuAnchor, setEmojiMenuAnchor] = useState(null);

  const handleSend = () => {
    if (message.trim()) {
      onSend(message); // This triggers sending the message
      setMessage("");
    }
  };

  const handleKeyDown = (e) => {
    // Check if the Alt key is pressed along with Enter
    if (e.key === "Enter" && e.altKey) {
      e.preventDefault();
      setMessage((prev) => prev + "\n"); // Add a new line to the message
    } else if (e.key === "Enter" && !e.altKey) {
      e.preventDefault();
      handleSend(); // Trigger the send action if only Enter is pressed
    }
  };

  const handleEmojiClick = (emojiObject) => {
    if (emojiObject?.emoji) {
      setMessage((prev) => prev + emojiObject.emoji);
    }
  };

  const handleCloseEmojiPicker = () => {
    setEmojiMenuAnchor(null); // Close emoji picker
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => e.preventDefault()}
      className="chat-input"
    >
      <TextField
        variant="standard"
        placeholder="Type a message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        fullWidth
        onKeyDown={handleKeyDown}
        multiline
        sx={{ height: 40 }}
        maxRows={2}
        InputProps={{
          disableUnderline: true, // This removes the underline

          startAdornment: (
            <InputAdornment position="start">
              <Tooltip title="Add Emoji">
                <IconButton
                  onClick={(event) => setEmojiMenuAnchor(event.currentTarget)}
                  edge="start"
                  size="small"
                >
                  <Icon icon="fluent:emoji-add-16-regular" fontSize={22} />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <IconButton
        onClick={handleSend}
        disabled={!message.trim()}
        aria-label="send message"
      >
        <SendIcon />
      </IconButton>

      {/* Emoji Picker Menu */}
      <Menu
        anchorEl={emojiMenuAnchor}
        sx={{ backgroundColor: "transparent", maxHeight: 500, maxWidth: 350 }}
        open={Boolean(emojiMenuAnchor)}
        onClose={handleCloseEmojiPicker}
      >
        <Box mb={3}>
          <EmojiPicker onEmojiClick={handleEmojiClick} />
        </Box>
      </Menu>
    </Box>
  );
};

export default ChatInput;
