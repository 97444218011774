import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Avatar,
  TextField,
  Grid,
  useMediaQuery,
  Typography,
  InputAdornment,
  Tooltip,
  Skeleton,
  CircularProgress,
  useTheme,
  Collapse,
  Popover,
  Divider,
  Autocomplete,
  Chip,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import {
  Delete,
  MoreHorizOutlined,
  PlayCircleOutline,
} from "@mui/icons-material";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import VolumeOffOutlinedIcon from "@mui/icons-material/VolumeOffOutlined";
import { Icon } from "@iconify/react/dist/iconify.js";
import EmojiPicker from "emoji-picker-react";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { getRequest, postRequest } from "../../api-service";
import { queryClient } from "../../http";
import {
  handleDeleteComment,
  handleEditPost,
  handlePostLike,
} from "../../http/postApiCalls";
import { useMutation, useQuery } from "@tanstack/react-query";
import { handleGetPosts } from "../../http/getApiCalls";
import CommentInput from "../../utils/CommentInput";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import ReplyIcon from "@mui/icons-material/Reply";
import { useLocation, useNavigate } from "react-router-dom";
import PostMoreMenuModal from "../../more-menus/PostMoreMenuModal";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LocationAutocomplete from "./LocationAutocomplete";
import MusicPicker from "./MusicPicker";

import { Visibility } from "@mui/icons-material";
import PeopleIcon from "@mui/icons-material/People";
import { handleGetSiloUsers, handlePostFinal } from "../../http/postApiCalls";
import { useSnackbarUtils } from "../../utils/snackbar/snackbarUtils";
import LoadingBackdrop from "../../utils/progress/LoadingBackdrop";

function EditPost({
  isModalOpen,
  handleModalClose,
  imageUrl,
  //   caption,
  postData,
  userId,
  userName,
  profileImage,
  following_status,
  closeCommentModal,
}) {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const location = useLocation();
  console.log("postData", postData);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isPosted, setIsPosted] = useState(false);
  const [likePost, setLikePost] = useState(postData.liked_by_user);
  const [likeCount, setLikeCount] = useState(postData.likes_count);

  const videoRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const navigate = useNavigate();

  // console.log("postData---", postData);
  const isImage = imageUrl?.match(/\.(jpeg|jpg|gif|png)$/) !== null;
  const isVideo = imageUrl?.match(/\.(mp4|webm|ogg)$/) !== null;

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  const {
    data: postsData,
    isLoading: postFinalIsLoading,
    isRefetching: postFinalIsRefetch,
    refetch: postFinalRefetch,
  } = useQuery({
    queryKey: ["getAllPostData", postData.id],
    queryFn: async () => handleGetPosts(postData.id),
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (isModalOpen) {
      postFinalRefetch(); // Refetch the data when the modal is opened
    }
  }, [isModalOpen, postFinalRefetch, isPosted]);
  // console.log("postsData commentsmodal", postsData ? postsData[0] : postsData);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    borderRadius: 2,
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 0,
    // p: isSmallScreen ? 2.5 : 4,
    width: isSmallScreen ? "85vw" : "75vw",
    minHeight: "70vh",
    maxHeight: isSmallScreen ? "95vh" : "80vh",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  };

  const handleMuteToggle = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handlePlayPauseToggle = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play().catch((error) => {
          console.error("Error playing video:", error);
        });
        setIsPaused(false);
      } else {
        videoRef.current.pause();
        setIsPaused(true);
      }
    }
  };

  const getCommentsOfPost = () => {
    // setLoading(true);

    getRequest(
      `/community-post/community-post-comments/get-comments?post_id=${postData.id}`
    )
      .then((res) => {
        setLoading(false);
        setComments(res.data.comments);
        // console.log("comments res", res.data.comments);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error", err);
      });
  };
  const [replyingTo, setReplyingTo] = useState(null);
  const [commentReplyTrue, setCommentReplyTrue] = useState(false);
  const [replyingToName, setReplyingToName] = useState("");
  const [commentReplyId, setCommentReplyId] = useState(null);

  const darkMode = theme.palette.mode === "dark";
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarUtils();

  const [caption, setCaption] = useState(postData?.caption);
  const [location1, setLocation] = useState(postData?.location);
  console.log("location1", location1?.place_name);
  const [mentions, setMentions] = useState(postData?.tagged_users_details);
  const [mentions1, setMentions1] = useState([]);
  //   const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [music, setMusic] = useState(null);
  //   const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [binaryImage, setBinaryImage] = useState(null);
  //   const [isMuted, setIsMuted] = useState(false);
  const [searching, setSearching] = useState(false);
  const fixedOptions = []; // You can add fixed users if needed

  //   const videoRef = useRef(null);
  //   const emojiPickerRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 800px)");

  const convertImageToBinary = (base64String) => {
    const byteString = atob(base64String.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    setBinaryImage(uint8Array);
  };

  const fetchVideoBlob = async (blobUrl) => {
    try {
      const response = await fetch(blobUrl);
      const blob = await response.blob();
      setBinaryImage(blob);
    } catch (error) {
      console.error("Error fetching video blob:", error);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  // const [users, setUsers] = useState([]);

  // Fetch users with a limit of 30 initially
  const { mutate: getUsers, reset: getUsersReset } = useMutation({
    mutationKey: ["get-users"],
    mutationFn: (fd) => handleGetSiloUsers(fd),
    onSuccess: (data) => {
      setLoading(false);
      setSearching(false);

      if (data?.status) {
        setUsers(data.message.users);
      }
      getUsersReset();
    },
    onError: () => {
      setLoading(false);
      setSearching(false);
    },
  });

  // Fetch initial 30 users
  useEffect(() => {
    const fd = new FormData();
    fd.append("limit", 30);
    getUsers(fd);
  }, [getUsers]);
  const handleSearchChange = (event, value) => {
    console.log("evenet", event.target.value);
    const query = value || event.target.value;
    setSearchQuery(query);
    if (query) {
      // Trigger search
      setSearching(true);
      const fd = new FormData();
      fd.append("limit", 30);
      fd.append("search", query); // Send search query to the backend
      getUsers(fd);
    } else {
      setSearching(false);

      setUsers([]); // Clear results if the query is empty
    }
  };

  // const getUsersData = () => {
  //   postRequest("/wallet/users").then((res) => {
  //     setUsers(res.data.users);
  //   });
  // };

  // useEffect(() => {
  //   getUsersData();
  // }, []);

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleEmojiClick = (event, emojiObject) => {
    const emoji = emojiObject?.emoji || event.emoji || event.native;
    if (emoji) {
      setCaption((prevCaption) => prevCaption + emoji);
    } else {
      console.error("Emoji not found in the event object", event);
    }
  };

  //   const handleClickOutside = (event) => {
  //     if (
  //       emojiPickerRef.current &&
  //       !emojiPickerRef.current.contains(event.target)
  //     ) {
  //       setShowEmojiPicker(false);
  //     }
  //   };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePost = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("post_id", postData?.id);
    formData.append("caption", caption ? caption : "");
    formData.append(
      "location",
      location1?.place_name ? location1?.place_name : postData?.location
    );
    formData.append("tagged_users", mentions1);

    postDone(formData);
    console.log("formdata", formData);
  };
  console.log("loading post final", loading);

  const { mutate: postDone, reset: postDoneReset } = useMutation({
    mutationKey: ["post-done"],
    mutationFn: handleEditPost,
    onSuccess: (data) => {
      console.log("final data", data);
      if (data?.status) {
        showSuccessSnackbar(data.message);
        queryClient.invalidateQueries(["getAllPostData"]);
        setLoading(false);
        handleCloseModal();
        closeCommentModal();
      } else {
        setLoading(false);

        showErrorSnackbar(data.message);
      }

      postDoneReset();
    },
  });

  const handleVideoClick = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  const handleReplyClick = (commentId, commentUsername) => {
    setCommentReplyId(commentId);
    setCommentReplyTrue(true);
    setReplyingToName(`@${commentUsername}`);
    setReplyingTo((prev) => (prev === commentId ? null : commentId));
  };

  const [viewingReplies, setViewingReplies] = useState(null);

  const handleViewRepliesClick = (commentId) => {
    setViewingReplies((prev) => (prev === commentId ? null : commentId));
  };
  const handlePostSuccess = () => {
    if (isModalOpen === true) {
      getCommentsOfPost();
      setReplyingTo(null);
      setReplyingToName("");
      setCommentReplyTrue(false);
      setCommentReplyId(null);
    }
  };

  useEffect(() => {
    if (isModalOpen === true) {
      getCommentsOfPost();
    }
  }, [isModalOpen, isPosted]);

  // api for like post
  const handleLikePost = (id) => {
    setLikePost(!likePost);
    setLikeCount(likePost ? likeCount - 1 : likeCount + 1);
    // postRequest("/community-post/togglelike-post", { post_id: id }).then(
    //   (res) => {
    //     console.log("post liked");
    //   }
    // );
    const formData = new FormData();
    formData.append("post_id", id);

    postLike(formData);
  };

  const { mutate: postLike, reset: postLikeReset } = useMutation({
    mutationKey: ["post-like"],
    mutationFn: handlePostLike,
    onSuccess: (data) => {
      // console.log("final data", data);
      if (data?.status) {
        // showSuccessToast(data.message, darkMode);
        queryClient.invalidateQueries(["getAllPostData"]);
      } else {
        // showErrorToast(data?.message, darkMode);
      }

      postLikeReset();
    },
  });
  const userCheck = localStorage.getItem("loggedIn_user_id");

  const handleDeleteComments = (id, type) => {
    console.log("id", id, "type", type);
    const formData = new FormData();
    if (type === "comment") {
      formData.append("comment_id", id);
    } else if (type === "reply") {
      formData.append("reply_id", id);
    }
    deleteComment(formData);
  };

  const { mutate: deleteComment, reset: deleteCommentReset } = useMutation({
    mutationKey: ["delete-comments"],
    mutationFn: (fd) => handleDeleteComment(fd),
    onSuccess: (data) => {
      if (data?.status) {
        getCommentsOfPost();
        handleClose();
      }
      deleteCommentReset();
    },
    onError: (data) => {
      console.error("Error deleting comment or reply", data);
    },
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteID, setDeleteID] = useState(0);
  const [deleteType, setDeleteType] = useState("");

  // Handle opening popover
  const handleClick = (event, id, type) => {
    console.log("id", id, "type", type);
    setDeleteID(id);
    setDeleteType(type);

    setAnchorEl(event.currentTarget);
  };

  // Handle closing popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Determine if the popover is open
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [isLoading, setIsLoading] = useState(true); // Loading state for media

  const handleImageLoaded = () => {
    setIsLoading(false); // Hide skeleton when image is loaded
  };

  const handleVideoLoaded = () => {
    setIsLoading(false); // Hide skeleton when video is loaded
  };

  const handleGoToOtherUserProfile = (id) => {
    if (location.pathname.startsWith("/profile")) {
      navigate("/profile");
      return;
    }
    navigate(
      userCheck === id?.toString() ? "/profile" : `/other/profile/${id}`
    );
  };

  const [selectedPost, setSelectedPost] = useState([]);
  const [openMoreMenu, setOpenMoreMenu] = useState(false);
  const handleOpenModal = (post) => {
    setSelectedPost(post);
    setOpenMoreMenu(true);
  };
  const handleCloseModal = () => {
    setOpenMoreMenu(false);
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <Box sx={modalStyle}>
        {/* <Box
          sx={{ position: "absolute", top: isSmallScreen ? 2 : 6, right: 15 }}
        >
          <IconButton edge="end" color="inherit" onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box> */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: 0,
                paddingTop: "100%", // Square aspect ratio
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#c4c0c0", // Black background similar to Instagram
              }}
            >
              {/* Skeleton Loader */}
              {isLoading && (
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    bgcolor: "#c4c0c0", // Dark skeleton for Instagram-like feel
                  }}
                />
              )}

              {/* Image Handling */}
              {postData?.media_details[0]?.media_type === "image" && (
                <img
                  src={imageUrl}
                  alt="Post"
                  onLoad={handleImageLoaded} // Detect when the image is fully loaded
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover", // Make sure the image covers the area
                    display: isLoading ? "none" : "block", // Hide image until loaded
                  }}
                />
              )}

              {/* Video Handling */}
              {postData?.media_details[0]?.media_type === "video" && (
                <>
                  <video
                    ref={videoRef}
                    src={imageUrl}
                    loop
                    autoPlay
                    playsInline
                    onLoadedData={handleVideoLoaded} // Detect when the video is ready
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover", // Cover the container
                      display: isLoading ? "none" : "block", // Hide video until loaded
                    }}
                    onClick={handlePlayPauseToggle}
                  />
                  {isPaused && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "white",
                        borderRadius: "50%",
                        padding: 1,
                      }}
                    >
                      <PlayCircleOutline
                        onClick={handlePlayPauseToggle}
                        sx={{
                          fontSize: isSmallScreen ? 40 : 60,
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              maxHeight: "100%",
              p: 1.5,
              mt: "auto",
            }}
          >
            <Box padding={"0 20px 10px 20px"}>
              {/* Caption Section with Emoji Picker and Send Button */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 0,
                    position: "relative",
                    borderBottom: "1px solid #dbdbdb",
                  }}
                >
                  <Tooltip title="Add Emoji">
                    <IconButton
                      onClick={toggleEmojiPicker}
                      edge="start"
                      sx={{
                        pr: 1,
                        backgroundColor: "transparent",
                      }}
                    >
                      <EmojiEmotionsIcon
                        sx={{ color: "#d1d1d1" }}
                        fontSize="18px"
                      />
                    </IconButton>
                  </Tooltip>
                  <TextField
                    fullWidth
                    multiline
                    maxRows={2}
                    value={caption}
                    onChange={(e) => setCaption(e.target.value)}
                    variant="standard"
                    placeholder="Add a caption..."
                    sx={{
                      backgroundColor: "transparent",
                      "& .MuiInputBase-root": {
                        backgroundColor: "transparent",
                        paddingTop: "12px",
                        paddingBottom: "12px",
                        // borderBottom: "1px solid #dbdbdb",
                        height: "auto",
                        maxHeight: "50px",
                        resize: "none",
                        "&.Mui-focused": {
                          backgroundColor: "transparent",
                          "& .MuiInputBase-input": {
                            borderBottomColor: "none",
                          },
                        },
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      },
                      "& .MuiInput-underline:before": {
                        display: "none",
                      },
                      "& .MuiInput-underline:after": {
                        display: "none",
                      },
                      "& .MuiInput-underline:hover:before": {
                        display: "none",
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  {showEmojiPicker && (
                    <Box
                      ref={emojiPickerRef}
                      sx={{
                        position: "absolute",
                        bottom: "35px",
                        left: "0",
                        zIndex: 1,
                      }}
                    >
                      <EmojiPicker
                        onEmojiClick={handleEmojiClick}
                        height={375}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>

              {/* Add Location Button */}
              <Grid item xs={12} mt={0}>
                <LocationAutocomplete
                  value={location1}
                  onChange={(newValue) => setLocation(newValue)}
                />
              </Grid>

              {/* Music Picker */}
              {/* <Grid item xs={12} mt={1}>
            <MusicPicker
              value={music}
              onChange={(newValue) => setMusic(newValue)}
            />
          </Grid> */}

              {/* Tag People Button */}
              <Grid item xs={12} mt={1}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: -1,
                    position: "relative",
                    borderBottom: "1px solid #dbdbdb",
                  }}
                >
                  <IconButton
                    edge="start"
                    sx={{
                      pr: 1,
                      backgroundColor: "transparent",
                    }}
                  >
                    <PeopleIcon sx={{ color: "#d1d1d1" }} fontSize="18px" />
                  </IconButton>
                  <Autocomplete
                    multiple
                    id="mention-tags"
                    value={mentions}
                    noOptionsText={searching ? "searching..." : "No user found"}
                    onChange={(event, newValue) => {
                      const selectedIds = newValue
                        .filter(
                          (option) => fixedOptions.indexOf(option.id) === -1
                        )
                        .map((option) => option.id); // Extract only the ids

                      setMentions1([...fixedOptions, ...selectedIds]);
                      setMentions([
                        ...fixedOptions,
                        ...newValue.filter(
                          (option) => fixedOptions.indexOf(option.id) === -1
                        ),
                      ]);
                    }}
                    onInputChange={handleSearchChange}
                    options={users}
                    getOptionLabel={(option) => option.username} // Use username here
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          avatar={
                            <Avatar
                              alt={option.username}
                              src={option.profile_image_path}
                              sx={{
                                fontSize: "17px", // Set the delete icon size to 17px
                              }}
                            />
                          }
                          key={option.id}
                          label={option.username} // Use username here
                          {...getTagProps({ index })}
                          sx={{
                            backgroundColor: "#E0E0E0",
                            borderRadius: "25px",
                            ml: -2,
                            height: "fit-content",
                            "& .MuiChip-label": {
                              fontWeight: 500,
                            },
                            "& .MuiChip-deleteIcon": {
                              fontSize: "17px", // Set the delete icon size to 17px
                            },
                          }}
                          disabled={fixedOptions.indexOf(option) !== -1}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Add mention..."
                        sx={{
                          backgroundColor: "transparent",
                          "& .MuiInputBase-root": {
                            backgroundColor: "transparent",
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            height: "auto", // Allow flexible height
                            resize: "none", // Disable resizing
                            "&.Mui-focused": {
                              backgroundColor: "transparent",
                            },
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          },
                          "& .MuiInput-underline:before": {
                            display: "none", // Remove underline before focus
                          },
                          "& .MuiInput-underline:after": {
                            display: "none", // Remove underline after focus
                          },
                          "& .MuiInput-underline:hover:before": {
                            display: "none", // Remove underline on hover
                          },
                          "& .MuiInputBase-input::placeholder": {
                            marginLeft: "-16px", // Apply ml: -2 (equivalent to -16px)
                          },
                        }}
                      />
                    )}
                    sx={{
                      width: "100%",
                      "& .MuiAutocomplete-inputRoot": {
                        overflowX: "hidden",
                        maxHeight: "64px", // Approx height for 2 rows (adjust as needed)
                        overflowY: "auto", // Scroll if more than 2 rows
                        padding: "5px 0px",
                      },
                      "& .MuiChip-root": {
                        marginRight: "5px",
                        marginBottom: "5px",
                      },
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li
                        {...props}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Avatar
                          alt={option.username}
                          src={
                            option.profile_image_path || "/default-avatar.png"
                          }
                          sx={{
                            width: isSmallScreen ? 20 : 32,
                            height: isSmallScreen ? 20 : 32,
                            mr: isSmallScreen ? 0.5 : 2,
                            ml: isSmallScreen ? -1 : 0,
                          }}
                        />
                        {option.username}
                      </li>
                    )}
                  />
                </Box>
              </Grid>

              {/* Share Button */}
              <Grid item xs={12} mt={2}>
                <Button
                  // type="submit"
                  onClick={handlePost}
                  variant="contained"
                  color="primary"
                  sx={{
                    mb: 1,
                    backgroundColor: "#De3744",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#C60804 ",
                    },
                    width: "100%",
                    textTransform: "none",
                  }}
                >
                  Share
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <PostMoreMenuModal
          open={openMoreMenu}
          onClose={handleCloseModal}
          post={selectedPost}
          userId={userId}
          closeCommentModal={handleModalClose}
          postData={postData}
          following_status={following_status}
        />
        <LoadingBackdrop loading={loading} />
      </Box>
    </Modal>
  );
}

export default EditPost;
