import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingBackdrop = ({ loading }) => {
  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        color: "#fff",
      }}
      open={loading}
    >
      <CircularProgress color="error" />
    </Backdrop>
  );
};

export default LoadingBackdrop;
