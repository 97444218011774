import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Avatar,
  Button,
  TextField,
  DialogContent,
  DialogActions,
  useTheme,
  Typography,
  Divider,
  Box,
  Skeleton,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useMutation } from "@tanstack/react-query";
import { handleGetSiloUsers } from "../../http/postApiCalls";
import { useSnackbarUtils } from "../../utils/snackbar/snackbarUtils";

const shareOptions = [
  {
    name: "Your Story",
    icon: <Icon icon="hugeicons:user-story" fontSize={25} />,
  },
  {
    name: "WhatsApp",
    icon: <Icon icon="ph:whatsapp-logo-thin" fontSize={30} />,
  },
  {
    name: "Facebook",
    icon: <Icon icon="ph:facebook-logo-light" fontSize={30} />,
  },
  {
    name: "Twitter",
    icon: (
      <Icon
        icon="hugeicons:new-twitter-ellipse"
        fontSize={30}
        style={{ strokeWidth: 0.5 }}
      />
    ),
  },
  {
    name: "CopyLink",
    icon: <Icon icon="fluent:copy-32-regular" fontSize={25} />,
  },
];

const StoryShareModal = ({ open, handleClose, shareableUrl }) => {
  const theme = useTheme();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarUtils();

  const darkMode = theme.palette.mode === "dark";
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSharePost = () => {
    console.log("selected users", selectedUsers);
  };

  // Fetch users with a limit of 30 initially
  const { mutate: getUsers, reset: getUsersReset } = useMutation({
    mutationKey: ["get-users"],
    mutationFn: (fd) => handleGetSiloUsers(fd),
    onSuccess: (data) => {
      setLoading(false);
      if (data?.status) {
        setUsers(data.message.users);
      }
      getUsersReset();
    },
  });

  useEffect(() => {
    const fd = new FormData();
    fd.append("limit", 30); // Fetch initial 30 users
    fd.append("search", searchQuery); // Send search query to the backend

    getUsers(fd);
  }, [searchQuery]);

  const handleToggle = (user) => {
    const isSelected = selectedUsers.some((u) => u.id === user.id);
    if (isSelected) {
      setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.trim()) {
      // Trigger search
      setLoading(true);
      const fd = new FormData();
      fd.append("limit", 30); // Optional: Adjust this limit as needed
      fd.append("search", query); // Send search query to the backend
      getUsers(fd);
    } else {
      // Fetch the initial 30 users again if search query is cleared
      const fd = new FormData();
      fd.append("limit", 30);
      getUsers(fd);
    }
  };

  // const shareUrl = `https://community.silocloud.io${post.shareable_link}`;
  const shareUrl = shareableUrl;

  const handleShare = (platform) => {
    let url = "";
    switch (platform) {
      case "whatsapp":
        url = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "twitter":
        url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          shareUrl
        )}`;
        break; // Missing break added here
      case "copylink":
        navigator.clipboard.writeText(`${shareableUrl}`);

        showSuccessSnackbar("Link copied to clipboard.");
        return; // Don't proceed to window.open when copying link
      default:
        return;
    }

    if (url) {
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: { borderRadius: 5 },
      }}
    >
      <DialogTitle
        sx={{ textAlign: "center", fontWeight: "bold", padding: "20px 0" }}
      >
        <Typography
          variant="h6"
          sx={{ position: "absolute", right: "45%", top: 8, fontSize: "18px" }}
        >
          Share
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 5, color: "grey.500" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      {/* Search Section */}
      <span
        style={{
          display: "flex",
          alignItems: "center",
          // margin: "0 16px",
          padding: "8px 16px",
          backgroundColor: darkMode ? "#2b2b2b" : "#f2f2f2",
          // borderRadius: "50px",
          boxShadow: darkMode
            ? "0 1px 4px rgba(0, 0, 0, 0.4)"
            : "0 1px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h6" fontSize={14} style={{ marginRight: "8px" }}>
          To:
        </Typography>
        <TextField
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search users"
          variant="outlined"
          size="small"
          fullWidth
          margin="none"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchQuery && (
                  <IconButton
                    edge="end"
                    onClick={() => setSearchQuery("")}
                    size="small"
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              padding: "0px 16px",
              borderRadius: "50px",
              backgroundColor: darkMode ? "#3c3c3c" : "#ffffff",
              height: 40,
              "& fieldset": {
                border: "none", // Remove default border
              },
              "&:hover fieldset": {
                borderColor: darkMode ? "#De3744 " : "#De3744 ", // Hover effect
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent",
                boxShadow: "0 0 0 1px #De3744 ", // Instagram-style shadow on focus
              },
            },
            input: {
              padding: "10px 0px", // Ensure input text is vertically aligned
            },
          }}
        />
      </span>
      <Divider />
      <DialogContent
        dividers
        sx={{
          padding: 0,
          maxHeight: "350px",
          minHeight: "350px",
        }}
        className="custom-scrollbar-chat"
      >
        {/* Selected Users Display */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            margin: "0 16px",
            // paddingBottom: "8px",
            overflowX0: "auto",
          }}
        >
          {selectedUsers.map((user) => (
            <Box
              key={user.id}
              sx={{
                display: "flex",
                alignItems: "center",
                margin: "4px",
                padding: "2px 8px",
                backgroundColor: darkMode ? "#444" : "#f0f1f2",
                borderRadius: "16px",
              }}
            >
              <Avatar
                alt={user.username}
                src={user.profile_image_path}
                sx={{ width: 24, height: 24, marginRight: 1 }}
              />
              <Typography variant="caption" sx={{ fontWeight: 500 }}>
                {user.username}
              </Typography>
            </Box>
          ))}
        </Box>
        <Divider />

        {/* Users List */}
        <List sx={{ padding: 0 }}>
          {loading
            ? Array.from(new Array(5))?.map((_, index) => (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={40}
                    height={40}
                    sx={{ m: 1 }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width={100}
                    height={30}
                  />
                </span>
              ))
            : users.map((user) => (
                <ListItem
                  key={user.id}
                  button
                  onClick={() => handleToggle(user)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "12px 16px",
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: darkMode ? "#3d3d3d" : "#f0f1f2",
                    },
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      alt={user.username}
                      src={user.profile_image_path}
                      sx={{ width: 40, height: 40, marginRight: 2 }}
                    />
                    <span style={{ fontWeight: 500 }}>{user.username}</span>
                  </div>
                  <div
                    style={{
                      height: "24px",
                      width: "24px",
                      border: "1px solid #De3744",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {selectedUsers.some((u) => u.id === user.id) && (
                      <CheckCircleIcon
                        sx={{
                          color: "#De3744",
                          fontSize: 22,
                        }}
                      />
                    )}
                  </div>
                </ListItem>
              ))}
        </List>
        <Divider />
      </DialogContent>
      <DialogActions sx={{ padding: "8px 16px", display: "block" }}>
        <Box
          sx={{ display: "flex", overflowX: "auto", padding: "8px 16px" }}
          className="custom-scrollbar-chat"
        >
          {shareOptions.map((option, index) => (
            <Box
              key={index}
              onClick={() => handleShare(option.name.toLowerCase())}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "60px",
                height: "60px",
                marginRight: "15px",
                borderRadius: "50%",
                backgroundColor: darkMode ? "#2c2c2c" : "#f0f0f0",
                transition: "transform 0.7s ease, background-color 0.7s ease",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#C60804 ",
                  transform: "scale(1.05)",
                  color: "#fff",
                },
              }}
            >
              {option.icon}
            </Box>
          ))}
        </Box>
        <Button
          onClick={handleSharePost}
          variant="contained"
          size="large"
          fullWidth
          sx={{
            background: "#DE3744",
            color: "white",
            "&:hover": {
              backgroundColor: "#c4333d",
            },
          }}
        >
          Share
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StoryShareModal;
