import React, { useRef, useState } from "react";
import {
  Grid,
  Modal,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Skeleton, // Import Skeleton from MUI
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Icon } from "@iconify/react/dist/iconify.js";
import zIndex from "@mui/material/styles/zIndex";
import LoadingBackdrop from "../../utils/progress/LoadingBackdrop";
import { useMutation } from "@tanstack/react-query";
import { handleDeletePost, handleUnarchivePost } from "../../http/postApiCalls";
import { queryClient } from "../../http";
import { useSnackbarUtils } from "../../utils/snackbar/snackbarUtils";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw", // Full-width modal for responsiveness
  maxWidth: "600px", // Maximum width similar to Instagram post view
  backgroundColor: "#fff",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)", // Soft shadow for depth
  borderRadius: "10px", // Rounded corners for a modern look
  overflow: "hidden", // Ensures the content doesn't overflow
  display: "flex",
  flexDirection: "column",
};

const iconButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  zIndex: 1,
  color: "#262626", // Icon color to match Instagram's neutral theme
};

const mediaStyle = {
  width: "100%",
  height: "auto",
  objectFit: "cover", // Ensures media maintains aspect ratio
  maxHeight: "80vh", // Limit height for better viewing experience
};

const ArchivePost = ({ posts, archivePostDataIsLoading }) => {
  const videoRefs = useRef({});
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarUtils();

  const [openPostModal, setOpenPostModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [selectedMediaType, setSelectedMediaType] = useState(""); // Track media type
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState("");
  const [loading, setLoading] = useState(false);
  const openMenu = Boolean(anchorEl);

  const handleOpenPostModal = (media, mediaType, post) => {
    setSelectedMedia(media);
    setSelectedMediaType(mediaType); // Set media type (image or video)
    setOpenPostModal(true);
    setSelectedPost(post);
  };
  const handlePostSelect = (media, mediaType, post) => {
    setSelectedMedia(media);
    setSelectedMediaType(mediaType); // Set media type (image or video)
    // setOpenPostModal(true);
    setSelectedPost(post);
  };

  const handleClosePostModal = () => {
    setOpenPostModal(false);
    setSelectedMedia("");
    setSelectedMediaType(""); // Reset media type
    setSelectedPost(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePostUnarchive = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("post_id", selectedPost?.id);
    unarchivePost(formData); // Trigger mutation
  };

  const handlePostDelete = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("post_id", selectedPost?.id);
    deletePost(formData); // Trigger mutation
  };

  // Mutation for deleting the post
  const { mutate: deletePost, reset: deletePostreset } = useMutation({
    mutationKey: ["delete-post"],
    mutationFn: (fd) => handleDeletePost(fd),
    onSuccess: (data) => {
      // setLoading(false);
      if (data?.status) {
        queryClient.invalidateQueries([
          "getAllPostData",
          "getarchivePostsData",
        ]);
        setLoading(false);
        setSelectedPost(null);

        showSuccessSnackbar("Post deleted successfully!");
        handleClosePostModal();
        setAnchorEl(null);
      }
      deletePostreset();
    },
    onError: (data) => {
      setLoading(false);
      showErrorSnackbar("Error while deleting...");
      setSelectedPost(null);
      handleClosePostModal();
      setAnchorEl(null);
    },
  });

  // Mutation for unarchive the post
  const { mutate: unarchivePost, reset: unarchivePostreset } = useMutation({
    mutationKey: ["unArchive-post"],
    mutationFn: (fd) => handleUnarchivePost(fd),
    onSuccess: (data) => {
      // setLoading(false);
      if (data?.status) {
        queryClient.invalidateQueries([
          "getAllPostData",
          "getarchivePostsData",
        ]);
        setLoading(false);
        setSelectedPost(null);

        showSuccessSnackbar("Post Unarchived successfully!");
        handleClosePostModal();
        setAnchorEl(null);
      }
      unarchivePostreset();
    },
    onError: (data) => {
      setLoading(false);
      showErrorSnackbar("Error while UnArchiving...");
      setSelectedPost(null);
      handleClosePostModal();
      setAnchorEl(null);
    },
  });

  return (
    <>
      <Grid container spacing={2}>
        {archivePostDataIsLoading
          ? // Show Skeletons while loading
            Array.from(new Array(6)).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton variant="rectangular" height={250} />
              </Grid>
            ))
          : posts?.map((post) => {
              const media = post?.media_details?.[0];
              if (!media) return null;

              const mediaPath = media.media_urls;
              const mediaType = media.media_type;
              const isImage = mediaType === "image";
              const isVideo = mediaType === "video";

              return (
                <Grid item xs={12} sm={6} md={4} key={post.id}>
                  <Box
                    sx={{
                      position: "relative",
                      cursor: "pointer",
                      // "&:hover .media-element": {
                      //   opacity: 0.3, // Lower opacity on hover
                      //   transition: "opacity 0.3s ease-in-out",
                      // },
                      // "&:hover .controls": {
                      //   opacity: 1,
                      // },
                      // "&:hover": {
                      //   height: 250,
                      //   backgroundColor: "rgba(0, 0, 0, 0.8)", // Apply same background to image/video box
                      // },
                      "&:hover .moreAction": {
                        opacity: 1,
                      },
                    }}
                    // onClick={() =>
                    //   handleOpenPostModal(mediaPath, mediaType, post)
                    // }
                  >
                    <Box
                      className="moreAction"
                      sx={{
                        position: "relative",
                        right: -39,
                        top: -13,
                        opacity: 0,
                      }}
                      onClick={() =>
                        handlePostSelect(mediaPath, mediaType, post)
                      }
                    >
                      <IconButton
                        aria-label="more"
                        aria-controls="post-menu"
                        aria-haspopup="true"
                        onClick={handleMenuClick}
                        sx={iconButtonStyle}
                      >
                        <MoreVertIcon />
                      </IconButton>

                      <Menu
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={handlePostUnarchive}>
                          Unarchive
                        </MenuItem>
                        <MenuItem onClick={handlePostDelete}>Delete</MenuItem>
                      </Menu>
                    </Box>
                    {isImage && (
                      <Box
                        component="img"
                        src={mediaPath}
                        alt={post.caption}
                        className="media-element"
                        sx={{
                          width: "100%",
                          height: 250,
                          objectFit: "cover",
                          transition: "opacity 0.3s ease-in-out",
                        }}
                      />
                    )}
                    {isVideo && (
                      <Box
                        sx={{
                          position: "relative",
                          width: "100%",
                          height: 250,
                          overflow: "hidden",
                        }}
                        // onClick={() =>
                        //   handleOpenPostModal(mediaPath, mediaType, post)
                        // }
                      >
                        <video
                          ref={(el) => (videoRefs.current[post.id] = el)}
                          src={mediaPath}
                          loop
                          autoPlay
                          playsInline
                          className="media-element"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            transition: "opacity 0.3s ease-in-out",
                          }}
                        />
                      </Box>
                    )}

                    {/* Post Controls */}
                    {/* <Box
                      className="controls"
                      sx={{
                        position: "absolute",
                        bottom: "43%",
                        left: 0,
                        right: 0,
                        padding: "8px 50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        opacity: 0,
                        transition: "opacity 0.3s ease-in-out",
                        color: "#fff",
                        fontWeight: 700,
                        "& .MuiIconButton-root": {
                          color: "#fff",
                        },
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton>
                          <Icon icon={"solar:heart-linear"} fontSize={30} />
                        </IconButton>
                        <Typography variant="h6">
                          {post.likes_count || 0}
                        </Typography>

                        <IconButton>
                          <Icon icon="iconamoon:comment-light" fontSize={30} />
                        </IconButton>
                        <Typography variant="h6">
                          {post.comment_count || 0}
                        </Typography>
                      </Box>

                      <IconButton sx={{ marginTop: "5px" }}>
                        <Icon icon="tabler:send" fontSize={30} />
                      </IconButton>
                    </Box> */}
                  </Box>
                </Grid>
              );
            })}
        <LoadingBackdrop loading={loading} />
      </Grid>
      {posts?.length === 0 && (
        <Typography variant="h6" color="textSecondary" textAlign={"center"}>
          No posts available.
        </Typography>
      )}

      {/* <Modal open={openPostModal} onClose={handleClosePostModal}>
        <Box sx={modalStyle}>
          <IconButton
            aria-label="more"
            aria-controls="post-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
            sx={iconButtonStyle}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu anchorEl={anchorEl} open={openMenu} onClose={handleMenuClose}>
            <MenuItem onClick={handlePostUnarchive}>Unarchive</MenuItem>
            <MenuItem onClick={handlePostDelete}>Delete</MenuItem>
          </Menu>

          {selectedMediaType === "image" ? (
            <img src={selectedMedia} alt="Image" style={mediaStyle} />
          ) : selectedMediaType === "video" ? (
            <video
              src={selectedMedia}
              controls={false}
              autoPlay
              loop
              style={mediaStyle}
            />
          ) : null}

          <LoadingBackdrop loading={loading} />
        </Box>
      </Modal> */}
    </>
  );
};

export default ArchivePost;
