import React, { useState } from "react";
import {
  Box,
  Modal,
  Typography,
  Avatar,
  Tooltip,
  Button,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import highlightLogo from "../../../assets/images/addHighLight.png";
import HighlightStory from "./HighlightStory";
import { useSnackbarUtils } from "../../../utils/snackbar/snackbarUtils";
import {
  handleAddHighLight,
  handleAddHighLightInExistingHighlight,
} from "../../../http/postApiCalls";
import { queryClient } from "../../../http";
import { useMutation } from "@tanstack/react-query";
import LoadingBackdrop from "../../../utils/progress/LoadingBackdrop";

const AddLiveStoryHighlight = ({
  open,
  handleClose,
  currentStory,
  handleClose1,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarUtils();

  const [newHighlight, setNewHighlight] = useState(false);
  const [storyTitle, setStoryTitle] = useState("");
  const [storyIds, setStoryIds] = useState([]);
  const [coverMedia, setCoverMedia] = useState(currentStory?.mediaUrls);
  const [loading, setLoading] = useState(false);
  console.log("currentStory", currentStory);
  const handleCreateNewHighlight = () => {
    setNewHighlight(true);
  };

  // Convert image to binary data (FileReader is used to get binary)
  const convertToBinary = async (imageUrl) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new File([blob], "cover_img.jpg", { type: blob.type }); // Convert Blob to a File
  };

  const handleDone = async () => {
    if (coverMedia) {
      setLoading(true);
      const formData = new FormData();

      // Append necessary fields
      formData.append("story_ids", JSON.stringify([currentStory?.id])); // Convert ID to array format as ["3", "4"]
      formData.append("title", storyTitle);
      formData.append("is_archived", true);

      // Convert image to binary and append to FormData
      const binaryData = await convertToBinary(coverMedia);
      formData.append("cover_img", binaryData); // Append binary data as File

      // Call your API to add highlight
      addHighlight(formData);

      // After API call, reset state or close modal
      setLoading(false);
      handleClose();
    }
  };

  // Mutation for add highlight
  const { mutate: addHighlight, reset: addHighlightReset } = useMutation({
    mutationKey: ["add-highlight"],
    mutationFn: (formData) => handleAddHighLight(formData),
    onSuccess: (data) => {
      console.log("data?.message", data);

      if (data?.status) {
        queryClient.invalidateQueries(["getHighlights"]);
        setLoading(false);
        showSuccessSnackbar("Highlight added successfully!");
        handleClose1();
      } else {
        showErrorSnackbar("errorrrrrrrrrrrrrrrrrr");
      }
      addHighlightReset();
    },
    onError: (error) => {
      setLoading(false);
      handleClose1();
      console.error("Error adding highlight:", error);
    },
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-live-story-highlight"
      aria-describedby="add-new-highlight-story"
    >
      <Box>
        {!newHighlight ? (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: 400,
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              padding: "0 10px",
              overflow: "hidden",
            }}
          >
            <Box
              display="flex"
              justifyContent={isSmallScreen ? "center" : "flex-start"}
              width="100%"
              mt={6}
              mb={1}
              padding={isSmallScreen ? "0 10px" : 0}
            >
              <div>
                <Tooltip title="Add Highlight" placement="top">
                  <Avatar
                    src={highlightLogo}
                    onClick={handleCreateNewHighlight}
                    sx={{ cursor: "pointer", height: 50, width: 50 }}
                  />
                </Tooltip>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "wrap",
                    maxWidth: "500px",
                    textAlign: "center",
                    fontSize: "12px",
                    lineHeight: "16px",
                    mt: 1.2,
                    color:
                      theme.palette.mode === "dark" ? "#696666" : "#535455",
                  }}
                >
                  New
                </Typography>
              </div>
              <HighlightStory
                liveStory={true}
                storyId={currentStory?.id}
                handleClose1={handleClose1}
                handleClose={handleClose}
              />
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: 400,
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              padding: "20px",
              textAlign: "center",
            }}
          >
            <Typography variant="h6" mb={2}>
              Create New Highlight
            </Typography>
            {/* Display the current story's image in a circle */}
            <Avatar
              src={coverMedia}
              alt="Current Story"
              sx={{ width: 120, height: 120, margin: "0 auto" }}
            />

            {/* Title input */}
            <TextField
              variant="standard"
              placeholder="Enter highlight title"
              value={storyTitle}
              onChange={(e) => setStoryTitle(e.target.value)}
              fullWidth
              sx={{
                mt: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none", // No border
                  },
                },
              }}
            />

            {/* Done button */}
            <Button
              onClick={handleDone}
              sx={{
                border: "1px solid #f44336",
                color: "#f44336",
                borderRadius: 3,
                textTransform: "none",
                padding: "5px 0",
                fontSize: "1rem",
                mt: 2,

                backgroundColor: "#f44336",
                color: "#fff",
                border: "1px solid #f44336",
                "&:hover": {
                  backgroundColor: "#C60804",
                },
              }}
            >
              Done
            </Button>
          </Box>
        )}

        <LoadingBackdrop loading={loading} />
      </Box>
    </Modal>
  );
};

export default AddLiveStoryHighlight;
