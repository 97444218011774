import React, { useState, useCallback, useEffect } from "react";
import {
  Avatar,
  Button,
  TextField,
  MenuItem,
  Box,
  Typography,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
  useMediaQuery,
  IconButton,
  Divider,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete"; // Import DeleteIcon

import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation } from "@tanstack/react-query";
import {
  handleGetCommunityProfile,
  handleSetCommunityProfile,
} from "../../http/postApiCalls";
import { useSnackbarUtils } from "../../utils/snackbar/snackbarUtils";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import LoadingBackdrop from "../../utils/progress/LoadingBackdrop";

const EditProfileContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px 100px",
  minHeight: "100vh",
  [theme.breakpoints.down("sm")]: {
    padding: "10px 20px",
  },
}));

const ProfileCard = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "15px 20px",
  borderRadius: "20px",
  marginBottom: "20px",
  width: "100%",
  maxWidth: "500px",
  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    padding: "10px",
  },
}));
const LinkCard = styled(Paper)(({ theme }) => ({
  // display: "flex",
  // alignItems: "center",
  // justifyContent: "space-between",
  // padding: "15px 20px",
  // borderRadius: "20px",
  // marginBottom: "20px",
  width: "100%",
  maxWidth: "500px",
  // boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    padding: "10px",
  },
}));

const SaveButton = styled(Button)({
  backgroundColor: "#De3744",
  color: "#fff",
  padding: "10px 20px",
  borderRadius: "30px",
  fontWeight: "bold",
  textTransform: "none",
  boxShadow: "0px 5px 15px rgba(255, 77, 77, 0.3)",
  "&:hover": {
    backgroundColor: "#e63939",
  },
  marginBottom: "20px",
});

const EditProfile = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 400px)");
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarUtils();

  // State for profile info
  const [bio, setBio] = useState("");
  const [gender, setGender] = useState("Male");
  const [profileImage, setProfileImage] = useState(
    "https://via.placeholder.com/150"
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const [isCropOpen, setIsCropOpen] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // State for links
  const [links, setLinks] = useState([]);
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLinkDialogOpen, setIsLinkDialogOpen] = useState(false);
  const [currentLink, setCurrentLink] = useState({ title: "", url: "" });
  const [editingIndex, setEditingIndex] = useState(null);
  const [noProfile, setNoProfile] = useState(null);
  console.log("noProfile", noProfile);
  const { mutate: getCommunityProfile, reset: communityProfileReset } =
    useMutation({
      mutationKey: ["get-community-profile"],
      mutationFn: handleGetCommunityProfile,
      onSuccess: (data) => {
        if (data?.status) {
          setNoProfile(data.message.communityProfile);
          setBio(data.message.communityProfile.about_me || "");
          setUserName(data.message.profile.username || "");
          setGender(data.message.communityProfile.gender || "male");
          setProfileImage(
            data.message.communityProfile.profile_image_temporary_url ||
              "https://via.placeholder.com/150"
          );
          // Parse the `url` field if it's a valid JSON string
          let links = [];
          try {
            links = JSON.parse(data.message.communityProfile.url || "[]");
          } catch (e) {
            console.error("Error parsing the URL field", e);
          }

          setLinks(links); // Set the parsed links array
        }
        communityProfileReset();
      },
      onError: (data) => {
        console.error("Error getting data", data);
      },
    });

  useEffect(() => {
    getCommunityProfile();
  }, []);

  // Handle Bio input limit
  const handleBioChange = (e) => {
    if (e.target.value.length <= 150) {
      setBio(e.target.value);
    }
  };

  // Handle image upload and open cropper
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
        setIsCropOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  // Crop complete handler
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Create a cropped image blob
  const createCroppedImage = async () => {
    const croppedImageBinary = await getCroppedImg(
      selectedImage,
      croppedAreaPixels
    );
    setProfileImage(URL.createObjectURL(croppedImageBinary));
    setIsCropOpen(false);
  };

  const handleSaveProfile = async () => {
    setLoading(true);

    const croppedImageBinary = croppedAreaPixels
      ? await getCroppedImg(selectedImage, croppedAreaPixels)
      : null;

    const formData = new FormData();
    formData.append(
      "profile_image_path",
      croppedImageBinary ? croppedImageBinary : null
    );
    formData.append("about_me", bio);
    formData.append("gender", gender);
    formData.append("url", JSON.stringify(links));

    try {
      setCommunityProfile(formData);

      // console.log("formdata", formData);

      // console.log("Profile updated successfully");
    } catch (error) {
      console.error("Error uploading profile:", error);
    }
  };

  const { mutate: setCommunityProfile, reset: communitySetProfileReset } =
    useMutation({
      mutationKey: ["set-community-profile"],
      mutationFn: handleSetCommunityProfile,
      onSuccess: (data) => {
        console.log("response body set", data);
        if (data?.status) {
          console.log("setprofile message", data);
          getCommunityProfile();
          setLoading(false);
          navigate("/profile");
          showSuccessSnackbar(data.message.message);
        } else {
          console.error("Error setting data", data);
          setLoading(false);
        }
        communitySetProfileReset();
      },
      onError: (data) => {
        showErrorSnackbar(data.errors[0]);
        console.log("Error setting data", data);
      },
    });

  // Utility to create a cropped image from canvas
  const getCroppedImg = (imageSrc, pixelCrop) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const size = Math.min(pixelCrop.width, pixelCrop.height);
        canvas.width = size;
        canvas.height = size;

        ctx.beginPath();
        ctx.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.clip();

        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          size,
          size
        );

        canvas.toBlob((blob) => {
          resolve(blob);
        }, "image/jpeg");
      };
    });
  };

  // Handle add/edit link
  const handleAddLink = () => {
    if (editingIndex !== null) {
      const updatedLinks = [...links];
      updatedLinks[editingIndex] = currentLink;
      setLinks(updatedLinks);
      setEditingIndex(null);
    } else {
      setLinks([...links, currentLink]);
    }
    setCurrentLink({ title: "", url: "" });
    setIsLinkDialogOpen(false);
  };

  // Open edit link dialog
  const handleEditLink = (index) => {
    setCurrentLink(links[index]);
    setEditingIndex(index);
    setIsLinkDialogOpen(true);
  };

  // Handle delete link
  const handleDeleteLink = (index) => {
    const updatedLinks = links.filter((_, i) => i !== index);
    setLinks(updatedLinks);
    showSuccessSnackbar("Link deleted successfully");
  };

  const navigate = useNavigate();

  const handleGoback = () => {
    navigate(-1); // This navigates to the previous page
  };
  return (
    <EditProfileContainer>
      <Box
        sx={{
          width: "100%",
          maxWidth: "500px",
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            padding: "10px",
          },
        }}
      >
        <ArrowBack onClick={handleGoback} sx={{ cursor: "pointer" }} />
      </Box>

      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", color: "#111", mb: 2 }}
      >
        Edit Profile
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          color: "#De3744",
          mb: 2,
          display: noProfile === null ? "flex" : "none",
        }}
      >
        Note :Please create your community profile
      </Typography>

      <ProfileCard>
        <Box display="flex" alignItems="center">
          <Avatar
            alt="Profile Picture"
            src={profileImage}
            // onClick={() => setIsCropOpen(true)}
            sx={{
              width: 80,
              height: 80,
              cursor: "pointer",
              marginRight: "20px",
            }}
          />
          <Box>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {userName}
            </Typography>
          </Box>
        </Box>
        <Box>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="change-photo"
            type="file"
            onChange={handleImageChange}
          />
          <label htmlFor="change-photo">
            <Button
              variant="outlined"
              component="span"
              color="error"
              sx={{
                borderRadius: "20px",
                textTransform: "none",
                borderColor: "#De3744",
                color: "#De3744",
                mt: theme.breakpoints.down("sm") ? 2 : 0,
                "&:hover": {
                  backgroundColor: "#ffcccc",
                },
              }}
            >
              Change
            </Button>
          </label>

          <Button
            variant="outlined"
            component="span"
            color="error"
            sx={{
              borderRadius: "20px",
              textTransform: "none",
              borderColor: "#De3744",
              color: "#De3744",
              mt: theme.breakpoints.down("sm") ? 2 : 0,
              ml: 1,
              "&:hover": {
                backgroundColor: "#ffcccc",
              },
            }}
            onClick={() => setProfileImage(null)}
          >
            Remove
          </Button>
        </Box>
      </ProfileCard>

      <TextField
        fullWidth
        multiline
        rows={3}
        label="Bio"
        value={bio}
        onChange={handleBioChange}
        sx={{ marginBottom: "20px", maxWidth: 500 }}
        inputProps={{ maxLength: 150 }}
        helperText={`${bio.length}/150`}
      />

      <TextField
        fullWidth
        select
        label="Gender"
        value={gender}
        onChange={(e) => setGender(e.target.value)}
        sx={{ marginBottom: "20px", height: "50px", maxWidth: 500 }}
      >
        <MenuItem value="male">Male</MenuItem>
        <MenuItem value="female">Female</MenuItem>
        <MenuItem value="other">Other</MenuItem>
      </TextField>
      <LinkCard
        sx={{
          borderRadius: 3,
          border: "0.5px solid #edece8",
          // minWidth: 500,
          // maxWidth: 500,
          p: 2,
          mb: 2,
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"} mb={1}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              // marginBottom: "10px",
              textAlign: "start",
            }}
          >
            Links
          </Typography>
          <Tooltip title="Add link">
            <Button
              variant="outlined"
              onClick={() => setIsLinkDialogOpen(true)}
              color="error"
              sx={{
                height: "30px",
                width: "30px",
                minWidth: "30px", // Ensures button stays square
                padding: 0, // Removes padding inside the button
              }}
            >
              <AddIcon fontSize="small" />
            </Button>
          </Tooltip>
        </Box>
        {links.map((link, index) => (
          <ProfileCard key={index}>
            <Box flexGrow={1}>
              <Typography variant="body1">{link.title}</Typography>
              <Typography variant="body2" color="textSecondary">
                {link.url}
              </Typography>
            </Box>
            <Box>
              <IconButton
                onClick={() => handleEditLink(index)}
                color="primary"
                size="small"
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => handleDeleteLink(index)}
                color="error"
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </ProfileCard>
        ))}
        {/* <Box textAlign={"end"}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => setIsLinkDialogOpen(true)}
            color="error"
            sx={{ mr: 0, textAlign: "end" }}
          >
            Add Link
          </Button>
        </Box> */}
      </LinkCard>

      <SaveButton onClick={handleSaveProfile}>Save Profile</SaveButton>
      <LoadingBackdrop loading={loading} />

      {/* Cropping Dialog */}
      <Dialog
        open={isCropOpen}
        onClose={() => setIsCropOpen(false)}
        maxWidth="xs" // Restrict maximum width for mobile devices
        fullWidth={isSmallScreen} // Make it full width on small screens
        PaperProps={{
          style: {
            overflowX: "hidden",
            margin: isSmallScreen ? "0 10px" : "auto", // Add margins for small screens
          },
        }}
      >
        <DialogContent sx={{ overflowX: "hidden" }}>
          <Box
            sx={{
              overflowX: "hidden",
            }}
            position="relative"
            width={isSmallScreen ? "205px" : "400px"}
            height={isSmallScreen ? "220px" : "400px"}
          >
            <Cropper
              image={selectedImage}
              crop={crop}
              zoom={zoom}
              aspect={1} // Circular crop
              cropShape="round"
              cropSize={{
                width: isSmallScreen ? 200 : 325,
                height: isSmallScreen ? 200 : 325,
              }} // Larger circle
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              objectFit="contain"
            />
          </Box>
          <Box mt={2} width="100%">
            <Slider
              color="error"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(e, zoom) => setZoom(zoom)}
              aria-labelledby="Zoom"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCropOpen(false)} color="error">
            Cancel
          </Button>
          <Button
            sx={{
              color: "#fff",
              backgroundColor: "#De3744",
              "&:hover": {
                backgroundColor: "#f43c2f",
              },
            }}
            onClick={createCroppedImage}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Link Dialog */}
      <Dialog
        open={isLinkDialogOpen}
        onClose={() => setIsLinkDialogOpen(false)}
      >
        <DialogTitle>
          {editingIndex !== null ? "Edit Link" : "Add Link"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            label="Title"
            value={currentLink.title}
            onChange={(e) =>
              setCurrentLink({ ...currentLink, title: e.target.value })
            }
            fullWidth
            sx={{ marginBottom: "10px" }}
            InputProps={{
              sx: { height: "40px", padding: "8px 14px" }, // Adjust height and padding
            }}
          />
          <TextField
            label="URL"
            value={currentLink.url}
            onChange={(e) =>
              setCurrentLink({ ...currentLink, url: e.target.value })
            }
            fullWidth
            sx={{ marginBottom: "10px" }}
            InputProps={{
              sx: { height: "40px", padding: "8px 14px" }, // Adjust height and padding
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ color: "#De3744" }}
            onClick={() => setIsLinkDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={!currentLink.title || !currentLink.url} // Disable Save button
            variant="contained"
            sx={{
              backgroundColor: "#De3744",
              "&:hover": {
                backgroundColor: "#f43c2f",
              },
            }}
            onClick={handleAddLink}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </EditProfileContainer>
  );
};

export default EditProfile;
