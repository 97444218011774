import { getRequest } from "../api-service";

export async function handleGetPosts(id = null) {
  const response = await getRequest(
    // `/community-post/get-all-user-posts?post_id=${id}`
    `/community-post/get-posts?post_id=${id}`
  );

  return await response?.data;
}

export async function handleGetStories() {
  const response = await getRequest(`/community-story/get-story`);
  return await response;
}

export async function handleGetUsersPosts() {
  const response = await getRequest(
    // `/community-post/get-all-user-posts?post_id=${id}`
    `/community-post/get-posts?user_posts=true`
  );

  return await response?.data;
}

export async function handleGetOtherUserData(id = null) {
  const response = await getRequest(
    // `/community-post/get-all-user-posts?post_id=${id}`
    `/community-post/get-all-users-posts?user_id=${id}`
  );

  return await response?.data;
}
export async function handleSelectDraft() {
  const response = await getRequest(
    // `/community-post/get-all-user-posts?post_id=${id}`
    `/community-story/community-draft-story/getsavedraft-story`
  );

  return await response?.data;
}

export async function handleGetArchivedStories() {
  const response = await getRequest(
    // `/community-post/get-all-user-posts?post_id=${id}`
    `/community-story/get-archive-story`
  );

  return await response?.data;
}

export async function handleGetHighlightStories() {
  const response = await getRequest(
    // `/community-post/get-all-user-posts?post_id=${id}`
    `/community-story/get-highlights`
  );

  return await response?.data;
}

export async function handleGetArchivedPosts() {
  const response = await getRequest(
    // `/community-post/get-all-user-posts?post_id=${id}`
    `/community-post/get-posts?include_archived=true`
  );

  return await response?.data;
}
